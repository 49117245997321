import { combineReducers } from 'redux';
import _ from 'lodash';
import { RECEIVE_VENDOR, RECEIVE_VENDORS, REQUEST_VENDORS } from './actions';
const prepareAlphabets = () => {
  let result = [];
  result.push(
    '0-9'
  );

  for (let i = 65; i < 91; i++) {
    const char = String.fromCharCode(i);
    result.push(
      char
    );
  }

  return result;
};
const filterJson = [
  {
      "name": "Vendor Type",
      "valueList": ['Warehouse', 'Direct Ship (Vendor Direct)']
  },
    
  {
     "name": 'Alphabetical',
     "valueList":prepareAlphabets()
  }
]

const vendors = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_VENDORS:
      return state;
    case RECEIVE_VENDORS:
      const { key, orderType } = action;
      let vendorKey = key && key.length > 1 ? key[0].toUpperCase() : key;
      vendorKey = key === '0-9' ? '0-9': vendorKey 
      let alphabetical = key;
      let dsIntialLetter = state?.dsIntialLetter || ''
      if(_.isEmpty(key)) {
         if( orderType === 1) {
          alphabetical = 'A'
         }
         else {
           //alphabetical = action.vendors?.Filters?.VendorPrefix[0]
          // dsIntialLetter = action.vendors?.Filters?.VendorPrefix[0]
          alphabetical = 'all'
          dsIntialLetter = 'all'
           
         }
      }
      const newState ={
        [vendorKey === '' ? 'all' : vendorKey]: Array.isArray(action.vendors) ? action.vendors : orderType !== 1 ? action.vendors?.Vendors : [],
        'filter': orderType !== 1 && action.vendors?.Filters?.VendorPrefix ? [
          {
              "name": "Vendor Type",
              "valueList": ['Warehouse', 'Direct Ship (Vendor Direct)']
          },
            
          {
             "name": 'Alphabetical',
             //"valueList":action.vendors?.Filters?.VendorPrefix
             "valueList":[]
          }
        ] : filterJson, 
        'alphabetical' : alphabetical,
        'orderType':orderType,
        dsIntialLetter
      };
      return newState;
    case RECEIVE_VENDOR:
      const { vendorDetails } = action;
      const { vendorsWithDetails = {} } = _.cloneDeep(state);
      const { VendorID } = vendorDetails;
      vendorsWithDetails[VendorID] = { ...vendorDetails };
      return { ...state, vendorsWithDetails };
    default:
      return state;
  }
};

const isFetching = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_VENDORS:
      return state + 1;
    case RECEIVE_VENDORS:
      return state - 1;
    default:
      return state;
  }
};

export const getVendorsFetching = state => state.isFetching;
export const getVendors = state => state.vendors;
export const getVendorWithDetails = state => {
  const { vendors = {} } = state;
  const { vendorsWithDetails = {} } = vendors;
  return vendorsWithDetails;
};

export default combineReducers({
  vendors,
  isFetching
});
