import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import renderImages from '../../common/RenderImages';
import search_img from '../../images/Search.svg';
import { fetchVendors } from '../actions';
import { getVendors } from '../reducer';
import utilities from '../../../utils/utilities';

class VendorSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: '',
      orderType : this.props.orderType || 1
    };
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.orderType !== nextProps.orderType) {
      this.setState({
        orderType: nextProps.orderType
      })
    }
  }
  searchKey = () => {
    const { searchKey, orderType } = this.state;
    const { fetchVendors, onSearchAction } = this.props;
    const letterNumber = /^[0-9]+$/;
    fetchVendors(orderType, encodeURIComponent(searchKey));
    if (onSearchAction && searchKey) {
      const searchKeyId = searchKey[0].toUpperCase();
      const searchKeyValue  = searchKeyId.match(letterNumber) ? "0-9" : searchKey;
      onSearchAction(searchKeyValue);
      const element = document.getElementById(searchKeyValue);
      //utilities.scrollToElement(element);
    }
  };

  handleFieldChange = e => {
    this.setState({
      searchKey: e.target.value.toUpperCase()
    });
  };

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchKey();
    }
  };

  render() {
    return (
      <div className='vendor-segment'>
        <div
          className={
            this.props.displayName === 'Order Search'
              ? 'ui icon input orders_header'
              : 'ui icon input '
          }
          id='search_div_address'
        >
          <span className='vender-search-header'>{this.props.displayName}</span>
          <input
            type='text'
            className='search-box_address'
            value={this.state.searchKey}
            onChange={this.handleFieldChange}
            onKeyDown={this.handleEnterKey}
          />
          <span onClick={this.searchKey} className='search_icon_address'>
            {renderImages.getImage({
              src: search_img
            })}
          </span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    orderType: getVendors(state.vendors).orderType
  }

};

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        fetchVendors
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorSearch);
