import React, { Component } from 'react';
import { Segment, Loader, Button, TextArea, Select } from 'semantic-ui-react'
import restUtils from '../../utils/restUtils';
import '../../views/AskTrueValue/AskTrueValue.css';
import {  ASK_TV_EMAIL, hereForYouDropdown } from '../../urlConstants';
import { connect } from 'react-redux';
import utilities from '../../utils/utilities';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { MEMBER_VIEW } from './menuConstants';
import _ from 'lodash';
import hereForYouLogo from '../../views/images/hereForYou.png'
import { bindActionCreators } from 'redux';
import { setHereForYouDropdown } from '../../views/Login/actions';
class HereForYou extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      product: undefined,
      emailAddress: this.props.emailAddress,
      contactNumber: this.props.contactNumber,
      username: this.props.hfyUserName,
      productMerchant: '',
      inventoryPlanner: '',
      productMerchantEmail: '',
      inventoryPlannerEmail: '',
      ccmyself: true,
      serverError: false,
      reasonForContact:this.props.reasonForContact,
      showBullets : false,
      pageOneOption: [],
      firstLevelVal:this.props.firstLevelVal,
      firstLevelOpt:[],
      secondLevelOpt:[],
      thridLevelOpt:[],
      secondLevelVal:this.props.secondLevelVal,
      thirdVal:this.props.thirdVal,
      comment: this.props.comment,
      queue:this.props.queue,
      file: null,
      fileName:'',
      fileDetails: null,
      hereForYouPrefer: this.props.hereForYouPrefer || 'Email',
      dropDownData: this.props.dropDownData || []

    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    let  { dropDownData } = this.state;
    if(_.isEmpty(this.props.dropDownData)) {
      restUtils.getDataWithoutToken(hereForYouDropdown).then((res)=>{
        this.props.setHereForYouDropdown(this.sortDropDown(res.data))
      })
    }else {
      let sortDropDown = this.sortDropDown(dropDownData)
      this.setState({
        dropDownData: sortDropDown
      },()=>{
        this.fetchDropown(sortDropDown)
      })
     
    }
    
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.dropDownData !== nextProps.dropDownData) {
      let sortDropDown = this.sortDropDown( nextProps.dropDownData)
      this.setState({
        dropDownData: sortDropDown
      })
      this.fetchDropown(this.sortDropDown(sortDropDown))
    }
  }
  sortDropDown = (data) => {
    let dropDownData = data
    dropDownData = dropDownData.map(ele=>{
      return {
        ...ele,
        SortOrder:parseInt(ele.SortOrder)
      }
    })
    return _.orderBy(dropDownData, ['SortOrder'], ['asc'])
  }
  fetchDropown = (dropDownData) =>{
    let  { firstLevelOpt, secondLevelOpt, thridLevelOpt } = this.state;
    let optVal = dropDownData.map(ele => ele.Level1)
      optVal = _.uniq(optVal)
      firstLevelOpt =  optVal.map(ele=>{
        return {
          key:ele,
          value:ele,
          text:ele,
          className:'reasonMenu'
        }
      })
      let opt_2 = dropDownData.filter(opt=> opt.Level1 === this.state.reasonForContact)
      opt_2 = _.uniqBy(opt_2, 'Level2').map(ele=> ele.Level2).filter(opt => opt !== '')
      secondLevelOpt = opt_2.map(ele=>{
        return {
          key:ele,
          value:ele,
          text:ele,
          className:'reasonMenu'
        }
      })
  
      let opt_3 = dropDownData.filter(opt=> opt.Level2 === this.state.secondLevelVal)
      opt_3 = _.uniqBy(opt_3, 'Level3').map(ele=> ele.Level3).filter(opt => opt !== '')
        thridLevelOpt = opt_3.map(ele=>{
          return {
            key:ele,
            value:ele,
            text:ele,
            className:'reasonMenu'
          }
        })
      this.setState({
        firstLevelOpt,
        secondLevelOpt,
        thridLevelOpt,
      })
  }
  getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);
    //reader.readAsBinaryString(files);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  handleFileInputChange = e => {
    let { file } = this.state;
    file = e.target.files[0];
    this.getBase64(file)
      .then(result => {
        file["base64"] = result;
        console.log("File Is", file);
        let fileType = file["name"].split(/\.(?=[^.]+$)/);
        if(!utilities.validateFileType(fileType[1])) {
          utilities.showToast('Attachments must be JPEG, PDF, PNG, Excel, or Word files.')
          return
        }
        else if(file['size'] >  2097152)  {
          utilities.showToast('Size should not exceed 2MB')
          return
        }
        this.setState({
          fileName: file["name"],
          fileDetails: {
            fileData: file["base64"],
            fileName:file["name"],
            fileType:file["type"]
          }         
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
  validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  handleSubmit = () => {
    const {
      username,
      contactNumber,
      emailAddress,
      comment,
      ccmyself,
      productMerchantEmail,
      inventoryPlannerEmail,
      reasonForContact,
      queue,
      hereForYouPrefer,
      fileDetails
    } = this.state;
    const {UserId, storeId, multiStoreDetails } = this.props;

    if (!username || !contactNumber || !emailAddress || !comment || !reasonForContact || queue ===""){
      utilities.showToast("Please enter all required fields", true);
      return;
    }
    const emailList = emailAddress.split(/[;,]/); // Split emails by comma or semicolon
    for (const email of emailList) {
      if (!this.validateEmail(email.trim())) {
        utilities.showToast("Please enter valid email address", true);
        return; // If any email is invalid, return false
      }
    }

    const requestPayload = {
      user: {
          UserID: UserId,
          StoreID: storeId,
          PrimaryWarehouseNum: multiStoreDetails && multiStoreDetails.primaryWarehouseNum
      },
      member: {
          memberName: username,
          memberPhone: contactNumber,
          memberEmail: emailAddress,
          memberNbr: storeId,
          comments: `How do you wish to be contacted?: ${hereForYouPrefer}<br/>Details Regarding Your Request: ${comment}`,
          CcEmail: ccmyself,
      },
      contact: {
          PM:  productMerchantEmail,
          Planner: inventoryPlannerEmail
      },
      item: {
      },
      fileAttachment: {
        ...fileDetails
      },
      Queue: {
        Name: this.state.queue,
        Level1: reasonForContact,
        Level2: this.state.secondLevelVal,
        Level3: this.state.thirdVal,
        ContactPreference:hereForYouPrefer,
      }
  }
  this.setState({ loading: true });
  restUtils
      .postData(ASK_TV_EMAIL, requestPayload)
      .then(response => {
        this.setState({ loading: false });
        utilities.showToast("Thank you for your submission. We are currently processing your request and will contact shortly.", false, 'centerToast', 10000);
        this.onClear();
        this.handleClose()
      })
      .catch(error => {
        console.log(error);
        utilities.showToast("Something went wrong, please try again.", true);
        this.setState({ loading: false });
      });
  };



  onClear = () => {
    this.setState({ username: '', contactNumber: '', emailAddress: '', comment: '', ccmyself: true, reasonForContact:"", secondLevelVal:'', thirdVal:'',hereForYouPrefer:'Email', file: null, fileName:'', fileDetails: null})
  }

  editableUsers = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    });
  }

  onReasonForContactChange = (e, {value}) => {
    const { dropDownData } = this.state
    this.setState({
      reasonForContact: value
    });
    let { secondLevelOpt} = this.state;
    let opt = dropDownData.filter(opt=> opt.Level1 === value)
    const optValue = opt
    opt = _.uniqBy(opt, 'Level2').map(ele=> ele.Level2).filter(opt => opt !== '')
    secondLevelOpt = opt.map(ele=>{
      return {
        key:ele,
        value:ele,
        text:ele,
        className:'reasonMenu'
      }
    })
    this.setState({
      secondLevelOpt,
      firstLevelVal: value,
      thridLevelOpt:[],
      secondLevelVal:'',
      thirdVal:'',
      queue:''
    })
    if(secondLevelOpt.length === 0) {
       this.setState({
        queue: optValue[0].Queue
       })
    }
  }

  secondLevel = (e, {value}) => {
    const { dropDownData } = this.state
    let opt = dropDownData.filter(opt=> opt.Level2 === value)
    opt = _.uniqBy(opt, 'Level3').map(ele=> ele.Level3).filter(opt => opt !== '')
    let queue = []
    let { thridLevelOpt} = this.state;
    if(opt.length==0) {
      queue = dropDownData.filter(opt=> opt.Level2 === value).map(ele=> ele.Queue)
      thridLevelOpt = []
    }
    else {
      thridLevelOpt = opt.map(ele=>{
        return {
          key:ele,
          value:ele,
          text:ele,
          className:'reasonMenu'
        }
      })
    }
    this.setState({
      secondLevelVal: value,
      thridLevelOpt,
      thirdVal:'',
      queue: !_.isEmpty(queue) && queue.length > 0 ? queue[0] : ''
    })
  }
  thirdLeavel = (e, {value}) =>{
    const { dropDownData } = this.state
    let queue = dropDownData.filter(opt=> opt.Level3 === value).map(ele=> ele.Queue)
    this.setState({
      thirdVal: value,
      queue: !_.isEmpty(queue) && queue.length > 0 ? queue[0] : ''
    })
  }
  handleMinimize = () =>{
    let { username,contactNumber, emailAddress, reasonForContact, secondLevelVal,thirdVal, comment,firstLevelVal,queue,hereForYouPrefer } = this.state
     username = !_.isEmpty(username) ? username : '' 
     contactNumber = !_.isEmpty(contactNumber) ? contactNumber : this.props.phoneNumber
     emailAddress = !_.isEmpty(emailAddress) ? emailAddress : this.props.primaryEmail
    const data = {
      hfyUserName:username,contactNumber, emailAddress, reasonForContact, secondLevelVal,thirdVal, comment,firstLevelVal,queue,hereForYouPrefer
     }
    this.props.handleMinimize(data)
  }
  handleClose = () =>{
     const data = {
      hfyUserName: '' , contactNumber:this.props.phoneNumber, emailAddress:this.props.primaryEmail, reasonForContact:'', secondLevelVal:'',thirdVal:'', comment:'',firstLevelVal:'',queue:''
     }
    this.props.handleMinimize(data)
  }
  preferChange = (e) =>{
    this.setState({
      hereForYouPrefer: e.target.value
    })
  }
  render() {
    const { serverError } = this.state;
    return serverError ? (
      <InternalServerError />
    ) : (
        <Segment id="hereForYouInfo">
          <div>
          </div>
              <>
                    <div className="page-header displayInline hereForYouDiv handleCloseHereForYou">
                      <img src={hereForYouLogo} />
                      <div>
                        <span onClick={this.handleMinimize}><i className='icon minus'></i></span>
                        <span onClick={this.handleClose}><i className='icon close'></i></span>
                      </div>
                          </div>
                <form id="inputForm" className='hereForYouDiv'>                  
                  <div className="displayFlex askform" >
                    <span className="askTitle"><b>Name <span>*</span>:</b></span>
                    <div className="autoform">
                      <input
                        type="text"
                        value={this.state.username}
                        onChange={this.editableUsers}
                        className="askfield"
                        name="username"

                      />
                    </div>
                  </div>
                  <div className="displayFlex askform">
                    <span className="askTitle" ><b>Contact Number <span>*</span>:</b></span>
                    <div className="autoform" >
                      <input
                        type="text"
                        className="askfield"
                        onChange={this.editableUsers}
                        value={this.state.contactNumber}
                        name="contactNumber"
                      />
                    </div>
                  </div>
                  <div className="displayFlex askform">
                    <span className="askTitle" ><b>Email Address <span>*</span>:</b></span>
                    <div className="autoform">
                      <input
                        type="text"
                        className="askfield"
                        value={this.state.emailAddress}
                        name="emailAddress"
                        onChange={this.editableUsers}
                      />
                      <span className='emailTextMsg'>To Enter multiple emails, use either semicolons or commas as separators.</span>
                    </div>
                  </div> 
                  <div className="displayFlex askform">
                    <span className="askTitle"><b>How do you wish to be contacted?<span>*</span></b></span>
                    <div className="autoform hereEmail">
                      <input type="radio" className="askfield" name="username" checked = {this.state.hereForYouPrefer === 'Email'} value="Email" onChange={this.preferChange}/><label>Email</label>
                      <input type="radio" className="askfield" name="username" checked = {this.state.hereForYouPrefer === 'Phone'}  value="Phone" onChange={this.preferChange}/><label>Phone</label>
                    </div>
                  </div>                
                  <div className="displayFlex askform" >
                    <span className="askTitle" ><b>I have a question about<span>*</span>:</b></span>
                    <div style={{ borderColor: 'black' }}>
                      <Select className="reasonDropdown" placeholder='Select one' selection value={this.state.reasonForContact}onChange={this.onReasonForContactChange} options={this.state.firstLevelOpt} />
                    </div>
                  </div> 
                  {this.state.secondLevelOpt.length > 0 && (
                        <div className="displayFlex askform" >
                        <span className="askTitle" ><b>I have a question about {this.state.firstLevelVal}<span>*</span>:</b></span>
                        <div style={{ borderColor: 'black' }}>
                          <Select className="reasonDropdown" placeholder='Select one' selection value={this.state.secondLevelVal}onChange={this.secondLevel}options={this.state.secondLevelOpt} />
                        </div>
                      </div>
                  )} 
                   {this.state.thridLevelOpt.length > 0 && (
                        <div className="displayFlex askform" >
                        <span className="askTitle" ><b>I have a question about {this.state.secondLevelVal}<span>*</span>:</b></span>
                        <div style={{ borderColor: 'black' }}>
                          <Select className="reasonDropdown" placeholder='Select one' selection value={this.state.thirdVal}onChange={this.thirdLeavel }options={this.state.thridLevelOpt} />
                        </div>
                      </div>
                  )}             
                  <div className="displayFlex askform" >
                    <span className="askTitle" ><b>Please help us expedite your request by including all relevant information such as: invoice #, PO#, item #, credit memo #, etc. <span>*</span>:</b></span>
                    <div style={{ borderColor: 'black' }}>
                      <TextArea
                        name="comment"
                        className="commentask"
                        value={this.state.comment}
                        onChange={this.editableUsers}
                      />
                    </div>
                  </div>
                  <div className="displayFlex askform" >
                    <span className="askTitle" ><b>Attachment:</b></span>
                    <div style={{ borderColor: 'black' }}>
                    <div className='hereForYouUpload'>
                      <input type="file" name="upload-file" id="upload-file" className="custom-file-input"  onChange={this.handleFileInputChange}/>
                      <input type="text" readOnly className="choose-file-label"  value={this.state.fileName}/>
                      <label htmlFor="upload-file" className="custom-input-btn">Choose File</label>
                      <p className='sizeMsg'>Attachments must be JPEG, PDF, PNG, Excel, or Word files and should not exceed 2MB in size.</p>
                    </div>
                    </div>
                  </div>
                  </form>
                <div
                  className="displayFlex askform asksubmit hereforYouSubmit" >
                  <Button size='mini' color='red' onClick={this.handleSubmit}>Submit</Button>
                  <span
                    style={{
                      color: '#4183c4',
                      fontWeight: 'bolder',
                      paddingRight: '5rem',
                      paddingTop: '0.7rem'
                    }}
                  >
                    <u className="cursor_p" onClick={this.onClear}>Clear</u>
                  </span>
                </div>
              </>
        </Segment>
      )
  }
  
}

const mapStateToProps = state => {
  const {  UserId, storeId, multiStoreDetails, rdc } = state.SessionReducer;
  const { primaryEmail, phoneNumber, groupCollectionId } = state.SessionReducer.address;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    getprefer: state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    primaryEmail, phoneNumber, UserId, storeId, multiStoreDetails, rdc, groupCollectionId,
    hfyUserName: utilities.emptyForNull(state.SessionReducer.hfyUserName),
    contactNumber: utilities.emptyForNull(state.SessionReducer.contactNumber), 
    emailAddress: utilities.emptyForNull(state.SessionReducer.emailAddress),
    reasonForContact: utilities.emptyForNull(state.SessionReducer.reasonForContact),
    secondLevelVal:utilities.emptyForNull(state.SessionReducer.secondLevelVal),
    thirdVal: utilities.emptyForNull(state.SessionReducer.thirdVal),
    comment: utilities.emptyForNull(state.SessionReducer.comment),
    firstLevelVal: utilities.emptyForNull(state.SessionReducer.firstLevelVal),
    queue: utilities.emptyForNull(state.SessionReducer.queue),
    hereForYouPrefer: utilities.emptyForNull(state.SessionReducer.hereForYouPrefer),
    dropDownData: state.SessionReducer.hereForYouDropdown
  };
};
const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ setHereForYouDropdown}, dispatch)
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(HereForYou);
