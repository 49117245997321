import React from 'react';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { UPDATE_CONFIG } from "../../urlConstants";
import restUtils from "../../utils/restUtils";
import { getButton,getDatePicker } from "../common/ComponentUtilities";
import MenuOptionCheckbox from "../common/MenuOptionCheckbox";
import "../Orders/PromoShip/styles.css";
import { Loader } from "semantic-ui-react";
import "./admin.css"
import utilities from '../../utils/utilities';
import { connect } from 'react-redux';
import { ReunionOrdering } from '../Cart/actions';

class MenuOptions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      raChecked: false,
      ctbChecked: false,
      cocChecked: false,
      loading: false,
      editOrderChecked:false,
      startDate:'',
      endDate:'',
      isSlectedOption:1 
    };    
  }

  componentDidMount() {
    this.setState({ 
      raChecked : this.props.isRetailAssortmentsEnable,
      ctbChecked: this.props.isCtbAssortmentsEnable,
      cocChecked: this.props.isCloseoutCornerEnable,
      startDate : this.props.monthlySizzleSale?.startDate,
      endDate : this.props.monthlySizzleSale?.endDate,
      isSlectedOption : this.props.monthlySizzleSaleValue
    });
  }

  ordertoggleCheckBox = () => {
    this.setState(prevState => {
      return { editOrderChecked: !prevState.editOrderChecked };
    });
  }

  toggleCheckBox = () => {
    this.setState(prevState => {
      return { raChecked: !prevState.raChecked };
    });
  }

  toggleCtbCheckBox = () => {
    this.setState(prevState => {
      return { ctbChecked: !prevState.ctbChecked };
    });
  }

  toggleCocCheckBox = () => {
    this.setState(prevState => {
      return { cocChecked: !prevState.cocChecked };
    });
  }

  saveRetailAssortments = (selection) => {
    let selectedValue;
    let selectedDescription;
    if(selection == 'RA'){
      selectedValue=this.state.raChecked ? 1 : 0;
      selectedDescription='Retail Assortment';
    }else if(selection == 'CTBRA'){
      selectedValue=this.state.ctbChecked ? 1 : 0;
      selectedDescription='CTB Assortments';
    }else if(selection == 'MS3'){
      selectedValue=this.state.isSlectedOption;
      selectedDescription='Monthly Specials/Sizzle Sale';
    }else if(selection == 'COC'){
      selectedValue=this.state.cocChecked ? 1 : 0;
      selectedDescription='Closeout Corner';
    }else{
      selectedValue=this.state.editOrderChecked ? 1 : 0;
      selectedDescription='Edit Order';
    }
    this.setState({ loading: true });
    const { userName } = this.props;
    const requestData = {
      key: selection,
      value: selectedValue,
      description: selectedDescription,
      lastUpdateBy: userName
    };
    if(selection =='MS3'){
      requestData.timeZone='CST';
      requestData.startDate=this.state.startDate;
      requestData.endDate=this.state.endDate;
    }
    restUtils.putData(UPDATE_CONFIG, requestData)
    .then(response => {
      selectedDescription = selection == 'RA' || selection == 'CTBRA' || selection == 'COC' ? 'Assortments' : selectedDescription;
      if(['RA', 'COC'].includes(selection)){
        this.props.ReunionOrdering(this.props.storeId);
      }
      utilities.showToast(`${selectedDescription} updated successfully`);
      this.setState({loading: false})
    })
    .catch(error => console.log(error))
    .finally(this.setState({ loading: false }));
  }

  saveAssortments = () => {
    this.saveRetailAssortments('RA');
    this.saveRetailAssortments('CTBRA');
    this.saveRetailAssortments('COC');
  }

  retailAssortmentsOptions = () => {
    return (
    <MenuOptionCheckbox
      labelNameRA={'Show Retail Assortments'}
      headerName={'Enable Event(s)'}
      toggleCheckBoxRA={this.toggleCheckBox}
      propsCheckedRA={this.state.raChecked}
      labelNameCTB={'Show CTB Assortments'}
      toggleCheckBoxCTB={this.toggleCtbCheckBox}
      propsCheckedCTB={this.state.ctbChecked}
      submitButton={()=>this.saveAssortments()}
      propsCheckedCOC={this.state.cocChecked}
      labelNameCOC={'Show Closeout Corner'}
      toggleCheckBoxCOC={this.toggleCocCheckBox}
    />
    );
  }

  ctbAssortmentsOptions = () => {
    return (
    <MenuOptionCheckbox
      labelName={'Show CTB Assortments'}
      headerName={'CTB Assortments'}
      toggleCheckBox={this.toggleCtbCheckBox}
      propsChecked={this.state.ctbChecked}
      submitButton={()=>this.saveRetailAssortments('CTBRA')}
    />
    );
  }

  rowDatePickerComp = (id, value,fieldClass, isLabel,fieldName) => {   
    return getDatePicker(fieldName, id, value ? new Date(value) : null, this.datePickerOnChange, undefined, fieldClass, isLabel);
  }

  datePickerOnChange = (id,value) => {
    if(!value || value.length === 0) {
      return;
    } 
    value = new Date(value).toLocaleString().replace(/[^ -~]/g,'');   
    this.setState({[id]:value});
  }
  
  selctionSale=async(e)=>{
    await this.setState({isSlectedOption:e.target.value});
  }

  render() {
    const { loading } = this.state;
    
    return (
    <>
      {loading ? (
          <div style={{ "padding": "2rem" }}>
            <Loader active />
          </div>
        ): (
          <>
          <div className='displayFlex'> 
            <div>
                {this.retailAssortmentsOptions()}
            </div>
            <div>  
            <div className="res-menupage" style={{fontSize:'12px'}}>
            <div className='upload-link'>
              <div className='displayFlex page-header mb-2'>
              Monthly Specials/Sizzle Sale
              </div>
            </div>
            <div className="uploadMenuOption" style={{padding:"6px"}}>
            <div className="uploadRoot">
            <div className="prederenceCheck">           
            <input   type="radio" checked = {this.state.isSlectedOption == 1} onChange={this.selctionSale}
                name="sale_radio" id="isMonthlySelected" value='1' />
                <lable>Monthly Specials ( Default )</lable> &nbsp;&nbsp;
           <input   type="radio" checked={this.state.isSlectedOption == 2} onChange={this.selctionSale }
                name="sale_radio" id="isSizzleSelected" value="2" />
            <label>Sizzle Sale</label>&nbsp;&nbsp;
            <input   type="radio" checked={this.state.isSlectedOption == 3} onChange={this.selctionSale }
                name="sale_radio" id="isSizzleMonthlySelcted" value="3" />     
            <label>Monthly Specials/Sizzle Sale</label>
            </div>          
        </div>
        <div className='uploadRoot'>
          {this.rowDatePickerComp('startDate',this.state.startDate,'t-center datePicker', true,'Start Date')}   
        </div> 
        <div className='uploadRoot'> 
          {this.rowDatePickerComp('endDate',this.state.endDate,'t-center datePickerSale',true, 'End Date')}
        <div className=" buttonMenuOption">
          {getButton({type:'button', className:'fluid ui red button submit', name:'retailAssortmentsubmit', onClick:this.saveRetailAssortments.bind(this,'MS3')}, 'Submit')}
        </div> 
        </div>
        </div>
        </div>
        </div>
        </div>
        <br/>
      </>
      )}
    </>
    );
  } 
}

const mapStateToProps = state => {
  const { cart } = state;
  return {
    userName: state.SessionReducer.userName,
    storeId: state.SessionReducer.storeId,
    isRetailAssortmentsEnable: cart?.orders?.isRetailAssortmentsEnable,
    isCtbAssortmentsEnable: cart?.orders?.isCtbAssortmentsEnable,
    isCloseoutCornerEnable: cart?.orders?.isCloseoutCornerEnable,
    monthlySizzleSale: cart?.orders?.monthlySizzleSale,
    monthlySizzleSaleValue: cart?.orders?.monthlySizzleSaleValue
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
      { dispatch },
    bindActionCreators({ ReunionOrdering }, dispatch)
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuOptions);