import React, { Component } from 'react';
import '../common/filters.css';
import { connect } from 'react-redux';
import history from '../../history';

class ImFilters extends Component {
  handleFilterAction = e => {
    const qSelect = document.querySelector('#contents-1');
    const filterHeadElementImp = document.getElementById(e.target.name);
    if (e.target.checked) {
      filterHeadElementImp.className = 'filter-head impFilterchecked'
    } else if (e.target.name !== 'fh-types') {
      //redirect to landing page if area is unchecked
      history.push('/impulse-merchandising');
      return;
    } 
    else{
      let filterApplied = false;
      if(qSelect) {
        let filterCBs = qSelect.querySelectorAll('.filter-checkbox');
        if (!filterCBs) return;        
        filterCBs.forEach(cb => {
          if (cb.checked) {
            filterApplied = true;
            return;
          }
        })
      }
      if (!filterApplied){
        filterHeadElementImp.className = 'filter-head impFilterunchecked';
      }
    }
    this.props.callback(
      e.target.name === 'fh-types' ? 'Type' : 'Area',
      e.target.value
    );
  };
  componentWillReceiveProps(newProps) {
    if (newProps)
      if (newProps.clearFilters) {
        document.querySelectorAll('.filter-checkbox').forEach(cb => {
          cb.checked = false;
        });
        document.querySelectorAll('.filter-head').forEach(fh => {
          fh.style.backgroundColor = '#000000cc';
        });
        this.collapseAll();
      }
  }
  collapseAll = () => {
    const icons = document.getElementsByClassName('toggleIcon');
    Array.prototype.forEach.call(icons,(icon) => {icon.className = 'plus icon toggleIcon'});
    const contentDivs = document.getElementsByClassName('content-Div');
    Array.prototype.forEach.call(contentDivs,(contentDiv) => (contentDiv.style.display = 'none'));
  };
  toggleDiv = index => {
    const icon = document.getElementsByClassName('toggleIcon')[index];
    icon.className = icon.classList.contains('minus')
      ? 'plus icon toggleIcon'
      : 'minus icon toggleIcon';

    const contentDiv = document.getElementById('contents-' + index);
    contentDiv.style.display =
      contentDiv.style.display === 'none' ? 'block' : 'none';
  };
  render() {
    const categories = this.props.filters.filter(category => {
      return (
        category.Display_Name.indexOf('ENDCAPS') < 0 ||
        category.Category_Name === 'ENDCAPS'
      );
    });
    const endcapsTypes = this.props.filters.filter(category => {
      return (
        category.Display_Name.indexOf('ENDCAPS') > -1 &&
        category.Category_Name !== 'ENDCAPS'
      );
    });
    const selectedArea = this.props.appliedFilters.Area;
    const selectedTypes = this.props.appliedFilters.Type === 'null' ? null : decodeURIComponent(this.props.appliedFilters.Type);
    let filterColor = this.props.filtersApplied ? '#168c87' : '#ffffff';
    let shrunkFilterColor = this.props.filtersApplied ? '#168c87' : '#0c0c0c';

    return (
      <>
        {!this.props.isNoProducts && (
          <div>
            <i
              className='filter icon'
              style={
                this.props.showFilters
                  ? { display: 'none', color: shrunkFilterColor }
                  : { display: 'block', color: shrunkFilterColor }
              }
              onClick={this.props.toggleShowFilter}>
              <span className='expand-arrow'>»</span>
            </i>

            <div
              className='filters'
              style={
                this.props.showFilters
                  ? { display: 'block', color: filterColor }
                  : { display: 'none', color: filterColor }
              }>
              <span className='filter-head'>
                Refine Search
                <span
                  style={{
                    fontSize: '1rem',
                    color: filterColor,
                    float: 'right',
                    paddingRight: '0.5rem'
                  }}
                  onClick={this.props.toggleShowFilter}>
                  <i className={selectedArea? 'applied-filter filter icon':'filter icon'} style={{ verticalAlign: 'top' }} />
                  <span className={selectedArea? 'applied-filter shrink-arrow':'shrink-arrow'} >«</span>
                </span>
              </span>
              <div className='filter-section' id={'fs-categories'}>
                <span
                  style={{ fontFamily: 'HelveticaNeue' }}
                  className= {selectedArea? 'filter-head impFilterchecked':'filter-head'}
                  id={'fh-categories'}>
                  Areas
                  <i
                    className='plus icon toggleIcon'
                    style={{ float: 'right' }}
                    onClick={() => this.toggleDiv(0)}></i>
                </span>
                <div id={'contents-0'} style={{ display: 'none' }}>
                  {categories.map(category => {
                    return (
                      <div
                        className='ui checkbox'
                        style={{
                          display: 'block',
                          padding: '0.255rem 0.25rem'
                        }}
                        key={category.Category_Name}>
                        <input
                          type='checkbox'
                          className='filter-checkbox'
                          key={category.Category_Name}
                          name='fh-categories'
                          onChange={this.handleFilterAction}
                          value={category.Category_Name}
                          checked={
                            selectedArea === category.Category_Name ||
                            (selectedTypes !== null &&
                              category.Category_Name === 'ENDCAPS')
                          }
                          style={{ padding: '0.3rem 0.5em', fontSize: '1rem' }}
                        />
                        <label>{category.Display_Name}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
              {(selectedTypes || selectedArea === 'ENDCAPS') && (
                <div className='filter-section' id={'fs-types'}>
                  <span
                   className= {selectedTypes? 'filter-head impFilterchecked':'filter-head'}
                    style={{ fontFamily: 'HelveticaNeue' }}
                    id={'fh-types'}>
                    Types
                    <i
                      className='plus icon toggleIcon'
                      style={{ float: 'right' }}
                      onClick={() => this.toggleDiv(1)}></i>
                  </span>
                  <div id={'contents-1'} style={{ display: 'none' }}>
                    {endcapsTypes.map(category => {
                      return (
                        <div
                          className='ui checkbox'
                          style={{
                            display: 'block',
                            padding: '0.255rem 0.25rem'
                          }}
                          key={category.Category_Name}>
                          <input
                            type='checkbox'
                            className='filter-checkbox'
                            key={category.Category_Name}
                            name='fh-types'
                            onChange={this.handleFilterAction}
                            value={category.Category_Name}
                            checked={selectedTypes?selectedTypes.includes(category.Category_Name):false}
                            style={{
                              padding: '0.3rem 0.5em',
                              fontSize: '1rem'
                            }}
                          />
                          <label>{category.Display_Name}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    filters: state.ImReducer.categories
  };
};

export default connect(mapStateToProps)(ImFilters);
