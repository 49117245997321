import React, { Component } from 'react';
import {connect} from 'react-redux'
import { Segment, Loader, Grid } from 'semantic-ui-react'
import restUtils from '../../utils/restUtils';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import './styles.css'
import search_img from '../images/Search.svg';
import renderImages from '../common/RenderImages';
import RetailAssortmentLandingList from './RetailAssortmentLandingList';
import RetailAssortmentLandingGrid from './RetailAssortmentLandingGrid';
import { RETAILASSORTMENT } from '../../urlConstants';
import utilities from '../../utils/utilities';
import _ from 'lodash';
import { isArray } from 'util';
import Filter from '../common/Filter';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import { RETAIL_ASSORTMENT, REUNION_ASSORTMENT, CTB_ASSORTMENT,CTB_ASSORTMENT_DEPT } from '../common/constants'

class RetailAssortments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assortments: [],
      displayType:  (this.props.getprefer && this.props.getprefer.prdctListlayoutFormat && this.props.getprefer.prdctListlayoutFormat!=='L')?'grid':'list',
      isNoProducts: false,
      serverError: false,
      loading: false,
      seleDeptName: '',
      searchType: this.props.match?.path === CTB_ASSORTMENT_DEPT ? 'description':'itemNumber',
      filters: [],
      clearFilters: false,
      appliedFilters: {},
      endDate : '',
      searchNumber:'',
      backPath:'',
      isCtbAssortment: (this.props.match?.path === CTB_ASSORTMENT_DEPT || this.props?.fromScanning) ? true : false,
      isReunionAssortment: this.props.match?.path===REUNION_ASSORTMENT,
      showFilters:(this.props.getprefer && this.props.getprefer.filterView) ? (this.props.getprefer.filterView==='E' && window.innerWidth > 1025)?true:false:window.innerWidth >= 1024?true:false,
    }
    this.sortParam = this.props.match?.path === CTB_ASSORTMENT_DEPT ? 'description':'';
    this.sortDirection = '';
    this.searchParam = this.props.match?.path === CTB_ASSORTMENT_DEPT ? 'description':'itemNumber';
    this.searchNumber='';
    this.handleSort = this.handleSort.bind(this);
    this.filterString = {};
    this.pathname = '';
    this.appliedFilters = {};
    this.patterns = {
      itemNumber: '[0-9]+',
      assortNumber: '[a-zA-Z0-9-]+'
    };
    this.scrollPosition = 0;
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else if(this.props?.fromScanning) {
      this.getScannedData()
    }
    else{
      this.getAssortmentData();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      deptName,
      searchType,
      searchNumber
    } = nextProps.match ? nextProps.match.params : this.state;
    this.filterString = {};
    this.appliedFilters ={};
    this.searchParam = "itemNumber";
    this.searchNumber='';
    this.sortParam = '';
    this.sortDirection = '';
    this.setState({
      appliedFilters: {},
      clearFilters : true,
      searchNumber:'',
    }); 
    if (
      this.state.seleDeptName !== deptName ||
      this.state.searchType !== searchType ||
      this.state.searchNumber !== searchNumber
    ) {
      this.pathname = nextProps.location && nextProps.location.pathname;
      this.getAssortmentData(nextProps);    
    }
  }

  handleSort(e) {
    this.setState({ displayType: e });
  }
  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  onFilterChange = e => {
    const { validity = {}, value } = e.target;
    this.scrollPosition = window.pageYOffset;
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    this.setState({
      searchNumber: value
    });
  };
  filterCallback = (isAdd, filterKey, filterValue) => {
    let appliedFilters = this.state.appliedFilters;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
        appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
        appliedFilters[filterKey] = isArray(appliedFilters[filterKey]) ? appliedFilters[filterKey] : appliedFilters[filterKey].split(",")
        let value = appliedFilters[filterKey];
        let index = value.indexOf(filterValue);
        if (index > -1) {
            value.splice(index, 1);
        } else {
            value.push(filterValue);
        }
        if (!value.length && keyExists) delete appliedFilters[filterKey];
        else appliedFilters[filterKey] = value;
    }
    this.appliedFilters = appliedFilters;
    if (!appliedFilters.hasOwnProperty('Department') && this.state.seleDeptName) {
      appliedFilters = { ...appliedFilters, ['Department']: [this.state.seleDeptName]  };
    }
    this.filterString = utilities.getQueryJsonCtbAssortment(appliedFilters);
    this.setState({
        appliedFilters,
        clearFilters: false
    });
    this.getFilteredData();
};
getScannedData = () =>{
  const url = `${RETAILASSORTMENT}`;
  const sortArr = { 
    sortParam: this.sortParam.length > 0 ? this.sortParam :'description', 
    sortType: this.sortDirection.length > 0 ? this.sortDirection : 'asc'
  };
  let requestPayLoad = { 
    memberNo: this.props.storeId,   
    searchBy: {}, 
    sortBy: {sortParam: "description", sortType: "asc"},
    rdc:this.props.rdc,
    aliasSKURetailerGrpID:this.props.aliasSKURetailerGrpID,
    type:'CTB',
    subCategoryId:this.props.subCat,
  }
  const fitlerUrl = url+`/Filter`
  this.setState({ loading: true });
  restUtils
    .postData(fitlerUrl, requestPayLoad)
    .then(response => {
      const data = response.data.departmentList[0];
      const assortmentsList = data.assortmentsList || [];
      this.setState({ 
        isNoProducts: !data || data.assortmentsList.length === 0, 
        loading: false, 
        serverError: false,
        assortments: assortmentsList,
        endDate:response.data.endDate,
        filters: data.filters
      }, () => {
        window.scrollTo(0,this.scrollPosition);
        this.setBackButtonPath();
        });
      })
    .catch(error => {
      console.log(error);
      this.setState({ loading: false, serverError: true });
    });
}
getUrlFilters = (queryParam) => {
  let { deptName } = this.props.match ? this.props.match.params : this.state;
  let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
  if(searchParam !== ''){
    let search = searchParam.split('=');
    this.searchParam = search[0];
    this.searchNumber = search[1].split('&')[0];
  }
  this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
  this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                        queryParam[1].includes('SortType=desc') ? 'desc' : '';
  this.scrollPosition = queryParam[1].includes('scrollPosition=') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
  this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
  this.appliedFilters = utilities.getFilterArrayDecode(this.filterString);
  if (!this.appliedFilters.hasOwnProperty('Department') && deptName) {
    this.appliedFilters = { ...this.appliedFilters, ['Department']: [deptName]  };
  }
  this.filterString = utilities.getQueryJsonCtbAssortment(this.appliedFilters);
  this.setState({
    seleDeptName: deptName,
    appliedFilters : this.appliedFilters,
    searchNumber : this.searchNumber,
    backPath : this.pathname
  }, () => {
    this.getFilteredData();
  });
}

setBackButtonPath = () => {
  let pathName = '';
  if(this.searchNumber.length > 0){
    pathName = pathName + '&searchBy='+this.searchParam+'='+this.searchNumber;
  }
  if (this.sortParam && this.sortParam.length > 0) {
    pathName = pathName + '&SortParam='+this.sortParam;
    if (this.sortDirection && this.sortDirection.length > 0) {
      pathName = pathName + '&SortType='+this.sortDirection;
    } else {
      pathName = pathName + '&SortType=asc';
    }
  }  
  pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition='+this.scrollPosition : pathName;
  if (!_.isEmpty(this.appliedFilters)) {
    pathName = pathName + '&filterString='+ utilities.getQueryStrForCtbFilterString(this.appliedFilters);
  }
  let pushUrl = '';
  if(pathName !== ''){
    pushUrl = this.pathname +'?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }else{
    pushUrl = this.pathname;
  }
  this.setState ({
    backPath : pushUrl
  })
}

setScrollPosition = () => {
  this.scrollPosition = window.pageYOffset;
  this.setBackButtonPath();
}

getFilteredData = () => {
  let searchArr = {}
  const url = `${RETAILASSORTMENT}`;
  if(this.filterString.filterBy === undefined || this.filterString.filterBy.length === 0){
    const queryJson=[];
    const deptArr = {
      "name": "Department", 
      "valueList": [this.state.seleDeptName]
    }; 
    queryJson.push(deptArr)
    this.filterString = {filterBy: queryJson};
  }
  if(this.searchNumber){
    searchArr = {
      name: this.searchParam,
      valueList: this.searchNumber.split(",") }
  }
  const sortArr = { 
    sortParam: this.sortParam.length > 0 ? this.sortParam :'saving', 
    sortType: this.sortDirection.length > 0 ? this.sortDirection : 'asc'
  };
 
  let requestPayLoad = {
    isAdmin:this.props.showAdminMenu?"Y":"N",
    memberNo: this.props.storeId,   
    ...this.filterString, 
    searchBy: searchArr, 
    sortBy: sortArr,
    rdc:this.props.rdc,
    aliasSKURetailerGrpID:this.props.aliasSKURetailerGrpID,
    ...(this.state.isCtbAssortment ? {type:'CTB'}: null)
  }
  const fitlerUrl = url+`/Filter`
  this.setState({ loading: true });
  restUtils
    .postData(fitlerUrl, requestPayLoad)
    .then(response => {
      const data = response.data.departmentList[0];
      document.getElementById('search_RetailAssortment').value='';
      this.setState({ 
        isNoProducts: !data || data.assortmentsList.length === 0, 
        loading: false, 
        serverError: false,
        assortments: data.assortmentsList,
        endDate:response.data.endDate,
        filters: data.filters
      }, () => {
        window.scrollTo(0,this.scrollPosition);
        this.setBackButtonPath();
        });
      })
    .catch(error => {
      console.log(error);
      this.setState({ loading: false, serverError: true });
    });
}
  changeSortParam = e => {
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  };

  changeSearchParam = e => {
    this.searchParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.setState({searchType: this.searchParam,searchNumber: ''});
  };

  getAssortmentData = (newProps) => {
    let { deptName } = newProps ? newProps.match.params : this.props.match ? this.props.match.params : this.state;
    this.setState({
      loading: true,
      seleDeptName: deptName
    })
     deptName = encodeURIComponent(deptName);
    const {storeId, rdc, showAdminMenu} = this.props;
    const url = `${RETAILASSORTMENT}`;
    const sortParam = this.sortParam.length > 0 ? this.sortParam :'saving';
    const sortType = this.sortDirection.length > 0 ? this.sortDirection : 'asc';
    const modifiedUrl = this.state.isCtbAssortment ? 
    `/List?departmentID=${deptName}&memberNo=${storeId}&sortParam=${sortParam}&sortType=${sortType}&rdc=${rdc}&type=CTB&isAdmin=${showAdminMenu?"Y":"N"}` :
    `/List?departmentID=${deptName}&memberNo=${storeId}&sortParam=${sortParam}&sortType=${sortType}&rdc=${rdc}`
    restUtils.getData(
      url+`${modifiedUrl}`
    ).then(response => {
        const data =response.data.departmentList[0];
        this.setState({
          assortments: data?data.assortmentsList:[],
          endDate: response.data?response.data.endDate:'',
          filters: data?data.filters:[],
          loading: false,
          serverError: false,
          isNoProducts: !data || !data.assortmentsList || data.assortmentsList.length === 0 
        }, () => {
            window.scrollTo(0,this.scrollPosition);
            this.setBackButtonPath();
          });
        })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }
  
  searchItems = (e) => { 
    this.searchNumber =this.state.searchNumber;
    this.scrollPosition = window.pageYOffset;
    if(this.state.searchType === 'itemNumber' && parseInt(this.searchNumber) === 0){
      utilities.showToast('Invalid item number');
      return;
    }
    this.getFilteredData();
  }

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  getHeader = () =>{
    const pathName = this.props.match?.path;
    switch (pathName){
        case RETAIL_ASSORTMENT: 
          return "Retail Assortments";
        case CTB_ASSORTMENT_DEPT: 
          return "Customized True Blue Assortments";
        case REUNION_ASSORTMENT:
          return "Reunion Retail Assortments" + " - " + this.state.seleDeptName ;
        default : 
          return "Retail Assortments";
    }
  }

  render() {
    const isCtbAssortment = this.state.isCtbAssortment
    const isReunionAssortment = this.state.isReunionAssortment
    const pathName = this.props.match?.path;
    const fromScanning = this.props?.fromScanning
    let AssortmentLinkPage = '/retailAssortments/'+this.state.seleDeptName;
    let AssortmentLinkPageReunion = '/reunionAssortments/'+this.state.seleDeptName;
    
    const breadcrumbPath = isCtbAssortment ? [{ link: '/home', text: 'HOME' },
      { link: '/ctbAssortments', text: 'CTB Assortments' },
      { link: AssortmentLinkPage, text: this.state.seleDeptName } ] : 
      [{ link: '/home', text: 'HOME' },
      { link: AssortmentLinkPage, text: this.state.seleDeptName } ];
    const breadcrumbPathReunion = [{ link: '/home', text: 'HOME' },
      { link: '/reunionAssortment', text: 'Reunion Retail Assortments' },
      { link: AssortmentLinkPageReunion, text: this.state.seleDeptName } ];
    return (
      <div >
        {this.state.loading &&
          <div>
            <Loader active />
          </div>
        }
        {this.state.serverError ? <InternalServerError /> : <>
          <Segment id="retail_assortments" >
            <div className='page-header'>
              {fromScanning ? 'Customized True Blue Assortments':this.getHeader()}
            </div>
            {isReunionAssortment ?(<BreadcrumbComp path={breadcrumbPathReunion}/>
                        ):
                       (<BreadcrumbComp path={breadcrumbPath}/>)
                    }
            <br/><br/>
            {fromScanning && !_.isEmpty(this.state.assortments) &&  <h2>{this.state.assortments[0].sub_cat_desc}</h2>}
            {!fromScanning && (
            <div>
              <div className='ui input' id='input_searchReatil'>
              {isReunionAssortment ? (<span>{window.innerWidth < 768 ? 'Search' : 'Assortment Model #'}</span>
                ):
                (<span>{window.innerWidth < 768 ? 'Search' : isCtbAssortment ? 'CTB Assortment Search': 'Retail Assortment Search'}</span>)
              }
                <select id='pl_dropdown' onChange={this.changeSearchParam} value={this.searchParam}>
                   {isCtbAssortment ? (
                    <>
                    <option value="description">Description</option>
                    <option value="itemNumber">Item #</option>
                    <option value="pogNumber">POG #</option>
                    </>
                   ): <>
                    <option value="itemNumber">Item #</option>
                    <option value="assortNumber">Model #</option>
                    {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && (
                    <option value={'alternateSKU'}>Alternate SKU#</option>)}
                    </>
                   }
                </select>
                <input
                  id='search_RetailAssortment'
                  onKeyDown={this.handleEnterKey}
                  pattern={this.patterns[this.state.searchType]}
                  onChange={this.onFilterChange}
                  value={this.state.searchNumber}
                />
                {renderImages.getImage({ 
                  src: search_img, 
                  className:'search_icon_address', 
                  onClick: this.searchItems
                })}
              </div>
              <div className="sort_filter">
                <span className='filter-label'>Sort by</span>
                <select className='sort-criteria combo savingcomb' onChange={this.changeSortParam} value={this.sortParam} id='pl_dropdown'>
                  {isCtbAssortment ? <><option value='description'>Description</option><option value='saving'>Markdown</option></>:<option value="saving" >Savings</option>}
                   {/* Removed as per the business instruction */}
                  {/* <option value="MFO" >Market First Added</option> */}
                  <option value="Dating" >Dating</option>
                  {isCtbAssortment && <option value='size'>Size</option>}
                </select>
                <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection} id='sortAssortmentOrder'>
                  <option value='asc'>Ascending</option>
                  <option value='desc'>Descending</option>
                </select>
                {window.innerWidth >= 1023 &&
                  <span id='icon_align'> 
                  <i 
                    className='list icon cursor_p' 
                    value='List' 
                    id='list_icon' 
                    onClick={() => this.handleSort('list')}></i>{' '} 
                  | 
                  <i 
                    className='th icon cursor_p' 
                    value='Grid' 
                    id='grid_icon' 
                    onClick={() => this.handleSort('grid')}></i> 
                </span>
                }
              </div>
            </div>
            )}
            
            <div className={window.innerWidth > 1023 ? "displayFlex allOrdersTable" : "allOrdersTable"}>
              {!fromScanning && (
                <Filter
                    clearFilters={this.state.clearFilters}
                    filtersApplied={!_.isEmpty(this.state.appliedFilters)}
                    appliedFilters={this.state.appliedFilters}
                    toggleShowFilter={this.toggleShowFilter}
                    showFilters={this.state.showFilters}
                    filters={this.state.filters}
                    callback={this.filterCallback}
                    isNoProducts={this.state.isNoProducts}
                    selecDept={this.state.seleDeptName}
                    screenName={'RetailAssortments'}
                    loading={this.state.loading}
                />
              )}              
              {this.state.displayType === 'list' &&  window.innerWidth >=1023 ?
                <RetailAssortmentLandingList 
                  assortment={this.state.assortments} 
                  endDate={this.state.endDate} 
                  showAssortmentList={this.showAssortmentList}
                  isNoProducts={this.state.isNoProducts}
                  setBackButtonPath={this.setScrollPosition}
                  backPath={this.state.backPath}
                  isReunionAssortment={this.state.isReunionAssortment}
                  isCtbAssortment = {this.state.isCtbAssortment}
                  fromScanning = {this.props?.fromScanning}
                  subCat = {this.props.subCat}
                /> :
                <RetailAssortmentLandingGrid 
                  assortment={this.state.assortments} 
                  endDate={this.state.endDate} 
                  showAssortmentList={this.showAssortmentList}
                  isNoProducts={this.state.isNoProducts}
                  setBackButtonPath={this.setScrollPosition}
                  backPath={this.state.backPath}
                  isReunionAssortment={this.state.isReunionAssortment}
                  isCtbAssortment = {this.state.isCtbAssortment}
                  fromScanning = {this.props?.fromScanning}
                  subCat = {this.props.subCat}
                />
              }
            </div>
          </Segment>
        </>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const storeId = state.SessionReducer.storeId;
  return {
    userId:state.SessionReducer.UserId,
    showAdminMenu: state.SessionReducer?.address?.showAdminMenu,
    storeId,
    getprefer:state.preference.listData,
    rdc:state.SessionReducer.rdc,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
  };
}

export default connect(mapStateToProps, null)(RetailAssortments);