import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, ItemMeta, Table, Dimmer, Button} from 'semantic-ui-react';
import QuickViewDetails from './QuickViewDetails';
import Dialog from 'react-dialog';
import renderImages from '../common/RenderImages';
import { MaskPrice } from '../common/MaskPrice';
import { TierIcon, ProductIndicators } from './TierIcon';
import { computeGrossMargin } from '../ImpulseMerchandising/POG';
import utilities,{maskPrice,shouldHideCostForPreference,getPrefernceType,shouldHideOrigAndCurrCostForPreference,
  shouldHideSUGG_RETAILForPreference,showSRPMaskPricePref,getCloseoutRegularCost, getCloseoutCurrentCost, dsNonStockedItem} from '../../utils/utilities';
import { closeModal, addimg, delimg, isMaxQtyValid } from './productHandler';
import { addProduct, createNAddCart, orderTypes,chooseCart } from '../Cart/actions';
import { orderNames } from '../Cart/constants';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import AddToCartModal from '../Cart/AddToCartModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../history';
import BusinessRules from './BusinessRules'; 
import { nonStockMessage } from './constants';
import { 
  RETAIL_ASSORTMENTS,
  CLOSEOUTS, 
  IMPULSE_MERCHANDISING, 
  DISCONTINUED, 
  HOTDEALS,
  MONTHLY_SPECIALS ,
  DIRECTSHIP
} from '../Categories/constants';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import _ from 'lodash';

const checkForHubAndSpokeIndictor  = (product, listType) => {
  const isHubAndSpoke = listType === 'HotDeals' 
                        ? product && product.rdcType === 'S' && product.IsHubPlusSpokeQty === true 
                        : product && product.primaryWarehouseType === 'S' && product.IsHubPlusSpokeQty === true;
  return  (
    <>
    {listType === 'HotDeals' ? product.available : product.Available}
    {isHubAndSpoke && <>
    {' '}
      <span className='hnsInd'>
        {'H&S'} 
      </span> </>}
      </>
  );
}

class ProductListingGrid extends Component {
  constructor(props) {
    super(props);

    this.closeModal = closeModal.bind(this);
    this.addimg = addimg.bind(this);
    this.delimg = delimg.bind(this);

    this.state = {
      visible: false,
      show: false,
      productFav:'',
      item: '',
      click: '',
      quickviewdata: '',
      productSelectedForModal: null,
      isMultistoreModalOpen:false,
      indexForModal: 0,
      indexForModalMs:0,
      isAddToCartModalOpen: false,
      buttonVisible: false,
      toastMessage: '' //  Product added to cart..,
    };
  }

  multistoreHandleCarts= (product, index) => {
    this.setState({
      productSelectedForModal: product,
      indexForModal: index,
      indexForModalMs: index,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.setState({ isMultistoreModalOpen: false });
  }

  openCartModal = (product, index) => {
    this.setState({
      productSelectedForModal: product,
      indexForModal: index,
      isAddToCartModalOpen: true
    });
  };
  closeCartModal = () => {
    this.setState({ isAddToCartModalOpen: false });
  };

  enterFav = (Item_num) =>{
    this.setState({
      show:true,
      productFav:Item_num
    })
  }
  leaveFav  = (product) =>{
    this.setState({
      show:false,
      productFav:''
    })
  }

  openModal(data) {
    this.setState({
      visible: true,
      item: data,
      click: 'clicked'
    });
    this.props.blurfun && this.props.blurfun(true);
  }
  handleShow = ()=>{
    this.setState({buttonVisible: true});
  }
  handleHide = ()=>{
    this.setState({buttonVisible:false});
  }
  handleDirectShipAddToCart =(product, index) =>{
    const { addToCart, storeId } = this.props;
    const qty = this.props.quantities[index];
    if (
      !this.props.validateProductQuantity(
        'F',
        product.s_pack_qty,
        qty,
        null
      )
    ) {
      return;
    }
    if (product.whStockStatusMessage && product.whStockStatusMessage.toLowerCase() == 'not stocked') {
      utilities.showToast(nonStockMessage, true);
      return;
    }
    const vendorId = this.props.dsCarts.length > 0 && this.props.dsCarts.find(cart => parseInt(cart.VendorId) === parseInt(this.props.vendorId))
    let cartId = !_.isEmpty(vendorId) ? vendorId.cartId : null
    addToCart(
      product.Item_Number,
      this.props.vendorId,
      product.UpcNum,
      product.Model,
      qty,
      product.DSCost,
      cartId,
      storeId
    ); 
    if(!_.isEmpty(vendorId)) {
      this.props.chooseCart(vendorId)
    }
    }
  handleAddToCart = (product, index) => {
    const { tableType, addToCart, isShowAllWarehouse, storeId } = this.props;
    const qty = this.props.quantities[index];
    const or = this.props.overrideRetails[index];
    const rdcNum = utilities.rdcBasedonCentralship(product.rdctype, product.CsRDC, this.props.rdc);
    if (isShowAllWarehouse && qty > product.Available) {
      history.push({
        pathname: `/all-warehouses/${product.Item_Number}`,
        state: { fromPLP: true }
      });
      return;
    }
    if (
      !this.props.validateProductQuantity(
        product.S_Pack_Type,
        product.S_Pack_Qty,
        this.props.quantities[index],
        product.maxQty
      )
    ) {
      return;
    }
    if(tableType=='Monthly Specials') {
      let qtyValues = qty.filter(val=> {
        if(parseInt(val)) {
          return parseInt(val)
        }
      })
      let totalQty = qtyValues.length > 0 && qtyValues.reduce((a,b) => parseInt(a)+parseInt(b))
      if(!isMaxQtyValid(totalQty,product.maxQty,false)) {
        return
      }
    }
    const isAssortmentListing =
      tableType === RETAIL_ASSORTMENTS ||
      tableType === 'Planograms' ||
      tableType === 'DOM' ||
      tableType === IMPULSE_MERCHANDISING ||
      tableType === 'Reunion' ||
      tableType === MONTHLY_SPECIALS;
    if (
      !isAssortmentListing &&
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length > 0
    ) {
      this.openCartModal(product, index);
    } else if (
      !isAssortmentListing &&
      this.props.currentCartType !== 'REGULAR' &&
      this.props.whCarts.length === 0
    ) {
      addToCart(
        product.Item_Number,
        product.Member_Cost,
        qty,
        or,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        'new',
        rdcNum,
        storeId
      );
    } else if (tableType === 'Reunion') {
      addToCart(
        product.Item_Number,
        index,
        product.relay_nbr,
        product.Vendor_Id,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        product.promo_nbr,
        storeId
      );
    } else if (tableType === MONTHLY_SPECIALS) {
      const  carts = this.props.msCarts;
      const eventId = carts && carts.filter(cart => cart.cartName === `EVENT#${this.props.eventId}`)
      let newCart = (carts.length === 0 || eventId.length === 0) ? true : false
      addToCart(
        product.Item_Number,
        index,
        product.RelayNbr,
        product.PromoNbr,
        product.vendorID,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        storeId,
        newCart,
        product.maxQty
      );
    } else if (tableType === IMPULSE_MERCHANDISING) {
      const imInfoType = orderNames[IMPULSE_MERCHANDISING].infoType;
      const existingCart = this.props.carts.find(
        cart => cart.TypeId === imInfoType
      );
      if (!existingCart) {
        this.props.createNAddCart(
          product.Item_Number,
          qty,
          or,
          product.Member_Cost,
          'REGULAR',
          imInfoType,
          orderTypes[imInfoType],
          this.props.rdc,
          storeId
        );
      } else {
        this.props.addProduct(
          existingCart.cartId,
          product.Item_Number,
          qty,
          or,
          product.Member_Cost,
          'REGULAR',
          existingCart.cartName,
          this.props.rdc,
          storeId
        );
      }
    } else if (tableType === 'DOM'){
        addToCart(
          product.Item_Number,
          product.spc_cost,
          qty,
          or,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          product.promo_nbr
        );
    }else {
      if (product.whStockStatusMessage && product.whStockStatusMessage.toLowerCase() == 'not stocked') {
        utilities.showToast(nonStockMessage, true);
        return;
      } else{
        addToCart(
          product.Item_Number,
          product.Member_Cost,
          qty,
          or,
          product.S_Pack_Type,
          product.S_Pack_Qty,
          null,
          rdcNum,
          storeId
        );
      }
    }
  };
  handleKeyDown = (e, product, index)=>{
    if(e.key === 'Enter' && this.props.listType === DIRECTSHIP) {
      this.handleDirectShipAddToCart(product, index)
      return
    }
    const msCheck = utilities.msExist(this.props.multiStoreDetails);
    if(this.props.multiStoreDetails.access !== 1 && e.key === 'Enter' && !this.props.isReunionOnlyStore){
        if(msCheck && msCheck > 1){
          this.multistoreHandleCarts(product, index);
        }
        else{
          this.handleAddToCart(product,index);
        }
    }
  }

  render() {
    const link = '/product/';
    const {
      tableType,
      //categoryData: products,
      quantities,
      overrideRetails,
      handleChange,
      handleChangeblur,
      addToCart,
      assortmentId,
      assortmentName,
      isShowAllWarehouse
    } = this.props;
    let { categoryData: products} =  this.props
    if(this.props?.isCtbAssortment && [0, 2].includes(parseInt(this.props?.missing))) {
      products = products.filter(product=>{
        if(parseInt(product.IsMissingItem)=== 0) {
          return product
        }
        else {
          return
        }
      })
    }
    const isReunionListing = tableType === 'Reunion';
    const isHotDeals = tableType === HOTDEALS;
    const isMonthlyListing = tableType === MONTHLY_SPECIALS;
    const isImListing = tableType === IMPULSE_MERCHANDISING;
    const isDefaultListing = tableType === 'default';
    const isScanListing =
      tableType === ('tvProductScan' || 'competitorProducts');
    const isAssortmentListing =
      tableType === 'RetailAssortments' ||
      tableType === 'Planograms' ||
      tableType === 'DOM' ||
      tableType === IMPULSE_MERCHANDISING;
      let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const getPrefer =  getPrefernceType(this.props);
    const displayMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType,getPrefer);
    const showSuggestedRetailPrice  = shouldHideSUGG_RETAILForPreference(this.props.viewType,getPrefer);
    const showSRPMaskPrice = showSRPMaskPricePref(this.props.viewType,getPrefer);
    return (
      <>
        <AddToCartModal
          isAddtoCartModalopen={this.state.isAddToCartModalOpen}
          closeCartModal={this.closeCartModal}
          addToCart={addToCart}
          product={this.state.productSelectedForModal}
          index={this.state.indexForModal}
          qty={quantities[this.state.indexForModal]}
          or={overrideRetails[this.state.indexForModal]}
        />
        <MultistoreToCartModal
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          assortmentId={this.props.assortmentId}
          multiStoreCartavailable={this.props.multiStoreCartavailable}
          overrideRetail = {this.props.overrideRetails[this.state.indexForModalMs]}
          closeMultistoreModal={this.closeMultistoreModal}
          tableType={this.props.tableType}
          product={this.state.productSelectedForModal}
          quantitiesMs={this.props.quantities}
          indexCheck={this.state.indexForModalMs}
          handleChangeValue={this.props.handleChangeValue}
      />
        <div className={tableType === 'RetailAssortments'
                                        ? 'product-grid ra-grid grid-columns ui six doubling cards'
                                        : tableType === MONTHLY_SPECIALS ? 'product-grid grid-columns ui four doubling cards' :'product-grid grid-columns ui six doubling cards'} id={this.props.listType === DIRECTSHIP &&  'ds_gridListing'}>
          {products &&
            products.map((product, index) => {
              const isDiscontinuedHideAddToCart = product.whStockStatus === 'DIS' ? true : false
              const isHighlight = (tableType === IMPULSE_MERCHANDISING || tableType === 'Planograms') 
              &&  parseInt(product.recQty) !== parseInt(quantities[index]);
              return (
                <>
                {this.props.listType !== DIRECTSHIP && this.state.visible && this.state.item === product.Item_Number && (
                  <div className='QuickviewDialoggrid' onMouseLeave={this.closeModal}>
                    <Dialog width='1' height='1' position='absolute'>
                      <QuickViewDetails  tableType={tableType} Item_Number={product.Item_Number} productPlp = {product} />
                    </Dialog>
                  </div>
                )}
                <div
                style = {{position: 'relative'}}
                id={tableType === IMPULSE_MERCHANDISING ? 'cart-clip' : ''}
                  className={
                    this.state.click === 'clicked'
                      ? isHighlight ? 'highlight card_item blur_state grid-column card' : 'card_item blur_state grid-column card'
                      : isHighlight ? 'highlight card_item ui grid-column card' : ' card_item ui grid-column card'
                  }
                  // id={isAssortmentListing || this.props.multiStoreDetails.access !== 1  
                  //               ?'grid_segment_assort'
                  //               :'grid_segment'}
                >
                  <div>
                  {!isAssortmentListing && (
                    <div style={{ marginBottom: '0' }} className = 'grid-header'>
                      {(isReunionListing || isMonthlyListing)  ? (
                        <span className='compare-n-fav'>
                          SEQ# :{product.seq_nbr}
                        </span>
                      ) : (
                        <>
                        {this.props.listType !== DIRECTSHIP && (
                          <>
                         {!isScanListing && (
                            <div>
                            <i
                              className={
                                product.favorited
                                  ? 'heart icon'
                                  : 'heart outline icon'
                              }
                              onMouseEnter = {() =>this.enterFav(product.Item_Number)}
                              onMouseLeave = {() =>this.leaveFav(product.Item_Number)}
                              onClick={() =>
                                this.props.toggleFavorite(product.Item_Number)
                              }
                            />
                            <span className='fav_icon_hover_grid' >{this.state.show===true && this.state.productFav === product.Item_Number?'Favorites':''}</span>
                            </div>
                          )}
                          {!isScanListing  && (
                            <div className = 'compare-n-fav-compact'>
                              <span style={{ paddingRight: '0.3rem' }}>
                                Compare{' '}
                              </span>
                              <Checkbox
                                checked={product.selected}
                                onClick={() =>
                                  this.props.selectForCompare(
                                    product.Item_Number,
                                    !product.selected
                                  )
                                }
                              />
                            </div>
                          )}
                          </>                         
                        )}
                        </>
                      )}
                    </div>
                  )}
                  <div
                    onMouseLeave={() => {
                      this.delimg('delimg');
                    }}
                  >
                    <div className='card_img grid-image image' style = {{display: this.props.img_style ==='none'?'none':'', 'marginTop': '2rem'}} > 
                    {this.props.listType !== DIRECTSHIP ?
                      <Link
                       onClick={this.props.setBackButtonPath} 
                        to={{
                          pathname:
                            link +
                            (product.Item_Number
                              ? product.Item_Number
                              : product.item_id),
                          state: {
                            title: this.props.title,
                            listType: this.props.listType,
                            assortmentType: this.props.assortmentType,
                            muzak:product.muzak,
                            savingsOutOfWarehouse:product.savingsOutOfWarehouse,
                            promoHistory :product.promoHistory ,
                            futurePromo:product.futurePromo,
                            showAvailableField: !isShowAllWarehouse,
                            isReunionListing,
                            isHotDeals,
                            isMonthlyListing,
                            isWow:this.props.isWow,
                            MonthlySpecialSaving: product.Savings,
                            MonthlyEventCost: product.spc_cost,
                            MonthlyRegularCost: product.reg_cost,
                            MonthlyWHCost: product.Cost,
                            monthlyStock: product.stocked,
                            monthlyRelayNbr:product.RelayNbr,
                            monthlyPromoNbr:product.PromoNbr,
                            monthlyAssmentNbr:product.AsstmtID,
                            missing: this.props?.missing,
                            isCtbAssortment : this.props?.isCtbAssortment,
                            assortmentName:this.props?.assortmentName,
                            assortmentId:this.props?.assortmentId,
                            ctbseleDept:this.props.ctbseleDept
                          }
                        }}
                        className={
                          this.props.img_style === 'none'
                            ? 'disable_img_click'
                            : ''
                        }
                      >
                        {renderImages.getImage({
                          src: product.Image_File_Name
                            ? product.Image_File_Name
                            : 'https://images.truevalue.com/getimage.asp?id=' +
                              product.Item_Number +
                              '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                          style: { display: this.props.img_style},
                          alt: product.Image_File_Name,
                          className: 'ui small image image_lisitng_grid grid_image',
                          onMouseEnter: () => {
                            this.handleShow();
                            this.addimg(
                              'addimg',
                              product.Item_Number
                                ? product.Item_Number
                                : product.item_id
                            );
                          }
                        })}
                      </Link>
                      :
                      <div className={
                        this.props.img_style === 'none'
                          ? 'disable_img_click'
                          : ''
                      }>
                       {renderImages.getImage({
                          src: 'https://images.truevalue.com/getimage.asp?id=' +
                              product.Item_Number +
                              '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                          style: { display: this.props.img_style },
                          alt: 'product',
                          className: 'ui image image_lisitng',
                          onMouseEnter: () => {
                            this.addimg('addimg', product.Item_Number);
                          }
                        })}
                      </div>
                      }
                    </div>
                  </div>
                  </div>
                  <div className = 'header'>
                    {this.props.listType !== DIRECTSHIP &&
                      window.innerWidth >= 1024 && !this.props.isCtbAssortment &&(
                        <div
                        style = {{textAlign:'center'}}
                        className='view_button-compact-visible'
                        onClick={() => {
                          this.openModal(product.Item_Number);
                        }}
                      >
                        Quick View
                      </div>
                      )
                    }
                    <div className = 'grid_title'>
                  <div
                    style={{ marginBottom: '0'}}
                    className={ !isMonthlyListing ? 't-center vendor-span-compact vendor-span' : 't-center vendor-span-ms' }
                    id = 'vendor-width'
                  >
                    {this.props.listType !== DIRECTSHIP &&(
                    !isMonthlyListing ?
                    <Link
                     onClick={this.props.setBackButtonPath} 
                      to={{
                        pathname: product.Vendor_Name
                          ? '/vendor/' +
                            utilities.replaceAll(
                              product.Vendor_Name,
                              '/',
                              '%2F'
                            ) + '/' + (product.Vendor_Id || product.vendorID || product.vendor_id)
                          : '#',
                        state: { vendorId: product.Vendor_Id }
                      }}
                    >
                      {product.Vendor_Name}
                    </Link> : product.Vendor_Name )}
                  </div>
                  <div
                    className='t-center'
                    style={{ fontFamily: 'HelveticaNeueBold'}}
                  >
                    {this.props.listType !== DIRECTSHIP ?
                    <Link
                     onClick={this.props.setBackButtonPath} 
                      to={{
                        pathname: link + product.Item_Number,
                        state: {
                          title: this.props.title,
                          listType: this.props.listType,
                          showAvailableField: !isShowAllWarehouse,
                          assortmentType: this.props.assortmentType,
                          muzak:product.muzak,
                          savingsOutOfWarehouse:product.savingsOutOfWarehouse,
                          promoHistory :product.promoHistory ,
                          futurePromo:product.futurePromo,
                          isReunionListing,
                          isMonthlyListing,
                          isWow:this.props.isWow,
                          MonthlySpecialSaving: product.Savings,
                          MonthlyEventCost: product.spc_cost,
                          MonthlyRegularCost: product.reg_cost,
                          MonthlyWHCost: product.Cost,
                          monthlyStock: product.stocked,
                          monthlyRelayNbr:product.RelayNbr,
                          monthlyPromoNbr:product.PromoNbr,
                          monthlyAssmentNbr:product.AsstmtID,
                          missing: this.props?.missing,
                          isCtbAssortment : this.props?.isCtbAssortment,
                          assortmentName:this.props?.assortmentName,
                          assortmentId:this.props?.assortmentId,
                          ctbseleDept:this.props.ctbseleDept
                        }
                      }}
                    >
                      <b className={this.props.listType === DIRECTSHIP ? 'product_title-compact directShipTitle': 'product_title-compact'}>
                      {product.shortDesc || product.short_desc || product.Product_Name || product.Product_Title || product.shortdesc}
                      </b>
                    </Link>
                    :
                    <b className={this.props.listType === DIRECTSHIP ? 'product_title-compact directShipTitle': 'product_title-compact'} style={{'textDecoration':'none'}}>
                      {product.shortDesc || product.short_desc || product.Product_Name || product.Product_Title || product.shortdesc}
                    </b>
                     }
                  </div>
                  </div>
                { tableType !== MONTHLY_SPECIALS && 
                  <div className={tableType !== 'Planograms'?'restricted-product-grid t-center':'restricted-product-planogram t-center '} style = {{lineHeight: '32px', minHeight: '32px'}}>
                      {(product?.Restrictions?.length > 0) ? utilities.restrictedDetail(product?.Restrictions) : '    '}
                    </div>
            }
                  </div>
                  <div className = 'card_desciption content'>
                    <div className='card_fields gridbold'>
                      {!isDefaultListing ? (                        
                        <CustomTableGridView
                          viewPOG={this.props.multiStoreDetails.viewPOG}
                          PogMbr={this.props.multiStoreDetails.PogMbr}
                          CatMbr={this.props.multiStoreDetails.CatMbr}
                          setBackButtonPath={this.props.setBackButtonPath}
                          getprefer={
                            this.props.getprefer &&
                            this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                          }
                          index={index}
                          assortmentId={assortmentId}
                          handleQtyChange={this.props.handleChange}
                          handleQtyChangeblur={this.props.handleChangeblur}
                          product={product}
                          viewType={this.props.viewType}
                          tableType={tableType}
                          quantity={quantities[index]}
                          overrideRetail={overrideRetails[index]}
                          title={this.props.title}
                          listType={this.props.listType}
                          palletItem={this.props.palletItem}
                          isShowAllWarehouse={isShowAllWarehouse}
                          access = {this.props.multiStoreDetails.access}
                          multiStoreDetails={this.props.multiStoreDetails}
                          isCloseoutItems={this.props.isCloseoutItems}
                          RAMarketFlag={this.props.RAMarketFlag}
                          inputFocus= {this.props.inputFocus}
                          handleKeyDown = {this.handleKeyDown}
                        />
                      ) : (
                        <Table className = 'very padded unstackable table grid-table'>
                          <Table.Body>
                          <Table.Row>
                          <Table.Cell className = ''>
                            <span className='card_name First_level_details-grid-compact'>Item#:</span>
                          </Table.Cell>
                          <Table.Cell>
                            <b className='column First_level_details First_level_details-grid-compact'>
                              {product.Item_Number}
                            </b>
                          </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                          <Table.Cell className = ''>
                            <span className='card_name First_level_details-grid-compact'>Model#:</span>
                          </Table.Cell>
                          <Table.Cell>
                            <b className='First_level_details First_level_details-grid-compact'>
                              {product.Model}
                            </b>
                          </Table.Cell>   
                          </Table.Row>                       
                          { this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && (
                            <Table.Row>
                              <Table.Cell className = ''>
                                <span className='card_name First_level_details-grid-compact'>Alternate SKU#:</span>
                              </Table.Cell>
                              <Table.Cell>
                                <b className='First_level_details First_level_details-grid-compact'>
                                  {product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr }
                                </b>
                              </Table.Cell>
                            </Table.Row>
                          )}
                          {(!isShowAllWarehouse || (isShowAllWarehouse && tableType === HOTDEALS)) ? (
                            <Table.Row>
                              <Table.Cell className = ''>
                                <span className={'First_level_details-grid-compact'}>
                                  Available:
                                </span>
                              </Table.Cell>
                              <Table.Cell>
                                <b
                                  className={
                                    product.Available &&
                                    parseInt(product.Available) > 0
                                      ? 'First_level_details First_level_details-grid-compact'
                                      : 'First_level_details cost_red_color First_level_details-grid-compact'
                                  }
                                >
                                 {utilities.checkForHubAndSpokeIndictor(product)}
                                </b>
                              </Table.Cell>
                            </Table.Row>
                          ) : null}
                          <Table.Row>
                          <Table.Cell className = ''>
                            <span className='card_name First_level_details-grid-compact'>Pack:</span>
                          </Table.Cell>
                          <Table.Cell>
                            <b className='First_level_details First_level_details-grid-compact'>
                              {product.S_Pack_Type}
                              {product.S_Pack_Qty}
                            </b>
                          </Table.Cell>
                          </Table.Row>
                          {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr))  && (
                          displayMemberCostAndLbl && <Table.Row>
                              <Table.Cell className = ''>
                                <span className='card_name First_level_details-grid-compact'>{this.props.viewType===MEMBER_VIEW?'Member Cost:':utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")+':'}</span>
                              </Table.Cell>
                              <Table.Cell>
                                <b className='First_level_details First_level_details-grid-compact'>
                                {(this.props.multiStoreDetails.access !== 1  || (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr))?
                                  <MaskPrice
                                    getprefer={getPrefer}
                                    viewType={this.props.viewType}
                                    fieldVal={utilities.getCostForQtyPlpNew(
                                      product,
                                      quantities[index] || ''
                                    )}
                                    field='Member Cost'
                                  />
                                  :
                                  ""
                                    }
                                  {product?.Member_Cost_Tier && this.props.viewType === MEMBER_VIEW && (
                                    <TierIcon
                                      tierLevel={utilities.getCostForQtyPlpNew(
                                        product,
                                        quantities[index],
                                        true
                                      )}
                                      tierLetter={quantities[index] && parseInt(quantities[index]) > 0 ? true : false}
                                    />
                                  )}
                                </b>
                              </Table.Cell>
                            </Table.Row>
                          )}
                          <Table.Row>
                         {showSuggestedRetailPrice &&<> <Table.Cell className = ''>
                            <span className='card_name First_level_details-grid-compact'>{utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL",getPrefer)}:</span>
                          </Table.Cell>
                          <Table.Cell>
                            <b className='First_level_details First_level_details-grid-compact'>
                            {this.props.viewType===MEMBER_VIEW?"$"+utilities.toIntlNumberFormat(product.SuggestedRetail):showSRPMaskPrice?maskPrice(product.SuggestedRetail):"$"+utilities.toIntlNumberFormat(product.SuggestedRetail)}
                            </b>
                          </Table.Cell> </>
                          }
                          </Table.Row>
                          {product.maxQty ? (<Table.Row>
                              <Table.Cell className='First_level_details-grid-compact'>Max Qty:</Table.Cell>
                              <Table.Cell className='First_level_details-grid-compact'>{product.maxQty}</Table.Cell>
                            </Table.Row>): null}
                            {product.alternateItem?.Number && product.alternateItem?.Number > 0 ? (
                              <Table.Row>
                                <Table.Cell className='First_level_details-grid-compact'>{product.alternateItem?.Label}</Table.Cell>
                                <Table.Cell className='First_level_details-grid-compact'>
                                  <Link
                                     to={{
                                       pathname: '/product/' + product.alternateItem?.Number,
                                       state: {
                                         listType: this.props.listType
                                       }
                                     }}>{product.alternateItem?.Number}</Link></Table.Cell>
                              </Table.Row>
                            ): null}
                            {!utilities.isEmptyOrNullString(product?.whStockStatusMessage) ? (
                              <Table.Row>
                                 <Table.Cell className='First_level_details-grid-compact'>Stock Status:</Table.Cell>
                              <Table.Cell className='First_level_details-grid-compact'>{product.whStockStatusMessage}</Table.Cell>
                              </Table.Row>
                            ): null}
                          <Table.Row>
                            <Table.Cell className = ''>
                              <span className='card_name First_level_details-grid-compact override-label'>Override Retail:</span>
                            </Table.Cell>
                            
                            <Table.Cell>
                              <span>
                                <input
                                  type='text'
                                  id={'or-' + product.Item_Number}
                                  className='qty-box'
                                  data-item-number={index}
                                  data-field='or'
                                  maxLength={7}
                                  onChange={handleChange}
                                  value={overrideRetails[index] || ''}
                                />
                              </span>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell className = ''>
                              <span className='card_name First_level_details-grid-compact'>Quantity:</span>
                            </Table.Cell>
                            <Table.Cell>
                              <span>
                                <input
                                  className='qty-box'
                                  id={'qty-box' + product.Item_Number}
                                  type='text'
                                  data-item-number={index}
                                  data-field='qty'
                                  data-pack-type={product.S_Pack_Type}
                                  data-pack-qty={product.S_Pack_Qty}
                                  onChange={handleChange}
                                  onKeyDown = {(e)=>this.handleKeyDown(e, product, index)}
                                  value={quantities[index] || ''}
                                />
                              </span>
                            </Table.Cell>
                          </Table.Row>
                        {/* product.altNbr && !utilities.isEmptyOrNullString(product.altNbr) &&
                        <Table.Row>
                          <Table.Cell className = ''>
                                <span className='card_name First_level_details-grid-compact'>Rec. Alt Sku:</span>
                          </Table.Cell>
                          <Table.Cell>
                            <b className='First_level_details First_level_details-grid-compact'>
                              <u><Link to={'/product/' + product.altNbr}>{product.altNbr}</Link></u>
                            </b>
                        </Table.Cell>
                        </Table.Row>
                        */}
                        </Table.Body>
                        </Table>
                      )}
                    </div>
                  </div>
                  {this.props.listType !== DIRECTSHIP ? 
                  tableType !== 'competitorProducts' &&
                  (!isAssortmentListing ||
                  tableType === 'DOM' ||
                  (isImListing &&
                  (assortmentId === 'CLIP01' ||
                  assortmentId === 'CTOP01'))) &&
                  (
                    <div className = 'card_footer'>
                      {this.props.multiStoreDetails.access !== 1  && !isDiscontinuedHideAddToCart && (
                        <>
                        {msCheck && msCheck > 1 ?
                        !this.props.isReunionOnlyStore ?
                         <button
                         className='red ui button grid_button-compact'
                         onClick={() =>
                           this.multistoreHandleCarts(product, index)
                         }
                       >
                         Add to Store
                       </button>
                       :<>
                        <p class="disabledMessage">*Ordering restricted</p>
                        <button className='red ui button grid_button-compact' disabled>Add to Store</button>
                        </>
                       :!this.props.isReunionOnlyStore ?
                        <button
                          className='ui red button grid_button-compact'
                          id='button_grid'
                          onClick={() => this.handleAddToCart(product, index)}
                        >
                          Add to Cart
                        </button>
                        :<>
                          <p class="disabledMessage">*Ordering restricted</p>
                          <button className='ui red button grid_button-compact' disabled>Add to Cart</button>
                         </>
                        }
                        </>
                      )}
                    </div>
                ):
                <div className = 'card_footer'>
                  {
                    !this.props.isReunionOnlyStore ?
                    (product?.DSCost === 0 || (product?.Restrictions && product?.Restrictions.length > 0 && utilities.dsDealerProgramQuantity(product?.Restrictions))) ? '':
                    <button
                      className='ui red button grid_button-compact'
                      id='button_grid'
                      onClick={() => this.handleDirectShipAddToCart(product, index)}
                    >
                      Add to Cart
                    </button>
                    :<>
                      <p class="disabledMessage">*Ordering restricted</p>
                      <button className='ui red button grid_button-compact' disabled>Add to Cart</button>
                     </>
                  }
                  </div>
                }
                </div>
              </>
              );
            })}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const carts = state.cart.orders.allCarts;

  return {
    carts,
    currentCartType: state?.cart?.orders?.currentCart?.cartInfoName,
    currentCart: state?.cart?.orders?.currentCart,
    whCarts: carts && carts.filter(cart => cart.cartInfoName === 'REGULAR'),
    msCarts: carts && carts.filter(cart => cart.cartInfoName === 'MS'),
    dsCarts: carts && carts.filter(cart => cart.cartInfoName === 'DS'),
    getprefer: state.preference.listData,
    multiStoreDetails : state.SessionReducer.multiStoreDetails
  };
};

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        addProduct,
        createNAddCart,
        chooseCart
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListingGrid);

const CustomTableGridView = ({
  product,
  viewType,
  tableType,
  handleQtyChange,
  handleQtyChangeblur,
  index,
  quantity,
  overrideRetail,
  title,
  listType,
  isShowAllWarehouse,
  assortmentId,
  setBackButtonPath,
  getprefer,
  access,
  CatMbr,
  viewPOG,
  PogMbr,
  multiStoreDetails,
  RAMarketFlag,
  handleKeyDown,
}) => {
  const displayMemberCostAndLbl = shouldHideCostForPreference(viewType , getprefer);
  const dipslayOrigAndCurrCost = shouldHideOrigAndCurrCostForPreference(viewType ,tableType,getprefer);
  const showSuggestedRetailPrice  = shouldHideSUGG_RETAILForPreference(viewType,getprefer);
  const closeOutRegularCost = getCloseoutRegularCost(product)
  const closeOutMemberCost = getCloseoutCurrentCost(product)
  const memberCost = 
    <MaskPrice
      getprefer={getprefer}
      viewType={viewType}
      fieldVal={
        tableType === CLOSEOUTS || tableType === DISCONTINUED || tableType === 'NewItems' ? utilities.getCostForQtyPlpNew(product, quantity || '') : tableType === IMPULSE_MERCHANDISING
            ? utilities.getCostForQty(product, quantity || '', true)
            : tableType === HOTDEALS ? utilities.getCostForQtyPlpNew(product, quantity || '')
            : tableType === MONTHLY_SPECIALS
            ? utilities.getCostForQtyPlpNew(product.pricing , quantity[0] || '')
            : tableType === 'DOM'
            ? utilities.getCostForQtyHotDeals(product, quantity || '', true)
            : tableType === 'Planograms' 
            ? utilities.getCostForQtyPlpNew(product && product.pricing, quantity || '')
            : product.Member_Cost
      }
      field={(tableType === "DOM" || tableType===MONTHLY_SPECIALS)?"Member Cost black":'Member Cost'}
    />

   let price = BusinessRules.suggestedRetailPriceForPlp(product,product.variableRetail,viewType, multiStoreDetails);

   const srpCost =
     <MaskPrice
       getprefer={getprefer}
       viewType={viewType}
       fieldVal={price}
       field='Suggested Retail'
     />
  const costWithTier = product?.Member_Cost_Tier
    ? <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
      </>
    : memberCost;
  
  const hotDealsCostWithTier = product?.Member_Cost_Tier
    ? <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
      </>
    : memberCost;
  
  const costWithTierImpulse = product.tiered && product.tiered !== 'False'?
        <>
          {memberCost}
          <TierIcon tierLevel={utilities.getTierLevelForQty(product, quantity,tableType)} />
        </> : memberCost;

  const costWithTierMonthlySpecials = product && product.pricing && product.pricing?.Member_Cost_Tier
    ? <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product && product.pricing, quantity[0] || '',true)} tierLetter={quantity[0] && parseInt(quantity[0]) > 0 ? true : false}/>}
      </>
    : memberCost;
  
   const costWithTierHotDeals =(
      (product?.tiered && product?.tiered !== 'False') 
        || (product && product?.pricing && product?.pricing?.tiered)) ?
     <>
       {memberCost}
       <TierIcon tierLevel={utilities.getTierLevelForQtyHotDeals((product || (product && product.pricing)), quantity)} />
     </>
   : memberCost;

   const costWithTierPlanograms = product && product.pricing && product.pricing?.Member_Cost_Tier
    ?
      <>
        {memberCost}
        {viewType === MEMBER_VIEW && <TierIcon tierLevel={utilities.getCostForQtyPlpNew(product && product.pricing, quantity,true)} tierLetter={quantity && parseInt(quantity) > 0 ? true : false}/>}
      </>
    : memberCost;

    const regularcost = <MaskPrice
      viewType={viewType}
      getprefer={getprefer}
      fieldVal={product.Regular_Cost || product?.pricing?.Regular_Cost}
      field='Regular Cost'
    />
    const montlySpeialFuturecost = <MaskPrice
    viewType={viewType}
    getprefer={getprefer}
    fieldVal={product.future_cost}
    field='Regular Cost'
  />
    const regularCostTier = <>
      {regularcost}
      {viewType === MEMBER_VIEW && ( product.tiered || product?.pricing?.tiered ) && <TierIcon tierLevel={'2'} tierLetter={true}/>}
    </>
    const futureCostTier = <>
    {montlySpeialFuturecost}
    {viewType === MEMBER_VIEW && ( product.tiered || product?.pricing?.tiered ) && <TierIcon tierLevel={'2'} tierLetter={true}/>}
    </>
    const RetailMemCost =
                      RAMarketFlag==='Y' && product.Reunion_Cost && parseInt(product.Reunion_Cost) === 0 ? '-' :
                     <MaskPrice
                        getprefer={getprefer}
                        viewType={viewType}
                        fieldVal={RAMarketFlag==='Y' ? product.Reunion_Cost : product.pricing ? product.pricing.Cost : ''}
                        field={'Member Cost'}
                      />
    let price_evt = product && product.spc_cost
    const evtCost = <MaskPrice
                      getprefer={getprefer}
                      viewType={viewType}
                      fieldVal={price_evt}
                      field={'Member Cost'}
                    />
  const ds_cost = <MaskPrice
  getprefer={
    getprefer &&
    getprefer.customerView
      ? getprefer.customerView
      : 'c1'
  }
  viewType={viewType}
  fieldVal={product.DSCost}
  field='Member Cost'
/>

  let tbl_data = [];
  if (tableType === 'NewItems') {
    tbl_data = [
      ...((access!==1 || (access===1 && CatMbr)) 
        ?  [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(multiStoreDetails && utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              ? []
              : [
                {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
                ]),
            !isShowAllWarehouse && {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            { label: 'Date Added:', val: product.creation_date },
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':', val: utilities.emptyPriceCheck(costWithTier) },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'Quantity:',
              type: 'qty',
              is_text_box: true,
              val: quantity
            }
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(multiStoreDetails && utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
              ? []
              : [
                {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
                ]),
            !isShowAllWarehouse && {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            { label: 'Date Added:', val: product.creation_date },
            { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'Quantity:',
              type: 'qty',
              is_text_box: true,
              val: quantity
            },
          ])
    ];
  } else if (tableType === DISCONTINUED || tableType === CLOSEOUTS) {
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ? [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(multiStoreDetails && utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            ? []
            : [
              {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
              ]),
            !isShowAllWarehouse && {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'Disc. Date:',
              val: utilities.getDateFromTimeStamp(
                new Date(product.discontinued_date)
              )
            },
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':',  val:<>{utilities.emptyPriceCheck(costWithTier)}</>, },
            /*{
              label: product.sub_type_cd ==='3' || (product.substistuteorreferal && utilities.checkRef(product.substistuteorreferal))? 'Referral Item:':'Substitute:' ,
	                   val:product.sub_type_cd? utilities.hyphenForZero(product.sub_type_cd) ==='3'? utilities.hyphenForNullsub(product.substitute_item) : utilities.hyphenForNullref(product.substitute_item)
                   :product.substistuteorreferal? product.substistuteorreferal: utilities.hyphenForNullsub(product.substistuteorreferal)
            },*/
            dipslayOrigAndCurrCost && {
              label: utilities.getLabelsByType(viewType,"REGULAR_COST")+':',
              val: utilities.emptyPriceCheck(regularCostTier),
            },
            /*dipslayOrigAndCurrCost&&{ label: utilities.getLabelsByType(viewType,"CURR_COST")+':',
                                      val: tableType === CLOSEOUTS ?  <>{utilities.getCostBasedOnnatvalue(viewType,closeOutCurrentCost[0],getprefer)}  {closeOutCurrentCost[1]&& (<span className="tier_icon">{closeOutCurrentCost[1]}</span>)}</> : memberCost},*/
            ( viewType === MEMBER_VIEW ? 
              { label: '% Off:',
                val: utilities.hyphenForZero(product.savings && Number(product.savings) > 0 ? `${Number(product.savings).toFixed(2)}`: '-')
              } : {}
            ),
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'Quantity:',
              type: 'qty',
              is_text_box: true,
              val: quantity
            }
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(multiStoreDetails && utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            ? []
            : [
              {label: 'Alternate SKU#:', val: product.Xref_ItemNbr !== '~' && product.Xref_ItemNbr}
              ]),
            !isShowAllWarehouse && {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'Disc. Date:',
              val: utilities.getDateFromTimeStamp(
                new Date(product.discontinued_date)
              )
            },
            {
              label: 'Substitute:',
              val: utilities.hyphenForZero(
                utilities.hyphenForNullsub(product.substitute_item)
              )
            },
            ( viewType === MEMBER_VIEW ? 
              { label: '% Off:',
                val: utilities.hyphenForZero(product.savings && Number(product.savings) > 0 ? `${Number(product.savings).toFixed(2)}`: '-')
              } : {}
            ),
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'Quantity:',
              type: 'qty',
              is_text_box: true,
              val: quantity
            },
          ])
    ];
  } else if (tableType === DIRECTSHIP) {
    tbl_data = [

      { label: 'Item#:', val: product?.IsStocked ? product.Item_Number: dsNonStockedItem(product.Item_Number) },
      { label: 'Model#:', val: product.Model },
      { label: 'UPC:', val: (product.UpcNum !== '0' && !_.isEmpty(product.UpcNum)) ? product.UpcNum: '-' },  
        displayMemberCostAndLbl && (access !== 1|| (access === 1 && CatMbr)) &&
      {
         label: utilities.getLabelsByType(viewType,"DS_COST", getprefer)+':',
         val: product.DSCost
           ? ds_cost
           : '-'
      },
      {
        label: 'Pack:',
        val: `${product.s_pack_qty ? product.s_pack_qty : ''
        }`
      },
        showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val:  <MaskPrice
          viewType={viewType}
          getprefer={getprefer}
          fieldVal={product.Srp_Cost || '0'}
          field = {"Suggested Retail"}
      /> },
                      
      (product?.DSCost === 0 || product?.Restrictions && product?.Restrictions.length > 0 && utilities.dsDealerProgramQuantity(product?.Restrictions) ? {} :
      {
        label: 'Quantity:',
        val: quantity,
        type: 'qty',
        is_text_box: true
      })
      
    ]
  } else if (tableType === HOTDEALS) {
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ? [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(multiStoreDetails && utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            ? []
            : [
              {label: 'Alternate SKU#:', val: product.aliasSku}
              ]),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.available && parseInt(product.available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Disc. Date:',
              val: product.tbdDate
              ? utilities.getDateFromTimeStamp(
                  new Date(product.tbdDate)
                )
              : '-'
            },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':',  val:<>{utilities.emptyPriceCheck(hotDealsCostWithTier)}</>, },
            dipslayOrigAndCurrCost && {
              label: utilities.getLabelsByType(viewType,"REGULAR_COST")+':',
              val: utilities.emptyPriceCheck(regularCostTier),
            },
            {
              label: '% Off:',
              val: utilities.hyphenForZero(product.Savings && Number(product.Savings) > 0 ? `${Number(product.Savings).toFixed(2)}`: '-')
            },
            {
              label: 'Pack:',
              val: `${product.packType ? product.packType : ''}${
                product.packQty ? product.packQty : ''
              }`
            },
            {
              label: 'Quantity:',
              type: 'qty',
              is_text_box: true,
              val: quantity
            }
          ]
          :
          [
            { label: 'Item:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(multiStoreDetails && utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            ? []
            : [
              {label: 'Alternate SKU#:', val: product.aliasSku}
              ]),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.available && parseInt(product.available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Disc. Date:',
              val: utilities.getDateFromTimeStamp(
                new Date(product.tbdDate)
              )
            },
            {
              label: 'Substitute:',
              val: utilities.hyphenForZero(
                utilities.hyphenForNullsub(product.subitemNum)
              )
            },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: '% Off:',
              val: utilities.hyphenForZero(product.Savings && Number(product.Savings) > 0 ? `${Number(product.Savings).toFixed(2)}`: '-')
            },
            {
              label: 'Pack:',
              val: `${product.packType ? product.packType : ''}${
                product.packQty ? product.packQty : ''
              }`
            },
            {
              label: 'Quantity:',
              type: 'qty',
              is_text_box: true,
              val: quantity
            },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
          ])
    ];
  } else if (tableType === 'Planograms') {
    tbl_data = [
      ...((access !== 1 || PogMbr)
        ? [
            { label: 'Item#:', val: product.Item_Number },
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: costWithTierPlanograms },
            { label: 'Model#:', val: product.Model },
            multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
            { label: 'Suggested Qty:', val: product.recQty },
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Order Qty:',
              val: quantity,
              type: 'qty',
              is_text_box: true
            },
            { label: 'UPC:', val: product.UpcNum },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Order Qty:',
              val: quantity,
              type: 'qty',
              is_text_box: true
            },
            { label: 'UPC:', val: product.UpcNum },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
          ])
    ];
  } else if (tableType === 'RetailAssortments') {
    tbl_data = [
      ...((access !== 1 || (access === 1 && PogMbr))
        ? [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.model },
            multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: RetailMemCost },
          showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            {label: 'UPC:', val: product.UpcNum},
            { label: 'Quantity:', val: utilities.checkForHubAndSpokeIndictor(product) },
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.model },
            multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
            { label: 'Quantity:', val: utilities.checkForHubAndSpokeIndictor(product) },
            { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
          ])
    ];
  } else if (tableType === 'DOM') {
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ? [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(!utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
            ? [{label: 'Alternate SKU#:', val: product.aliasSku}] : [] ),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: costWithTierHotDeals },
            {
              label: 'Promo Retail:',
              val:
                !utilities.isEmptyOrNullString(product.promo_retail) ? '$' + Number(product.promo_retail).toFixed(2) : '-'
            },
            {
              label: 'Promo Cost:',
              val: !utilities.isEmptyOrNullString(product.spc_cost) ? '$' + Number(product.spc_cost).toFixed(2) : '-',
              valClass:'First_level_details_red',
              classname: 'First_level_details-grid-compact'
            },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'Quantity:',
              val: quantity,
              type: 'qty',
              is_text_box: true
            },
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(!utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
            ? [{label: 'Alternate SKU#:', val: product.aliasSku}] : [] ),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'Quantity:',
              val: quantity,
              type: 'qty',
              is_text_box: true
            },
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ])
    ];
  } else if (tableType === IMPULSE_MERCHANDISING) {
    tbl_data = [
      ...((access !== 1 || (viewPOG && PogMbr))
        ? [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            displayMemberCostAndLbl && { label: utilities.getLabelsByType(viewType,"MEMBER_COST") + ':', val: costWithTierImpulse },
            showSuggestedRetailPrice &&  { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
            {
              label: 'Gross Margin:',
              val: computeGrossMargin(product.Srp_Cost, product.Member_Cost)
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            { label: 'Suggested Qty:', val: product.recQty },
            { label: 'Quantity:', val: quantity, is_text_box: true, type: 'qty',},
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            multiStoreDetails && !utilities.isEmptyOrNullString(multiStoreDetails.aliasSKURetailerGrpID)
            && ({label: 'Alternate SKU#:', val: product.aliasSku}),
            {
              label: 'Available:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            { label: 'Suggested Qty:', val: product.recQty },
            { label: 'Quantity:', val: quantity, is_text_box: true },
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer) + ':', val: srpCost },
          ])
    ];
  } else if (tableType === 'Reunion') {
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ? [
            { label: 'ITEM#:', val: product.Item_Number },
            { label: 'MODEL#:', val: product.Model },
            { label: 'UPC :', val: product.UpcNum },
            {
              label: 'WH COST :',
              val:
                (product.future_cost && parseInt(product.future_cost)) !== 0
                  ? '$' + product.future_cost
                  : '$' + product.Cost
            },
            {
              label: 'EVT COST:',
              val: utilities.hyphenForNull('$' + product.spc_cost)
            },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost },
            {
              label: 'OVERRIDE RETAIL:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'SAVINGS:',
              val: utilities.hyphenForNull(product.Savings) + ' %'
            },
            {
              label: 'PACK:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'NON-STOCKED:',
              val: !product.stocked || product.stocked !== '1' ? 'Y' : 'N'
            },
            { label: 'SHIP WEEK:', val: 'QUANTITY' },
            ...(utilities.isEmptyOrNullString(product.shipdate1)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate1),
                    type: 'qty1',
                    val: quantity[0],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipdate2)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate2),
                    type: 'qty2',
                    val: quantity[1],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipdate3)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate3),
                    type: 'qty3',
                    val: quantity[2],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipdate4)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate4),
                    type: 'qty4',
                    val: quantity[3],
                    is_text_box: true
                  }
                ]),
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ]
          :
          [
            { label: 'ITEM#:', val: product.Item_Number },
            { label: 'MODEL#:', val: product.Model },
            { label: 'UPC :', val: product.UpcNum },
            {
              label: 'SAVINGS:',
              val: utilities.hyphenForNull(product.Savings) + ' %'
            },
            {
              label: 'PACK:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'NON-STOCKED:',
              val: !product.stocked || product.stocked !== '1' ? 'Y' : 'N'
            },
            { label: 'SHIP WEEK:', val: 'QUANTITY' },
            ...(utilities.isEmptyOrNullString(product.shipdate1)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate1),
                    type: 'qty1',
                    val: quantity[0],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipdate2)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate2),
                    type: 'qty2',
                    val: quantity[1],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipdate3)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate3),
                    type: 'qty3',
                    val: quantity[2],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipdate4)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipdate4),
                    type: 'qty4',
                    val: quantity[3],
                    is_text_box: true
                  }
                ]),
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ])
    ];
  }else if (tableType === MONTHLY_SPECIALS) {
    const savingsValue = product.Savings ? product.Savings.split('%')[0] : 0;
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ? [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(!utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
            ? [{label: 'Alternate SKU#:', val: product.aliasSku}] : [] ),
            { label: 'UPC:', val: product.UpcNum },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'Non-Stocked:',
              val: product.stocked
            },
            displayMemberCostAndLbl && {
              label: `${utilities.getLabelsByType(viewType,"REGULAR_COST")}:`,
              val : product.future_cost && product.future_cost > 0 ? utilities.emptyPriceCheck(futureCostTier) : quantity[0] > 0 ? costWithTierMonthlySpecials : utilities.emptyPriceCheck(regularCostTier)
            },
            displayMemberCostAndLbl && {
              label: `${utilities.getLabelsByType(viewType,"EVENT_COST")}:`,
              val: evtCost,
              valClass:'First_level_details_red'
            },
            viewType === MEMBER_VIEW && {
              label: 'Savings:',
              val: utilities.hyphenForZero(savingsValue && Number(savingsValue) > 0 ? `${Number(savingsValue).toFixed(2)}%`: '-'),
              valClass:'First_level_details_red'
            },    
            {
              label: 'Override Retail:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
                    
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            { label: 'Ship Week:', val: 'QUANTITY' },
            ...(utilities.isEmptyOrNullString(product.shipDate1)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate1),
                    type: 'qty1',
                    val: quantity[0],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipDate2)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate2),
                    type: 'qty2',
                    val: quantity[1],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipDate3)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate3),
                    type: 'qty3',
                    val: quantity[2],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipDate4)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate4),
                    type: 'qty4',
                    val: quantity[3],
                    is_text_box: true
                  }
                ]),               
            {
              label : (product?.Restrictions?.length > 0)
              ? utilities.restrictedDetail(product?.Restrictions)
              : '',
              labelClass : 'restricted-product',
              is_one_col : true
            }
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ]
          :
          [
            { label: 'Item#:', val: product.Item_Number },
            { label: 'Model#:', val: product.Model },
            ...(!utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
            ? [{label: 'Alternate SKU#:', val: product.aliasSku}] : [] ),
            { label: 'UPC :', val: product.UpcNum },
            {
              label: 'Savings:',
              val: utilities.hyphenForNull(product.Savings),
               valClass:'First_level_details_red'
            },
            {
              label: 'Pack:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            {
              label: 'Non-Stocked:',
              val: product.stocked
            },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            { label: 'Ship Week:', val: 'QUANTITY' },
            ...(utilities.isEmptyOrNullString(product.shipDate1)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate1),
                    type: 'qty1',
                    val: quantity[0],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipDate2)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate2),
                    type: 'qty2',
                    val: quantity[1],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipDate3)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate3),
                    type: 'qty3',
                    val: quantity[2],
                    is_text_box: true
                  }
                ]),
            ...(utilities.isEmptyOrNullString(product.shipDate4)
              ? []
              : [
                  {
                    label: utilities.getDateFromTimeStamp(product.shipDate4),
                    type: 'qty4',
                    val: quantity[3],
                    is_text_box: true
                  }
                ]),
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ])
    ];
  } else if (tableType === 'tvProductScan') {
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ? [
            { label: 'ITEM#:', val: product.Item_Number },
            { label: 'MODEL#:', val: product.Model },
            {
              label: 'AVAILABLE:',
              val: utilities.checkForHubAndSpokeIndictor(product),    
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            displayMemberCostAndLbl &&  { label: utilities.getLabelsByType(viewType,"MEMBER_COST")+':', val: memberCost },
            showSuggestedRetailPrice && { label:  utilities.getLabelsByType(viewType,"SUGG_RETAIL",getprefer)+':', val: srpCost },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'OVERRIDE RETAIL:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'PACK:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            { label: 'QUANTITY:', val: quantity, is_text_box: true },
            {
              label: 'RESTRICTED:',
              val: product.Restriced ? 'Restricted Product' : '',
              className: 't-center restricted-product'
            }
          ]
          :
          [
            { label: 'ITEM#:', val: product.Item_Number },
            { label: 'MODEL#:', val: product.Model },
            {
              label: 'AVAILABLE:',
              val: utilities.checkForHubAndSpokeIndictor(product),
              valClass: product.Available && parseInt(product.Available) > 0
              ? 'First_level_details-grid-compact'
              : 'First_level_details-grid-compact cost_red_color',
              classname: 'First_level_details-grid-compact'
            },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
            {
              label: 'OVERRIDE RETAIL:',
              val: overrideRetail,
              type: 'or',
              is_text_box: true
            },
            {
              label: 'PACK:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            { label: 'QUANTITY:', val: quantity, is_text_box: true },
            // {
            //   label: 'RESTRICTED',
            //   val: product.Restriced ? 'Restricted Product' : '',
            //   className: 't-center restricted-product'
            // }
          ])
    ];
  } else if (tableType === 'competitorProducts') {
    tbl_data = [
      ...((access !== 1 || (access === 1 && CatMbr)) 
        ?[
            { label: 'COMPETITOR NAME:', val: product.competitor_name },
            { label: 'ITEM#:', val: product.item_id },
            {
              label: 'MODEL#:',
              val: utilities.hyphenForNull(product.model_number)
            },
            {
              label: 'NAME:',
              val: product.item_name
            },
            { label: 'SALE PRICE:', val: product.sale_price },
            {
              label: 'PACK:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
          ]
          :
          [
            { label: 'COMPETITOR NAME:', val: product.competitor_name },
            { label: 'ITEM#:', val: product.item_id },
            {
              label: 'MODEL#:',
              val: utilities.hyphenForNull(product.model_number)
            },
            {
              label: 'NAME:',
              val: product.item_name
            },
            {
              label: 'PACK:',
              val: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                product.S_Pack_Qty ? product.S_Pack_Qty : ''
              }`
            },
            ...(product.maxQty > 0 ? [{label: 'Max Qty:', val: product.maxQty, classname:'First_level_details-grid-compact'}]: []),
            ...(!utilities.isEmptyOrNullString(product?.whStockStatusMessage)  ? [{label: 'Stock Status:', val: product?.whStockStatusMessage, classname:'First_level_details-grid-compact'}]: []),
            ...(product.alternateItem?.Number && product.alternateItem?.Number > 0 ? [{label: product.alternateItem?.Label, val: product.alternateItem?.Number}]:[]),
          ])
    ];
  }
  return (
    <>
    <Table className = 'very padded unstackable table grid-table'>
      <Table.Body>
      {tbl_data.map(item => {
        const className = item.classname
          ? item.classname
          : 'First_level_details-grid-compact';
        return (
          <>
          {
             item && <Table.Row>
              <Table.Cell  colspan ={ item.is_one_col ? '2' : '' }>
                {
                  item.type === 'or'? <span
                  className={item.labelClass ? item.labelClass + className + 'override-label': `card_name ${className} override-label`}
                >
                  {item.label}
                </span>: <span
                  className={item.labelClass ? item.labelClass + className : `card_name ${className}`}
                >
                  {item.label}
                </span>
                }
              </Table.Cell>
                {item.is_one_col ? '' :item.is_text_box ? (
                  item.type === 'qty' ? (
                    
                <Table.Cell>
                      <span>
                      {tableType === 'Planograms' || (tableType === 'ImpulseMerchandising' &&
                              !(assortmentId === 'CLIP01' || assortmentId === 'CTOP01'))
                        ?
                        <input
                          className='qty-box grid-input'
                          id={'qty-box' + product.Item_Number}
                          data-item-number={index}
                          data-field='qty'
                          data-pack-type={product.S_Pack_Type}
                          data-pack-qty={product.S_Pack_Qty}
                          data-recQty={product.recQty}
                          onChange={handleQtyChange}
                          onBlur={handleQtyChangeblur}
                          value={item.val ? item.val : ''}
                          onKeyDown = {(e)=>handleKeyDown(e, product, index)}
                          type='text'
                        />
                        :
                        <input
                        className='qty-box grid-input'
                        id={'qty-box' + product.Item_Number}
                        data-item-number={index}
                        data-field='qty'
                        data-pack-type={product.S_Pack_Type}
                        data-pack-qty={product.S_Pack_Qty}
                        onChange={handleQtyChange}
                        onKeyDown = {(e)=>handleKeyDown(e, product, index)}
                        value={item.val ? item.val : ''}
                        type='text'
                      />
                        }
                      </span>
                      </Table.Cell>
                    
                  ) : item.type === 'or' ? (
                    <Table.Cell>
                      <span>
                        <input
                          className='qty-box grid-input'
                          value={item.val ? item.val : ''}
                          data-item-number={index}
                          data-field='or'
                          onChange={handleQtyChange}
                        />
                      </span>
                      </Table.Cell>
                  ) : (
                    <Table.Cell>

                      <span>
                        <input
                          className='qty-box grid-input'
                          id={ItemMeta.type + product.Item_Number}
                          data-item-number={index}
                          data-field={item.type}
                          data-pack-type={product.S_Pack_Type}
                          data-pack-qty={product.S_Pack_Qty}
                          onChange={handleQtyChange}
                          value={item.val ? item.val : ''}
                          type='text'
                        />
                      </span>
                      </Table.Cell>

                  )
                ) : (
                  <Table.Cell>
                    <b className={item.valClass ? className + ' ' + item.valClass : className}>
                      {item.is_number ? (
                        utilities.changePriceToNumber(item.val)
                      ) : (item.label === 'Substitute:' || item.label === "Referral Item:" || item.label ==='Alternative Item:' )  && item.val &&
                      item.val > 0  ? (
                        <Link
                        onClick={setBackButtonPath} 
                          to={{
                            pathname: '/product/' + item.val,
                            state: {
                              title: title,
                              listType: listType
                            }
                          }}
                        >
                          {item.val}
                        </Link>
                      ) :
                      (
                        item.val
                      )}
                    </b>
                    </Table.Cell>
                )}
              </Table.Row>
      }
         </>
        );
      })}
      <Table.Row>
        <Table.Cell colspan='2'>
        <div className='indicators-div' style ={{'marginTop': '2rem'}}>
            <ProductIndicators product={product} isPLP='PLP'  />
        </div>  
        </Table.Cell>
      </Table.Row>
      </Table.Body>
      </Table>
      <div id='snackbar'>Product added to cart</div>
      </>
  );
};