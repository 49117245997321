//table types for PLP
export const CLOSEOUTS = 'CloseoutItems';
export const DIRECTSHIP = 'DirectShipItems';
export const IMPULSE_MERCHANDISING = 'ImpulseMerchandising';
export const HOTDEALS = 'HotDeals';
export const DISCONTINUED = 'Discontinued';
export const RETAIL_ASSORTMENTS = 'RetailAssortments';
export const CTB_ASSORTMENTS = 'Store_POG_CTB_Assortments';
export const REUNION_RETAIL_ASSORTMENTS = 'Reunion Retail Assortments';
export const PLANOGRAMS = 'Planograms';
export const PLANOGRAM_ITEMS = 'Planogram';
export const MONTHLY_SPECIALS = 'Monthly Specials';
export const DISCOUNT_CITY = 'Discount City';
export const WOW = 'Wow';
export const FLASH_SALES = 'FlashSales';
export const TV_PRODUCT_SCAN = 'tvProductScan';
export const COMPETITOR_PRODUCTS = 'competitorProducts';
export const HIDE_QUICKVIEW_PAGE = [IMPULSE_MERCHANDISING, TV_PRODUCT_SCAN, COMPETITOR_PRODUCTS];
