import 'whatwg-fetch';
import {VENDOR_URL,directShipVendorList, VENDOR_DETAILS_URL } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
import utilities from '../../utils/utilities';

export const REQUEST_VENDORS = 'REQUEST_VENDORS';
export const RECEIVE_VENDORS = 'RECEIVE_VENDORS';
export const RECEIVE_VENDOR = 'RECEIVE_VENDOR';

export const requestVendors = () => ({
  type: REQUEST_VENDORS
});

export const receiveVendor = vendorDetails => ({
  type: RECEIVE_VENDOR,
  vendorDetails
});

export const receiveVendors = (vendors, key, orderType) => ({
  type: RECEIVE_VENDORS,
  vendors,
  key,
  orderType
});

export const fetchVendors = (orderType, searchKey) => (dispatch,getState) => {
  dispatch(requestVendors());
 //if (!searchKey) return;
  let url = `${VENDOR_URL}${searchKey}`;
  const searchKeyId = searchKey !== ''? searchKey[0].toUpperCase(): '';
  const letterNumber = /^[0-9]+$/;
  const searchKeyValue  = searchKey !== ''  ? searchKeyId.match(letterNumber) ? "0-9" : searchKey.split('')[0].toUpperCase():'';
  let grpId = getState().SessionReducer.address ? getState().SessionReducer.address.urlId : '';
  if(grpId){
    url=url+'&GroupURLId='+grpId
  }
  if(orderType === 2 || orderType === 3) {
    url = `${directShipVendorList}${searchKey.toLowerCase()}`    
  }
  /*if(orderType === 2) {
    url = url+'&vendorType=DS-VD'
  }else if(orderType === 3) {
    url = url+'&vendorType=DS-CD'
  }*/
  utilities.setLoader(true)
  return restUtils
    .getData(url)
    .then(response => {
      if(response && response.data){
        dispatch(receiveVendors(response.data,searchKeyValue, orderType));
        utilities.setLoader(false)
      }
    }).catch(() => {
      dispatch(receiveVendors([],searchKeyValue, orderType));
      utilities.setLoader(false)
    });
};

export const fetchVendorById = vendorId => dispatch => {
  if (vendorId) {
    const url = `${VENDOR_DETAILS_URL}?vendorId=${vendorId}`;
    return restUtils
      .getData(url)
      .then(response =>{
        if(response && response.data){
          dispatch(receiveVendor(response.data));
        }
      })
      .catch(() => {
        dispatch(receiveVendor([]));
      });
  }
  return;
};
