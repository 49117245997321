import React, { Component } from 'react';
import { utilities } from '../../utils/utilities';
import { filters, filterValues } from './constants';
import StickyBox from "react-sticky-box";
import './filters.css';
import _ from 'lodash';
class SubCategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {filterTopOffset: 0};
  }
  componentDidMount = () => {
    this.getFilterTop();
  }

  getFilterTop = () => {
    let filterTopOffset = document.getElementById("subCategoryListFilter").getBoundingClientRect().top; 
    this.setState({
        filterTopOffset
    })
  }

  clearAllFilter = () => {
    let storedUrl = window.location.href.split('?');
    window.location.href = storedUrl[0];
  }

  handleFilterAction = e => {
    if (e.target.checked) {
      document.getElementById('fh-' + e.target.name).style.backgroundColor = '#168c87';
    } else {
      let filterCBs = document
        .getElementById('fs-' + e.target.name)
        .querySelectorAll('.filter-checkbox');
      if (!filterCBs) return;
      let filterApplied = false;
      filterCBs.forEach(cb => {
        if (cb.checked) {
          filterApplied = true;
          return;
        }
      });
      if (!filterApplied) {
        document.getElementById('fh-' + e.target.name).style.backgroundColor = '#606060';
        Object.keys(filters).forEach(function(key) {
        if(filters[key].requestString == e.target.name)
          document.getElementById('fh-' + e.target.name).style.backgroundColor = '#333333';
        })
      }
    }
    this.props.callback(e.target.checked, e.target.name, encodeURIComponent(e.target.value));
  };
  componentWillReceiveProps(newProps) {
    if (newProps.clearFilters) {
      document.querySelectorAll('.filter-checkbox').forEach(cb => {
        cb.checked = false;
      });
      /*Set Header color for all filters as grey. If the filter is not an attribute level filter
        set color to black.*/
      document.querySelectorAll('.filter-head').forEach(fh => {
        fh.style.backgroundColor = '#606060';
        Object.keys(filters).forEach(function(key) {
        if('fh-' + filters[key].requestString == fh.id)
          fh.style.backgroundColor = '#000000cc';
        })
      });
      document.querySelectorAll('.filter-header').forEach(fb => {
          fb.style.backgroundColor = '#000000cc';
      });
      this.collapseAll();
    }    
  }
  collapseAll = () => {
    const icons =  document.querySelectorAll('.toggleIcon')
    icons.forEach(icon => {
      icon.className = 'plus icon toggleIcon';
    });

    const contentDivs = document.querySelectorAll('.content-Div');
    contentDivs.forEach(contentDiv => (contentDiv.style.display = 'none'));
  };
  toggleDiv = index => {
    const icon = document.querySelectorAll('.toggleIcon')[index];
    icon.className = icon.classList.contains('minus')
      ? 'plus icon toggleIcon'
      : 'minus icon toggleIcon';

    const contentDiv = document.getElementById('contents-' + index);
    contentDiv.style.display =
      contentDiv.style.display === 'none' ? 'block' : 'none';
  };
  render() {
    const orderedCategories = _.orderBy(this.props.filters, ['sequence'], ['asc']);
    //Filtering the categories to remove the core items and Greener Options filters.
    const categories = _.filter(orderedCategories, function(category) { if(category.name == "coreItems" || category.name == "Greener") return false; else return true;});
    let filterColor = this.props.filtersApplied ? '#168c87' : '#ffffff';
    let shrunkFilterColor = this.props.filtersApplied ? '#168c87' : '#0c0c0c';
    let toggleFilter =
      this.props.isNoProducts && this.props.showFilters
        ? this.props.filtersApplied
          ? { display: 'block' }
          : { display: 'none' }
        : this.props.isNoProducts
          ? this.props.filtersApplied
          ? { display: 'block' } 
          : { display: 'none' }
        : { display: 'block' };
    let filterConstant = Object.keys(filters);
    return (
    <>
      <StickyBox offsetTop={this.state.filterTopOffset} >
        <div style={toggleFilter} id= "subCategoryListFilter">
          <i
            className='filter icon'
            style={
              this.props.showFilters
                ? { display: 'none', color: shrunkFilterColor }
                : { display: 'block', color: shrunkFilterColor }
            }
            onClick={this.props.toggleShowFilter}>
            <span className='expand-arrow'>»</span>
          </i>
          <div className='filters'
            style={
              this.props.showFilters
                ? { display: 'block', color: filterColor }
                : { display: 'none', color: filterColor }
            }>
            <div className='filter-disclaimer' style = {{paddingLeft: '1rem',fontSize: '0.93rem'}}>
              *Filters do not change item counts.
            </div>
            <div className='filter-head filter-header'>
              <span>
              REFINE/FILTER
              </span>
              <i className='filter icon' style={{ verticalAlign: 'top' }} onClick={this.clearAllFilter}></i>
              <span className = 'filter-hide'
                style={{
                  fontSize: '1rem',
                  color: filterColor
                }}
                onClick={this.props.toggleShowFilter}>
                <span className='shrink-arrow'> <>&lt;&lt;</> hide</span> 
              </span>
            </div>
            {categories &&
              categories.map((category, index) => (
                
                <div
                  className='filter-section-compact'
                  key={category.name}                  
                  id={
                    filters[category.name]
                      ? 'fs-' + filters[category.name].requestString
                      : 'fs-' + category.name
                  }>
                  <div
                    className='filter-head'
                    style={filterConstant.includes(category.name)?
                        {fontFamily: 'HelveticaNeue', backgroundColor: '#000000cc',display:'grid',gridTemplateColumns: '5fr 1fr'}:{fontFamily: 'HelveticaNeue', backgroundColor: '#606060',display:'grid',gridTemplateColumns: '5fr 1fr'}}
                    id={
                      filters[category.name]
                        ? 'fh-' + filters[category.name].requestString
                        : 'fh-' + category.name
                    }>
                    <div>
                      {filters[category.name]
                        ? filters[category.name].displayName
                        : category.name}
                    </div>
                    <div>
                      <i
                        className='plus icon toggleIcon'
                        onClick={() => this.toggleDiv(index)}>
                      </i>
                    </div>
                  </div>
                  <div
                    className='content-Div'
                    id={'contents-' + index}
                    style={{ display: 'none' }}>
                    {category.attributeValueList &&
                      category.attributeValueList.map(item => {
                        let label = filterValues[item.attributeValue]
                          ? item.attributeValue === 'MadeInUSAVal' ? filterValues[item.attributeValue].displayName :  item.attributeValue === 'level1' ? filterValues[item.attributeValue].displayName :
                            utilities.titleCase(filterValues[item.attributeValue].displayName)
                          : utilities.titleCase(item.attributeValue);
                        label = label + ' (' + item.productCount + ')';

                        return (
                          <div
                            className='ui checkbox'
                            style={{
                              display: 'block',
                              padding: '0.255rem 0.25rem'
                            }}
                            key={item.attributeValue}>
                            <input
                              type='checkbox'
                              className='filter-checkbox'
                              key={item.attributeValue}
                              name={
                                filters[category.name]
                                  ? filters[category.name].requestString
                                  : category.name
                              }
                              onChange={this.handleFilterAction}                              
                              value={
                                filterValues[item.attributeValue]
                                  ? filterValues[item.attributeValue].value
                                  : item.attributeValue.replace(/&/g, '%26')
                              }
                              style={{
                                padding: '0.3rem 0.5em',
                                fontSize: '1rem'
                              }}
                            />
                            <label>{label}</label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </StickyBox>
    </>
    );
  }
}

export default SubCategoryList;