import { getEventRedirectUrl } from "./eventRedirectUtilities";
import utilities from "../../../utils/utilities";
import React from "react";
import { Link } from "react-router-dom";
import renderImages from "../../common/RenderImages";
import {
  MARKET_ADD_TO_CART,
  getRedirectUri,
  getPalletImage,
  getImageURl,
} from "../../../urlConstants";
import {
  quantityValidator,
  validateProductQuantity,
} from "../../common/productHandler";
import restUtils from "../../../utils/restUtils";
import { assortmentLevels } from "../../common/constants";
import warning from "../../images/warning.png";
import usFlag from "../../images/US-Flag-icon.png";
import _ from "lodash";
import { DealsDialog } from "../MonthlySpecials/DealsDialog";
import MVP_Assortment_items from '../../images/TV_Icons_MVP-Assortment-Items.png';
import Assortment_items from '../../images/TV_Icons_assortment_item.png';
import { Icon, Popup } from "semantic-ui-react";

export const getSearchByOptions = (flowType, isAliasSkuRequired = false) => {
  switch (flowType) {
    case "1":
    case "4":
      return [
        {
          props: {
            value: "item",
            id: "item",
            className: "SelectOption",
            pattern: "[0-9]*",
          },
          text: "Item #",
          pattern: "[0-9]*",
        },
        {
          props: {
            value: "model",
            id: "models",
            className: "SelectOption",
            pattern: "[a-zA-Z0-9-/. ]*",
          },
          text: "Model #",
          pattern: "[a-zA-Z0-9-/. ]*",
        },
        {
          props: {
            value: "Upc",
            id: "upc",
            className: "SelectOption",
            pattern: "[0-9]*",
          },
          text: "UPC",
          pattern: "[0-9]*",
        },
        ...(isAliasSkuRequired
          ? [
              {
                props: {
                  value: "alternateSku",
                  id: "aliassku",
                  className: "SelectOption",
                },
                text: "Alternate SKU#",
              },
            ]
          : []),
      ];
    case "3":
    case "5":
      return [
        {
          props: { value: "item_nbr", id: "item", className: "SelectOption" },
          text: "Item #",
          pattern: "[0-9]*",
        },
        {
          props: { value: "model", id: "model", className: "SelectOption" },
          text: "Model #",
          pattern: "[a-zA-Z0-9-/. ]*",
        },
        {
          props: { value: "upc", id: "upc", className: "SelectOption" },
          text: "UPC",
          pattern: "[0-9]*",
        },
        ...(isAliasSkuRequired
          ? [
              {
                props: {
                  value: "alternateSku",
                  id: "aliassku",
                  className: "SelectOption",
                },
                text: "Alternate SKU#",
              },
            ]
          : []),
      ];

    case "2":
      return [
        {
          props: { value: "itemNbr", id: "itemNbr", className: "SelectOption" },
          text: "Item #",
        },
        {
          props: {
            value: "assortNumber",
            id: "assortNumber",
            className: "SelectOption",
          },
          text: "Assortment #",
          pattern: "alpha-hyphen-only",
        },
      ];
  }
};

export const getSortByOptions = (
  flowType,
  isAliasSkuRequired = false,
  VendorDropshipItems,
  shoppingAreaId
) => {
  switch (flowType) {
    case "1":
    case "4":
      return [
        ...(shoppingAreaId === '5'  ?
         [
         {
             props: { value: "seqno", id: "Sequence#", className: "SelectOption" },
             text: "Sequence#",
           },
         ]: []),
        {
          props: {
            value: "vendorname",
            id: "VendorName",
            className: "SelectOption",
          },
          text: "Vendor Name",
        },
        ...(shoppingAreaId !== '5' ?
        [
          {
            props: { value: "seqno", id: "Sequence#", className: "SelectOption" },
            text: "Sequence#",
          },
        ]: []),
        {
          props: { value: "item", id: "Item#", className: "SelectOption" },
          text: "Item#",
        },
        {
          props: { value: "model", id: "Model#", className: "SelectOption" },
          text: "Model#",
        },
        {
          props: { value: "upc", id: "UPC#", className: "SelectOption" },
          text: "UPC#",
        },
        {
          props: {
            value: "eventcost",
            id: "EvtCost",
            className: "SelectOption",
          },
          text: "Event Cost",
        },
        {
          props: { value: "savings", id: "savings", className: "SelectOption" },
          text: "Savings",
        },
        ...(isAliasSkuRequired
          ? [
              {
                props: {
                  value: "alias_sku",
                  id: "aliassku",
                  className: "SelectOption",
                },
                text: "Alternate SKU#",
              },
            ]
          : []),
      ];
    case "3":
    case "5":
      return [
        {
          props: {
            value: "item_nbr",
            id: "item_nbr",
            className: "SelectOption",
          },
          text: "Item #",
        },
        ...(isAliasSkuRequired
          ? [
              {
                props: {
                  value: "alias_sku",
                  id: "alternateSKU",
                  className: "SelectOption",
                },
                text: "Alternate SKU#",
              },
            ]
          : []),
        {
          props: { value: "model", id: "modelId", className: "SelectOption" },
          text: "Model#",
        },
      ];
    case "2":
      return [
        {
          props: {
            value: "itemNbr",
            id: "itemNbr#",
            className: "SelectOption",
          },
          text: "Item#",
        },
        {
          props: { value: "model", id: "model#", className: "SelectOption" },
          text: "Model#",
        },
        {
          props: { value: "upc", id: "upc#", className: "SelectOption" },
          text: "UPC",
        },
        ...(isAliasSkuRequired
          ? [
              {
                props: {
                  value: "alias_sku",
                  id: "aliassku",
                  className: "SelectOption",
                },
                text: "Alternate SKU#",
              },
            ]
          : []),
      ];
  }
};

export const getVendorDetails = function (vendorName, orderDeadline, vendorId) {
  const {
    shipdate1,
    shipdate2,
    shipdate3,
    shipdate4,
    memberterms,
  } = this?.props?.location?.state;
  return (
    <>
      <span className="listingVendorDetails">
        {" "}
        {decodeURIComponent(vendorName)}
        <span className="listingDeadline">
          {" "}
          Order Deadline: {orderDeadline}{" "}
        </span>
        <span
          onClick={() => this.showDealDetails()}
          className="dealDeadlinePalletListing"
        >
          Deal Details
        </span>
        {this.state.dealDetails && (
          <div
            id={
              vendorId && window.innerWidth < 1024
                ? "dealDetailsDialogItem"
                : "dealDetailsDialog"
            }
          >
            <DealsDialog
              vendorName={decodeURIComponent(vendorName)}
              member_terms={memberterms}
              shipdate1={shipdate1}
              shipdate2={shipdate2}
              shipdate3={shipdate3}
              shipdate4={shipdate4}
              DialogClose={this.DialogClose}
            />
          </div>
        )}
      </span>
    </>
  );
};
export const isSearchRequired = (flowType) => {
  return (
    flowType === "1" || flowType === "3" || flowType === "4" || flowType === "5"
  );
};

export const isShowByRequired = (flowType) => {
  return (
    flowType === "1" || flowType === "3" || flowType === "4" || flowType === "5"
  );
};

export const isSortRequired = (flowType) => {
  return true;
};
export const isQuickViewRequired = (flowType) => {
  return flowType === "1" || flowType === "4";
};
export const isAddToCartRequired = (flowType) => {
  return flowType === "1" || flowType === "4";
};
export const isSeqRequried = (flowType) => {
  return flowType === "1" || flowType === "4";
};
export const getBreadcrumbLinks = (
  fromReunionHome,
  flowType,
  eventId,
  vendorName,
  vendorId, // PromoName for flow type 2
  shoppingAreaName,
  shoppingAreaId,
  shoppingAreaMetaId,
  relayYear,
  relayNbr, // assortmentId for flow type 2
  raModel,
  shortDesc,
  promoName,
  promoNbr,
  palletNbr,
  pageName, //used as is itemlisting,
  AllitemCheck
) => {
  let keyParams = {
    flowType,
    shoppingAreaId,
    shoppingAreaMetaId,
    shoppingAreaName,
    pageName: "PromoListingBackUrl",
    relayNbr: relayNbr,
    fromReunionHome: fromReunionHome,
  };
  let breadcrumbLinks = [
    ...(fromReunionHome
      ? [{ link: "/reunionOrdering", text: "REUNION HOME" }]
      : []),
    {
      link: {
        pathname: getEventRedirectUrl({ keyParams }),
        state: { ...keyParams, fromReunionHome },
      },
      text: shoppingAreaName,
    }, //promo listing
  ];
  switch (flowType) {
    case "1":
      if ((pageName || raModel) === "VendorListing") {
        breadcrumbLinks.push({
          link: "#",
          text: utilities.emptyForNull(shortDesc) + ": VENDORS",
        }); //vendorListing
      }
      if (pageName === "ItemListing") {
        //isItem lsitige
        keyParams = {
          ...keyParams,
          pageName: "VendorListing",
          eventId,
          relayNbr,
          relayYear,
          flowType,
          promoName,
          fromReunionHome,
          promoName: shortDesc,
        };
        breadcrumbLinks.push({
          link: {
            pathname: getEventRedirectUrl({ keyParams }),
            state: keyParams,
          },
          text: utilities.emptyForNull(shortDesc) + ": " + eventId + " VENDORS",
        }); //vendorListing
        breadcrumbLinks.push({
          link: "#",
          text: vendorName + ": Items",
        }); ///item listing
      }
      if (pageName === "AllItems") {
        breadcrumbLinks.push({
          link: "#",
          text: eventId + " : " + shortDesc + ": ALL ITEMS",
        }); ///item listing
      }
      break;
    case "2":
      if (pageName === "AssortmentListing") {
        keyParams = {
          ...keyParams,
          flowType,
          pageName: "AssortmentListing",
          eventId,
        };
        breadcrumbLinks.push({
          link: "#",
          text: decodeURIComponent(raModel),
        });
      }
      if (pageName === "AssortmentItemListing") {
        keyParams = {
          ...keyParams,
          flowType,
          fromReunionHome,
          pageName: "AssortmentListing",
          eventId,
          eventName: vendorName,
        };
        breadcrumbLinks.push({
          link: {
            pathname: getEventRedirectUrl({ keyParams }),
            state: keyParams,
          },
          text: eventId + "-" + vendorName,
        });
        breadcrumbLinks.push({
          link: "#",
          text: `${shortDesc}`,
        });
      }
      break;
    case "3":
      if ((pageName || raModel) === "VendorListing") {
        breadcrumbLinks.push({
          link: "#",
          text: utilities.emptyForNull(shortDesc) + ": VENDORS",
        }); //vendorListing
      }
      if (pageName === "PalletListing") {
        //isItem lsiting
        keyParams = {
          ...keyParams,
          pageName: "VendorListing",
          eventId,
          vendorId,
          vendorName,
          relayNbr,
          flowType,
          relayYear,
          promoName,
          promoNbr,
          fromReunionHome,
          promoName: shortDesc,
        };
        breadcrumbLinks.push({
          link: {
            pathname: getEventRedirectUrl({ keyParams }),
            state: keyParams,
          },
          text:
            utilities.emptyForNull(shortDesc) + ": " + promoNbr + " VENDORS",
        }); //vendorListing
        breadcrumbLinks.push({
          link: "#",
          text: vendorName ? vendorName : vendorId,
        }); ///pallet listing
      }
      if (pageName === "PalletItemListing") {
        if (!utilities.isEmptyOrNullString(vendorId) && !AllitemCheck) {
          keyParams = {
            ...keyParams,
            pageName: "VendorListing",
            eventId,
            relayNbr,
            flowType,
            relayYear,
            promoName,
            fromReunionHome,
          };
          breadcrumbLinks.push({
            link: {
              pathname: getEventRedirectUrl({ keyParams }),
              state: keyParams,
            },
            text: decodeURIComponent(promoName) + ": " + eventId + " VENDORS",
          });
        } else {
          keyParams = {
            ...keyParams,
            pageName: "PalletAllItems",
            eventId,
            relayNbr,
            flowType,
            relayYear,
            promoName,
            fromReunionHome,
          };
          breadcrumbLinks.push({
            link: {
              pathname: getEventRedirectUrl({ keyParams }),
              state: keyParams,
            },
            text: decodeURIComponent(promoName) + ": " + eventId + " All Items",
          });
        }
        if (!utilities.isEmptyOrNullString(vendorName) && !AllitemCheck) {
          keyParams = {
            ...keyParams,
            pageName: "PalletListing",
            eventId,
            vendorId,
            vendorName,
            relayNbr,
            flowType,
            relayYear,
            promoName,
            fromReunionHome,
          };
          breadcrumbLinks.push({
            link: {
              pathname: getEventRedirectUrl({ keyParams }),
              state: keyParams,
            },
            text: vendorName,
          });
          breadcrumbLinks.push({
            link: "#",
            text: shortDesc,
          });
        } else {
          breadcrumbLinks.push({
            link: "#",
            text: shortDesc,
          });
        }
      }
      if (pageName === "AllPallets") {
        breadcrumbLinks.push({
          link: "#",
          text: eventId + " : " + shortDesc + "- ALL ITEMS",
        }); ///item listing
      }
      break;
    case "4":
      if ((pageName || raModel) === "VendorDropShipVendorListing") {
        breadcrumbLinks.push({
          link: "#",
          text: utilities.emptyForNull(shortDesc) + ": VENDORS",
        }); //vendorListing
      }
      if (pageName === "DropShipVendorItems") {
        //isItem lsitige
        keyParams = {
          ...keyParams,
          pageName: "VendorDropShipVendorListing",
          eventId,
          relayNbr,
          relayYear,
          flowType,
          promoName,
          fromReunionHome,
          promoName: shortDesc,
        };
        breadcrumbLinks.push({
          link: {
            pathname: getEventRedirectUrl({ keyParams }),
            state: keyParams,
          },
          text: utilities.emptyForNull(shortDesc) + " : VENDORS",
        }); //vendorListing
        breadcrumbLinks.push({
          link: "#",
          text: decodeURIComponent(vendorName) + " : Items",
        }); ///item listing
      }
      if (pageName === "AllVendorDropShipItems") {
        breadcrumbLinks.push({
          link: "#",
          text: shortDesc + ": ALL ITEMS",
        }); ///item listing
      }
      break;
    case "5":
      if ((pageName || raModel) === "VendorListingDropShipDiscountCity") {
        breadcrumbLinks.push({
          link: "#",
          text: utilities.emptyForNull(shortDesc) + ": VENDORS",
        }); //vendorListing
      }
      if (pageName === "PalletDropShipDCVendorItems") {
        //isItem lsiting
        keyParams = {
          ...keyParams,
          pageName: "VendorListingDropShipDiscountCity",
          eventId,
          vendorId,
          vendorName,
          relayNbr,
          flowType,
          relayYear,
          promoName,
          promoNbr,
          fromReunionHome,
          promoName: shortDesc,
        };
        breadcrumbLinks.push({
          link: {
            pathname: getEventRedirectUrl({ keyParams }),
            state: keyParams,
          },
          text: utilities.emptyForNull(shortDesc) + ": VENDORS",
        }); //vendorListing
        breadcrumbLinks.push({
          link: "#",
          text: vendorName ? vendorName : vendorId,
        }); ///pallet listing
      }
      if (pageName === "DropShipPalletItemListing") {
        if (!utilities.isEmptyOrNullString(vendorId) && !AllitemCheck) {
          keyParams = {
            ...keyParams,
            pageName: "VendorListingDropShipDiscountCity",
            eventId,
            relayNbr,
            flowType,
            relayYear,
            promoName,
            fromReunionHome,
          };
          breadcrumbLinks.push({
            link: {
              pathname: getEventRedirectUrl({ keyParams }),
              state: keyParams,
            },
            text: decodeURIComponent(promoName) + ":  VENDORS",
          });
        } else {
          keyParams = {
            ...keyParams,
            pageName: "DropShipPalletAllItems",
            eventId,
            relayNbr,
            flowType,
            relayYear,
            promoName,
            fromReunionHome,
          };
          breadcrumbLinks.push({
            link: {
              pathname: getEventRedirectUrl({ keyParams }),
              state: keyParams,
            },
            text: decodeURIComponent(promoName) + ": " + eventId + " All Items",
          });
        }
        if (!utilities.isEmptyOrNullString(vendorName) && !AllitemCheck) {
          keyParams = {
            ...keyParams,
            pageName: "PalletDropShipDCVendorItems",
            eventId,
            vendorId,
            vendorName,
            relayNbr,
            flowType,
            relayYear,
            promoName,
            fromReunionHome,
          };
          breadcrumbLinks.push({
            link: {
              pathname: getEventRedirectUrl({ keyParams }),
              state: keyParams,
            },
            text: vendorName,
          });
          breadcrumbLinks.push({
            link: "#",
            text: shortDesc,
          });
        } else {
          breadcrumbLinks.push({
            link: "#",
            text: shortDesc,
          });
        }
      }
      if (pageName === "AllPallets") {
        breadcrumbLinks.push({
          link: "#",
          text: eventId + " : " + shortDesc + "- ALL ITEMS",
        }); ///item listing
      }
  }
  return breadcrumbLinks;
};

export const getImageLink = (itemNbr) => {
  const link =
    "https://images.truevalue.com/getimage.asp?id=" +
    itemNbr +
    "&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2";
  return link;
};

export const getAltImageLink = (itemNbr) => {
  return getImageURl() + `/nsimages/${itemNbr}` + ".jpg";
};

export const getPalletImageLink = (palletNbr) => {
  // Need to change the URl once images are deployed to specific environments
  return getPalletImage() + `${palletNbr}` + "0.jpg";
};

export const getPdpParams = (flowType, product) => {
  let eventFields = [];
  switch (flowType) {
    case "1":
      const eventCostprice = product?.spcCost ? product?.spcCost : product.promoCost;
      const savings =
        utilities.isEmptyOrNullString(eventCostprice) ||
        parseFloat(eventCostprice) === 0.0
          ? "N/A"
          : utilities.hyphenForZeroPercentage(product.savings);
      const evtCostAtReunion =
        parseFloat(eventCostprice) === 0.0 || product.savings === 100
          ? "Pricing Avail @ Reunion"
          : utilities.emptyForNull(
            utilities.changePriceToNumber(utilities.toIntlNumberFormat(eventCostprice))
            );
      eventFields = [
        { fieldName: "Savings", value: savings },
        { fieldName: "Event Cost", value: evtCostAtReunion },
        {
          fieldName: "Regular Cost",
          value: utilities.emptyForNull(
            utilities.changePriceToNumber(utilities.toIntlNumberFormat(product?.futureCost && product?.futureCost > 0 ? product?.futureCost : product?.MSFuturecost>0?product.MSFuturecost : product.cost))
          ),
        },
        { fieldName: "Non Stocked", value: getStockedField(product.stocked) },
      ];
      break;
    case "2":
      eventFields = [];
      break;
    default:
      eventFields = [];
      break;
  }
  return {
    eventFields,
    flowType,
    promoNbr: product.promoNbr,
    hideAddToCart: hideAddToCartInPDP(flowType),
  };
};
const getStockedField = (stocked) => {
  return isNaN(parseInt(stocked)) ? stocked : stocked === 0 ? "N" : "Y";
};
export const getPalletImageElement = (
  palletNbr,
  getPalletItemListing,
  imgStyle,
  isLinkRequired
) => {
  return (
    <span onClick={isLinkRequired && getPalletItemListing} className="cursor">
      {renderImages.getImage({
        src: getPalletImageLink(palletNbr),
        altUrl: getImageLink(palletNbr),
        alt: "product image",
        className: `ui image image_lisitng ${imgStyle}`,
      })}
    </span>
  );
};
export const getImageElement = (
  setBackButtonPath,
  itemNbr,
  pdpParams,
  imgStyle,
  addimgHover,
  isLinkRequired,
  altImg,
  isReunionListing,
  shoppingAreaName,
  shoppingAreaMetaId
) => {
  const image = renderImages.getImage({
    src: ( altImg && altImg == 1 ) ? getAltImageLink(itemNbr) : getImageLink(itemNbr),
    alt: "product image",
    className: `ui image image_lisitng ${imgStyle}`,
    onMouseEnter: () => {
      addimgHover("addimg", itemNbr);
    },
  });
  if (!isLinkRequired) {
    return <>{image}</>;
  }
  return (
    <Link
      onClick={setBackButtonPath}
      to={{
        pathname: "/product/" + itemNbr,
        state: { ...pdpParams,isReunionListing,shoppingAreaName,shoppingAreaMetaId },
      }}
      className={
        imgStyle === "displayNone" ? "disable_img_click" : "card_list_img"
      }
    >
      {image}
    </Link>
  );
};
const getVendorLink = (vendorName, vendorId) => {
  return (
    "/vendor/" + utilities.replaceAll(vendorName, "/", "%2F") + "/" + vendorId
  );
};
export const getVendorLabel = (
  setBackButtonPath,
  vendorName,
  vendorId,
  className = "",
  isMSorReunnion = false
) => {
  return (
    !isMSorReunnion ?<Link
      className={className}
      onClick={setBackButtonPath}
      to={{
        pathname: vendorName ? getVendorLink(vendorName, vendorId) : "#",
        state: { vendorId },
      }}
    >
      {vendorName}
    </Link> : <>{vendorName}</>
  );
};

export const getProductTitleLabel = (
  setBackButtonPath,
  itemNbr,
  pdpParams,
  shortDesc,
  isPDPlinkReqd,
  shoppingAreaMetaId,
  available,
  isReunionListing,
  shoppingAreaName
) => {
  const title = <b className="First_level_details">{shortDesc}</b>;
  if (!isPDPlinkReqd) {
    return <>{title}</>;
  }
  return (
    <Link
      onClick={setBackButtonPath}
      to={{
        pathname: "/product/" + itemNbr,
        state: { ...pdpParams, shoppingAreaMetaId, available,isReunionListing,shoppingAreaName,shoppingAreaMetaId},
      }}
    >
      {title}
    </Link>
  );
};

export const getQtyField = (cell, handleQtyChange, fieldName, pallet) => {
  return (
    <input
      type="text"
      date-shipDate={pallet}
      data-index={cell.index}
      data-field={fieldName}
      onChange={handleQtyChange}
      value={cell.val && parseInt(cell.val) > 0 ? cell.val : ""}
      className="qty-box"
    />
  );
};

export const getOverrideRetailField = (cell, handleOrChange) => {
  return (
    <>
      $ {` `}
      <input
        className="qty-box"
        data-index={cell.index}
        value={utilities.emptyForNull(cell.val)}
        data-field="or"
        onChange={handleOrChange}
      />
    </>
  );
};

export const getPageNumber = (chosenPage, paginationParams) => {
  if (chosenPage === "Next") {
    return parseInt(paginationParams.currentPage) <
      parseInt(paginationParams.totalPages)
      ? parseInt(paginationParams.currentPage) + 1
      : paginationParams.currentPage;
  }
  if (chosenPage === "Prev") {
    return paginationParams.currentPage === 1
      ? 1
      : parseInt(paginationParams.currentPage) - 1;
  }
  if (chosenPage === "»") {
    return paginationParams.totalPages;
  }
  if (chosenPage === "«") {
    return 1;
  }
  return chosenPage;
};

export const isMaxQtyValid = (qty, maxQty, isMultiples, toast = true) => {
  if (qty && !(maxQty && maxQty > 0)) return true;
  if (qty && !isMultiples && qty > maxQty) {
    if(toast) {
    utilities.showToast("Maximum Order quantity is " + maxQty);
    }
    return false;
  } else if (qty && isMultiples && qty % maxQty !== 0) {
    if(toast) {
    utilities.showToast("Quantity should be a multiple of " + maxQty);
    }
    return false;
  }
  return true;
};

export const handleQtyChange = function (event) {
  //flowtype 1 and 3
  const { dataset, value, name } = event.target;
  const { field, index, shipdate } = dataset;
  const fieldValue = parseFloat(value);
  if (field !== "or" && !quantityValidator(value)) {
    return false;
  }
  let { quantities, qtyList, overrideRetails } = this.state;

  switch (field) {
    case "qty":
      quantities[index] = fieldValue || "";
      break;
    case "or":
      if (value.trim() === "" || fieldValue < 0) {
        utilities.showToast("Override Retail should be positive number!", true);
      }
      if (/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value)) {
        overrideRetails[index] = value || "";
      } else {
        overrideRetails[index] = fieldValue || "";
      }
      break;
    case "qty1":
      quantities[index][0] = fieldValue || "";
      break;
    case "qty2":
      quantities[index][1] = fieldValue || "";
      break;
    case "qty3":
      quantities[index][2] = fieldValue || "";
      break;
    case "qty4":
      quantities[index][3] = fieldValue || "";
      break;
    case "qty1Dropship":
      let shipIndex = qtyList[index];
      shipIndex = { ...shipIndex, [`${name}`]: fieldValue || "" };
      qtyList = { ...qtyList, [`${index}`]: shipIndex };
      this.setState({
        qtyList,
      });
      break;
    default:
      quantities[index] = fieldValue || "";
      break;
  }

  this.setState({
    overrideRetails: [...overrideRetails],
    quantities: [...quantities],
  });
};
export const setScrollPosition = function(){
  this.scrollPosition = window.pageYOffset;
  this.setBackButtonPath();
}
export const addToCart = function (
  itemNum,
  index,
  sPackType,
  sPackQty,
  maxQty,
  vendorId,
  isMultiples,
  model,
  maxQtyPerReunion
) {
  let  {
    relayNbr,
    eventId,
    flowType,
    shoppingAreaName,
    shoppingAreaId,
    shoppingAreaMetaId,
    quantities,
    qtyList,
    overrideRetails,
  } = this.state;
  shoppingAreaMetaId = parseInt(shoppingAreaMetaId) ? shoppingAreaMetaId : ''
  this.scrollPosition = window.pageYOffset;
  this.setBackButtonPath();
  const qties = quantities[index];
  let payload = {};
  if (flowType === "5") {
    let qtyListItems = qtyList[index];
    if (
      qtyListItems === "undefined" ||
      !utilities.isValidQuantity(qtyListItems) ||
      (_.isEmpty(qtyListItems) && qtyListItems.length === 0) ||
      (_.isEmpty(qtyListItems) &&
        Object.keys(qtyListItems).map((key) => qtyListItems[key] === ""))
    ) {
      utilities.showToast("Please enter a valid quantity", true);
      return;
    }
    let resObject =
      !_.isEmpty(qtyListItems) &&
      Object.keys(qtyListItems).map((key) => {
        return {
          month: key,
          quantity: qtyListItems[key],
        };
      });
    payload = {
      userId: this.props.userId.toString(),
      storeId: this.props.storeId,
      itemNum: itemNum.toString(),
      vendorID: vendorId,
      quantityList: resObject,
      type: eventId,
      relayNbr,
      promoNbr: eventId,
      rdcNbr: this.props.rdc,
      molUserName: this.props.userName,
      flowType: parseInt(flowType),
      shoppingName: shoppingAreaName,
      shoppingIdMeta:shoppingAreaMetaId,
      modelNum: model,
    };
  } else {
    let overRideRetail = overrideRetails[index];
    let qtyExists = false;
    let invalidQty = false;
    let totalQty = 0;
    qties.forEach((qty) => {
      if (!utilities.isEmptyOrNullString(qty)) {
        if (sPackType && !validateProductQuantity(sPackType, sPackQty, qty) || !isMaxQtyValid(qty, maxQty, isMultiples)) {
          invalidQty = true;
        }
        if((parseInt(shoppingAreaMetaId) !== 1 || parseInt(shoppingAreaMetaId) !== 5) && maxQtyPerReunion && maxQtyPerReunion >0 && !isMaxQtyValid(qty, maxQtyPerReunion, false)){
          invalidQty = true;
        }
        totalQty += !isNaN(parseInt(qty)) ? parseInt(qty) : 0;
        qtyExists = true;
      }
    });
    if((parseInt(shoppingAreaMetaId) === 1 || parseInt(shoppingAreaMetaId) === 5) && !isMaxQtyValid(totalQty, maxQty, isMultiples)){
      invalidQty = true;
    }
    
    
    if (invalidQty) {
      return;
    }
    if (!qtyExists) {
      utilities.showToast("Enter atleast one quantity");
      return;
    }
    payload = {
      userID: this.props.userId.toString(),
      itemNum: itemNum.toString(),
      userQty: qties[0].toString(),
      userQty2: qties[1].toString(),
      userQty3: qties[2].toString(),
      userQty4: qties[3].toString(),
      vendorID: vendorId,
      RelayNbr: relayNbr,
      promoNbr: eventId,
      rdcNbr: this.props.rdc,
      molUserName: this.props.userName,
      flowType: parseInt(flowType),
      shoppingName: shoppingAreaName,
      shoppingIdMeta:shoppingAreaMetaId,
      modelNum: model,
      overrideRetail: overRideRetail,
    };
  }
  utilities.setLoader(true);
  restUtils
    .postData(MARKET_ADD_TO_CART, payload)
    .then((response) => {
      utilities.showToast("Product added to cart!");
      utilities.setLoader(false);
      const cartId = response?.data?.cartID;
      this.props.viewAllCarts(cartId); // to set current cart
    })
    .catch((error) => {
      utilities.showToast("Couldn't add product to cart", true);
      utilities.setLoader(false);
    });
};

const hideAddToCartInPDP = (flowType) => {
  return true;
};

export const ProductIndicators = ({ product, isPLP, isPdp, flowType }) => {
  const isNew =
    product.newitem === "1" ||
    product.newitem === "True" ||
    product.Newitem === "True";
  const isCore =
    product.dtvInd === "1" ||
    product.dtvInd === "True" ||
    product.DtvInd === "True" ||
    product.dtvInd === "Y";
  const isBom = product.bom_ind === "BOM";
  const hasAssortmentLevel =
    product.assortmentLevel && product.assortmentLevel.trim() !== "";
  const isMadeInUsa =
    (product.madeInUSAInd && product.madeInUSAInd !== "N") ||
    (product.madInUSAInd && product.madInUSAInd !== "N");
  const isDiscontinued =
    product.disconInd === "D" || product.discontinued === "D";
  const isToBeDiscontinued =
    product.disconInd === "T" || product.discontinued === "T";
  const isGreenerOption =
    product.GreenerOptionInd === "1" || product.greenerOptionInd === "True";
  const isProp65 = product.prop65 === "Y" || product.prop65Indicator === "Y";
  const isHazMat = product.hazMat === "Y" || product.HazMat === "Y";
  const advCode = product.adv_code;
  const importInd = product.import_cd;
  const featureOfMonth = product.fom_ind;
  const whiteSupplies = product.wsl_ind;
  const nonReturnable = product.nr_ind;
  const rebateInd = product.Rebate_IND;
  const isbrandName = product.brandName;

  return (
    <>
      {<MadeinUsaInidicator isMadeInUsa= {isMadeInUsa} isbrandName = {isbrandName} flowType = {flowType}/>}
      {isPLP === "PLP" && isProp65 && <Prop65Indicator />}
      {isNew && <span className="indicator new-item">New </span>}
      {isCore && (
        <span className="indicator core-item">
          {isNew ? "| Core Item" : "Core Item"}
        </span>
      )}
      {isHazMat && (
        <span className="indicator hazmat-item">
          <span className="seprator-color hazmat-color">
            {isNew || isCore ? "| Hazmat Item" : "Hazmat Item"}
          </span>
        </span>
      )}
      {isDiscontinued && (
        <span className="indicator discon-ind">
          {isNew || isCore || isHazMat ? (
            <span className="seperator-color">
              <span>|</span> DISCONTINUED
            </span>
          ) : (
            "DISCONTINUED"
          )}
        </span>
      )}
      {isToBeDiscontinued && (
        <span className="indicator discon-ind">
          {isNew || isCore || isHazMat ? (
            <span className="seperator-color">
              <span>|</span> TO BE DISCONTINUED
            </span>
          ) : (
            "TO BE DISCONTINUED"
          )}
        </span>
      )}
      {isGreenerOption && (
        <span className="indicator greener-ind">
          {isNew || isCore || isHazMat ? (
            <span className="seperator-color">
              <span>|</span> GREENER OPTIONS
            </span>
          ) : (
            "GREENER OPTIONS"
          )}
        </span>
      )}
      {isBom && <BOMIndicator />}
      {hasAssortmentLevel && (
        <>
          {isNew || isCore || isHazMat ? (
            <span className="indicator assortment-item">
              <span className="seprator-color"> | </span>
              <span className="seprator-color indicator pdp-size">
              {parseInt(product.assortmentLevel || product.AssortmentLevel) == 1 ? <><img className='img-mvp-size' src={MVP_Assortment_items}/></> :
                                parseInt(product.assortmentLevel || product.AssortmentLevel) == 2 || parseInt(product.assortmentLevel || product.AssortmentLevel) == 3 ? <><img className='img-assort-size' src={Assortment_items}/></> : <Popup content="Rebate eligible in current calendar year" trigger={ <p>Expiring Assortment Item<Icon
                                  className='info circle icon'
                                  style={{ cursor: 'pointer', verticalAlign: 'initial' }}></Icon></p>} basic/>  }</span>
            </span>
          ) : (
            <span className="seprator-color indicator pdp-size">
              {parseInt(product.assortmentLevel || product.AssortmentLevel) == 1 ? <><img className='img-mvp-size' src={MVP_Assortment_items}/></> :
                                parseInt(product.assortmentLevel || product.AssortmentLevel) == 2 || parseInt(product.assortmentLevel || product.AssortmentLevel) == 3 ? <><img className='img-assort-size' src={Assortment_items}/></> : <Popup content="Rebate eligible in current calendar year" trigger={ <p>Expiring Assortment Item<Icon
                                  className='info circle icon'
                                  style={{ cursor: 'pointer', verticalAlign: 'initial' }}></Icon></p>} basic/>  }
            </span>
          )}
        </>
      )}
      {advCode && <AdvCode advCode={advCode} />}
      {importInd && importInd === "True" && (
        <span className="indicator new-item">| {"IM"}</span>
      )}
      {featureOfMonth && featureOfMonth === "1" && (
        <span className="indicator fom-ind">| {"FOM"}</span>
      )}
      {whiteSupplies && whiteSupplies === "1" && (
        <span className="indicator wsl-ind">| {"WSL"}</span>
      )}
      {nonReturnable && nonReturnable === "True" && (
        <span className="indicator promo_indicator">| {"NR"}</span>
      )}
      {rebateInd && rebateInd === "True" && (
        <span className="indicator rebate">| {"REB"}</span>
      )}
    </>
  );
};

const MadeinUsaInidicator = ({isMadeInUsa, isbrandName, flowType}) => {
  return (
    isMadeInUsa && isbrandName && flowType === '1' ? 
    <span className="miu-wrap">
      {renderImages.getImage({ src: usFlag, className: "madeinusa_indicator" })}
      <span className="indicator">&nbsp;Made In USA</span><span className="indicator green_thumb">&nbsp;{isbrandName}</span>
    </span>
     :
     isbrandName ?
    <span className="miu-wrap">
      <span className="indicator green_thumb">&nbsp;{isbrandName}</span>
    </span> :
    isMadeInUsa ? 
    <span className="miu-wrap">
      {renderImages.getImage({ src: usFlag, className: "madeinusa_indicator" })}
      <span className="indicator">&nbsp;Made In USA</span>
    </span>
    : <></>
  );
};

const Prop65Indicator = () => {
  return (
    <>
      {renderImages.getImage({
        src: warning,
        className: "warning_icon",
        alt: "warn",
      })}
      <span id="no_padding" className="warning prop65_color">
        &nbsp;Prop65
      </span>
      <br />
    </>
  );
};

const BOMIndicator = () => {
  return <span className="indicator promo_indicator">| BOM </span>;
};
const AdvCode = ({ advCode }) => {
  return <span className="indicator promo_indicator">| {advCode}</span>;
};

export const exportLargeData = async (url, payload, totalItems, callback) => {
  const resultPerPage = 500;
  const numberOfPromises =
    totalItems % resultPerPage > 0
      ? totalItems / resultPerPage + 1
      : totalItems / resultPerPage;
  let index = 0;
  let promises = [];
  for (index = 1; index <= numberOfPromises; index++) {
    payload = { ...payload, page: index, resultPerPage };
    promises.push(
      restUtils.postData(url, payload).catch((error) => {
        return { data: { reunionItemList: [] } };
      })
    );
  }
  Promise.all(promises).then((values) => {
    let itemList = [];
    values.forEach((response) => {
      itemList = [...itemList, ...response?.data?.reunionItemList];
    });
    callback(itemList);
  });
};
export const dcItemLabel = (label) => {
  switch (label) {
    case "EVT Cost:":
      return "DS EVT COST:";
    case "Member Cost:":
      return "DS COST:";
    default:
      return label;
  }
};
export const palletLocationVal = (locNbr, locSection) => {
  return !locNbr && !locSection ? '-': (utilities.emptyForNull(locNbr)+''+utilities.emptyForNull(locSection))
}
