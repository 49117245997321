import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { logout } from '../../views/Login/actions';
import Home from '../../views/Home';
import Header from '../../components/NavBar/Header';
import { connect } from 'react-redux';
import Footer from '../../views/common/Footer';
import AddressBook from '../../views/AddressBook';
import Scan from '../../views/Scan';
import Preferences from '../Preferences';
import ProductScandetails from '../../views/Scan/ProductScandetails';
import AddressBookUpload from '../../views/AddressBook/AddressBookUpload';
import AddressViewEdit from '../AddressBook/AddressViewEdit';
import Vendors from '../Vendors';
import ProductDetails from '../../views/common/ProductDetails';
import Categories from '../../views/Categories';
import Cart from '../../views/Cart';
import OrderPad from '../../views/OrderPad';
import OrderUpload from '../../views/OrderUpload';
import AllOrders from '../../views/AllOrders';
import AllWarehouses from '../../views/AllWarehouses';
import Favorites from '../../views/Favorites';
import FavoriteVendors from '../Favorites/vendors';
import NotFound from '../../views/ErrorPages/404_error_page';
import history from '../../history';
import Checkout from '../Cart/Checkout';
import CheckoutPlanograms from '../Cart/CheckoutPlanograms';
import CheckoutImpulsemerchandising from '../Cart/CheckoutImpulsemerchandising';
import OrderConfirmation from '../Cart/OrderConfirmation';
import AskTrueValue from '../AskTrueValue';
import RetailAssortments from '../../views/RetailAssortment';
import RetailAssortmentsLinks from '../../views/RetailAssortment/RetailAssortmentLinks';
import ReunionAssortmentDeptPage from '../../views/RetailAssortment/ReunionAssortment/ReunionAssortmentDeptPage'
import FlashSales from '../../views/RetailAssortment/FlashSales';
import WpTemplate from '../WpTemplate';
import DealsOfTheMonth from '../DealsOfTheMonth';
import AssortmentListing from '../../views/RetailAssortment/RetailAssortmentItemListing';
import HelpAndTutorials from '../../views/common/HelpAndTutorials/HelpAndTutorials';
import Planograms from '../Planograms';
import PlanogramLinks from '../Planograms/PlanogramLinks';
import ReunionEvent from '../../views/ReunionEvent';
import PlanogramItemListing from '../Planograms/PlanogramItemListing';
import DOMProductListing from '../DealsOfTheMonth/DOMProductListing';
import ScrollToTop from '../../components/ScrollToTop';
import OrderItemLevelDetails from '../../views/AllOrders/OrderItemLevelDetails';
import PromoItemDetails from '../Orders/PromoShip/PromoItemDetails';
import ImpulseMerchandising from '../ImpulseMerchandising';
import POG from '../ImpulseMerchandising/POG';
import ImItemListing from '../ImpulseMerchandising/ImItemListing';
import ShipLaters from '../../views/ShipLaters';
import CancelledItems from '../Orders/CancelledItems';
import FillRates from '../Orders/FillRates';
import ReunionEventVendors from '../../views/ReunionEvent/ReunionEventVendors';
import ReunionEventitemListingVendors from '../../views/ReunionEvent/ReunionEventitemListingVendors';
import ReunionApp from '../../views/ReunionApp';
import PreviewCircular from '../DealsOfTheMonth/PreviewCircular';
import ReunionItemListing from '../ReunionEvent/ReunionItemListing';
import AuthPage from './AuthPage';
import EditOrders from '../Orders/EditOrders/EditOrders';
import EditOrderItemLevelDetails from '../Orders/EditOrders/EditOrderItemLevelDetails';
import RetailAssortmentItemListing from '../../views/RetailAssortment/RetailAssortmentItemListing';
import wholeSaleDeals from '../WholeSaleDeals';
import CheckouRetailEvents from '../Cart/CheckouRetailEvents';
import FinelineCatalogLandingPage from '../FinelineCatalog/FinelineCatalogLandingPage';
import FlipbookViewer from '../FinelineCatalog/FinelineCatalogFlipbookViewer';
import FinelineCatalogAdConfiguration from '../FinelineCatalog/FinelineCatalogAdConfiguration';
import FinelineIndex from '../FinelineCatalog/FinelineIndex';
import MonthlySpecials from '../ReunionEvent/MonthlySpecials';
import MonthlySpecialEventVendors from '../ReunionEvent/MonthlySpecials/MonthlySpecialEventVendors';
import MonthlySpecialsItemListing from '../ReunionEvent/MonthlySpecials/MonthlySpecialsItemListing';
import DiscountCity from '../../views/ReunionEvent/DiscountCity';
import DiscountCityItemListing from '../../views/ReunionEvent/DiscountCity/DiscountCityItemListing';
import DiscountCityEventVendors from '../../views/ReunionEvent/DiscountCity/DiscountCityEventVendors';
import DiscountCitypalletListing from '../../views/ReunionEvent/DiscountCity/DiscountCitypalletListing';
import PalletListing from '../../views/ReunionEvent/AllReunion/palletListing';
import PalletItemListing from '../../views/ReunionEvent/AllReunion/PalletItemListing'
import AllReunion from '../../views/ReunionEvent/AllReunion';
import VendorListing from '../../views/ReunionEvent/AllReunion/VendorListing';
import AssortmentsList from '../../views/ReunionEvent/AllReunion/AssortmentsList';
import AssortmentItemListing from '../../views/ReunionEvent/AllReunion/AssortmentItemListing';
import CheckoutRA from '../Cart/CheckoutRA';
import PromoShip from '../Orders/PromoShip';
import ReunionOrdering from '../ReunionHome/ReunionOrdering';
import corporateEbook from '../../views/CorporateEbook';
import ItemListing from '../ReunionEvent/AllReunion/ItemListing';
import CloseoutCorner from '../CloseoutCorner/CloseoutCorner';
import ShoppingArea from '../Admin/ShoppingArea';
import PromoLinking from '../Admin/PromoLinking';
import MenuOptions from '../Admin/MenuOptions';
import LimitQuantity from '../Admin/LimitQuantity';
import RARestrictions from '../Admin/RARestrictions';
import VendorDropShipItemListing from '../ReunionEvent/AllReunion/VendorDropShipItemListing';
import DropShip from '../Admin/DropShip';
import CartReport from '../Admin/CartReport';
import VendorDropShipVendorListing from '../ReunionEvent/AllReunion/VendorDropShipVendorListing';
import DropShipPalletListing from '../ReunionEvent/AllReunion/DropShipPalletListing';
import DropShipPalletItemListing from '../ReunionEvent/AllReunion/DropShipPalletItemListing';
import VendorListingDropShipDC from '../ReunionEvent/AllReunion/VendorListingDropShipDC';
import CreditCardPaymentOption from '../CreditCardPaymentOption';
import Retailer from '../RetailerAlerts'
import BusinessTools from './BusinessTools'
import MultiStore from '../MultiStore';
import Fixtures from "../Fixtures";
import { Loader, Responsive } from 'semantic-ui-react';
import Freight from '../Freight';
import ContentSearch from '../WordPress/ContentSearch';
import { BIGDESKTOP} from './constants'
import AuthLoader from './AuthLoader';
import {  getfieldConnectUrl } from '../../urlConstants';
import  POGEnable from '../Admin/CTBReopen';
import CheckoutDirectShip from '../Cart/CheckoutDirectShip';
import DirectShipOderDetails from '../AllOrders/DirectShipOderDetails';

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state ={
      loadedFooter:false
    }
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress'
    ];
    this.clearSessionTimeout = this.clearSessionTimeout.bind(this);
    this.setSessionTimeout = this.setSessionTimeout.bind(this);

    for (let i in this.events) {
      window.addEventListener(this.events[i], this.clearSessionTimeout);
    }
    this.setSessionTimeout();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  clearSessionTimeout() {
    if (this.logoutTimeout) {
      clearTimeout(this.logoutTimeout);
    }
    this.setSessionTimeout();
  }
  
  handleScroll = (event) => {
    if(Math.round((window.scrollY /(document.body.scrollHeight -window.innerHeight)*100) > 80) && !this.state.loadedFooter) {
      this.setState({
        loadedFooter: true
      })
    }
}
  setSessionTimeout() {
    this.logoutTimeout = setTimeout(() => {
      this.props.history.push('/');
      this.props.logout();
    }, 30 * 60 * 1000);
  }
  render() {
    return (
      <>
        <Header content={this.props.children} />
        <Responsive {...Responsive.onlyComputer} minWidth={320} maxWidth={BIGDESKTOP}>
        {this.state.loadedFooter ?  <Footer /> : <div className='offlineFooter'><Loader active/></div>} 
        </Responsive>
        <Responsive {...Responsive.onlyComputer} minWidth={BIGDESKTOP}>
          <div className='biggerScreen'><p>Please adjust the zoom settings up on your web-browser or the resolution of your screen for the best viewing experience.</p></div>
        </Responsive>
       
      </>
    );
  }
}

const mapStateToLayoutProps = state => {
  return {
    ...state.SessionReducer
  };
};

const LayoutContainer = connect(mapStateToLayoutProps, { logout })(Layout);

class RenderRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader:false,
      // If we need Layout and AuthErrorBoundary for a screen, need_layout_and_auth flag should be yes
      // If we have to pass any params to a screen need_props should be yes.
      list_of_pages: [
        // {
        //   path: '/error',
        //   component: ErrorPage,
        //   need_props: 'no'
        // },
        {
          path: '/',
          component: AuthPage,
          need_props: 'no'
        },
        {
          path: '/home',
          component: Home,
          need_props: 'no'
        },
        {
          path: '/AddressBook',
          component: AddressBook,
          need_props: 'no'
        },
        {
          path: '/Scan',
          component: Scan,
          need_props: 'no'
        },
        {
          path: '/Scan/:code',
          component: Scan,
          need_props: 'no'
        },
        {
          path: '/Preferences',
          component: Preferences,
          need_props: 'no'
        },
        {
          path: '/MultiStoreSelection',
          component: MultiStore,
          need_props: 'no'
        },
        {
          path: '/productscandetails',
          component: ProductScandetails,
          need_props: 'no'
        },
        {
          path: '/AddressBookUpload',
          component: AddressBookUpload,
          need_props: 'no'
        },
        {
          path: '/AddressViewEdit',
          component: AddressViewEdit,
          need_props: 'yes'
        },
        {
          path: '/Vendors',
          component: Vendors,
          need_props: 'yes'
        },
        {
          path: '/Vendors/:orderType',
          component: Vendors,
          need_props: 'yes'
        },
        {
          path: '/categories/:deptId',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/vendor/:vendorName',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/discountCitypalletListing/:palletnumber',
          component: DiscountCitypalletListing,
          need_props: 'yes'
        },
        {
          path: '/vendor/:vendorName/:vendorId',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/vendor/:vendorName/:vendorId/:vendorCount',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/categories/:deptId/:categoryId',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/categories/:deptId/:categoryId/:subCategoryId',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/listing/:listType',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/directship/:listType/:vendorName/:vendorId/:vendorCount',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/directship/:listType/:vendorName/:vendorId',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/product/:productId',
          component: ProductDetails,
          need_props: 'yes'
        },
        {
          path: '/orderpad',
          component: OrderPad,
          need_props: 'no'
        },
        {
          path: '/ltlfreight',
          component: Freight,
          need_props: 'no'
        },
        {
          path: '/orderupload',
          component: OrderUpload,
          need_props: 'no'
        },
        {
          path: '/allorders',
          component: AllOrders,
          need_props: 'no'
        },
        {
          path: '/directshipallorders',
          component: AllOrders,
          need_props: 'no'
        },
        {
          path: '/content/:pageId',
          component: WpTemplate,
          need_props: 'yes'
        },
        {
          path: '/OrderItemLevelDetails',
          component: OrderItemLevelDetails,
          need_props: 'no'
        },
        {
          path: '/DirectShipOrderDetails',
          component: DirectShipOderDetails,
          need_props: 'no'
        },
        {
          path: '/PromoItemDetails',
          component: PromoItemDetails,
          need_props: 'no'
        },
        {
          path: '/EditOrderItemLevelDetails/:orderNum',
          component: EditOrderItemLevelDetails,
          need_props: 'yes'
        },
        {
          path: '/search/:searchKey/:searchValue',
          component: Categories,
          need_props: 'yes'
        },
        {
          path: '/contentSearch/:searchValue',
          component: ContentSearch,
          need_props: 'yes'
        },
        {
          path: '/cart',
          component: Cart,
          need_props: 'no'
        },
        {
          path: '/checkout/:cartName',
          component: Checkout,
          need_props: 'yes'
        },
        {
          path: '/checkoutDirectShip/:cartName',
          component: CheckoutDirectShip,
          need_props: 'yes'
        },
        {
          path: '/checkoutPlanograms/:cartName',
          component: CheckoutPlanograms,
          need_props: 'yes'
        },
        {
          path: '/checkoutRA/:cartName',
          component: CheckoutRA,
          need_props: 'yes'
        },
        {
          path: '/checkoutAssortmentFlow/:cartName',
          component: CheckoutRA,
          need_props: 'yes'
        },
        {
          path: '/checkoutImpulsemerchandising/:cartName',
          component: CheckoutImpulsemerchandising,
          need_props: 'yes'
        },
        {
          path: '/checkoutCTB/:cartName',
          component: CheckoutRA,
          need_props: 'yes'
        },
        {
          path: "/fixtures",
          component: Fixtures,
          need_props: "yes",
        },
        {
          path: '/checkoutMonthlySpecials/:cartName',
          component: CheckoutImpulsemerchandising,
          need_props: 'yes'
        },
        {
          path: '/checkoutReunionCart/:cartName',
          component: CheckoutImpulsemerchandising,
          need_props: 'yes'
        },
        {
          path: '/checkoutRetailEvents/:cartName',
          component: CheckouRetailEvents,
          need_props: 'yes'
        },
        {
          path: '/orderConfirmation/:cartName',
          component: OrderConfirmation,
          need_props: 'yes'
        },
        {
          path: '/favorites',
          component: Favorites,
          need_props: 'no'
        },
        {
          path: '/favoriteVendors/',
          component: FavoriteVendors,
          need_props: 'no'
        },
        {
          path: '/momentum',
          component: Favorites,
          need_props: 'no'
        },
        {
          path: '/all-warehouses/:productId',
          component: AllWarehouses,
          need_props: 'yes'
        },
        {
          path: '/asktruevalue/:productId',
          component: AskTrueValue,
          need_props: 'yes'
        },
        {
          path: '/ctbAssortments/:deptName/',
          component: RetailAssortments,
          need_props: 'yes'
        },
        {
          path: '/ctbAssortments',
          component: RetailAssortmentsLinks,
          need_props: 'no'
        },
        {
          path: '/retailAssortments/:deptName/',
          component: RetailAssortments,
          need_props: 'yes'
        },
        {
          path: '/retailAssortmentsLinks',
          component: RetailAssortmentsLinks,
          need_props: 'no'
        },
        {
          path: '/reunionAssortment',
          component: ReunionAssortmentDeptPage,
          need_props: 'yes'
        },
        {
          path: '/reunionAssortments/:deptName/',
          component: RetailAssortments,
          need_props: 'yes'
        },
        {
          path: '/assortment/:number',
          component: AssortmentListing,
          need_props: 'yes'
        },
        {
          path: '/retail-events',
          component: DealsOfTheMonth,
          need_props: 'no'
        },
        {
          path: '/retail-events/:promoNbr',
          component: DOMProductListing,
          need_props: 'yes'
        },
        {
          path: '/preview-circular/:promoNbr',
          component: PreviewCircular,
          need_props: 'yes'
        },
        {
          path: '/impulse-merchandising',
          component: ImpulseMerchandising,
          need_props: 'no'
        },
        {
          path: '/impulse-merchandising/:imCatId',
          component: POG,
          need_props: 'yes'
        },
        {
          path: '/im-item-listing/:categoryId/:assortmentId',
          component: ImItemListing,
          need_props: 'yes'
        },
        {
          path: '/shipLaters',
          component: ShipLaters,
          need_props: 'no'
        },
        {
          path: '/helpAndTutorials',
          component: HelpAndTutorials,
          need_props: 'no'
        },
        {
          path: '/planograms/:deptName',
          component: Planograms,
          need_props: 'yes'
        },
        {
          path: '/planogramLinks',
          component: PlanogramLinks,
          need_props: 'no'
        },
        {
          path: '/planograms/:deptName/:catgName',
          component: Planograms,
          need_props: 'yes'
        },
        {
          path: '/reunionEvent',
          component: ReunionEvent,
          need_props: 'no'
        },
        {
          path: '/reunionSearchItems',
          component:ReunionItemListing,
          need_layout_and_auth:'yes',
          need_props:'no'
        },
        {
          path: '/ReunionApp',
          component: ReunionApp,
          need_props: 'no'
        },
        {
          path: '/reunionEventVendors/:eventId',
          component: ReunionEvent,
          need_props: 'yes'
        },
        {
          path: '/ReunionEventitemListingVendors/:eventId',
          component: ReunionEventitemListingVendors,
          need_props: 'yes'
        },
        {
          path: '/MonthlySpecialsItems/:eventId',
          component : MonthlySpecialsItemListing,
          need_props: 'yes'
        },
        {
          path: '/wowItems/:eventId',
          component : MonthlySpecialsItemListing,
          need_props: 'yes'
        },
        {
          path: '/reunionEventItems',
          component: ReunionEvent,
          need_props: 'yes'
        },
        {
          path: '/planogram/:planogramId',
          component: PlanogramItemListing,
          need_props: 'yes'
        },
        {
          path: '/planogram/:planogramId/:categoryName/:departmentName/:planogramSubName',
          component: PlanogramItemListing,
          need_props: 'yes'
        },
        {
          path: '/monthlySpecials',
          component: MonthlySpecials,
          need_props: 'yes'
        },
        {
          path: '/monthlyEventVendors/:eventId',
          component: MonthlySpecialEventVendors,
          need_props: 'yes'
        },
        {
          path: '/wholeSaleDeals',
          component: wholeSaleDeals,
          need_props: 'yes'
        },
        {
          path: '/flashSales',
          component: FlashSales,
          need_props: 'yes'
        },
        
        {
          path: '/cancelledItems',
          component: CancelledItems,
          need_props: 'no'
        },
        {
          path: '/editOrders',
          component: EditOrders,
          need_props: 'no'
        },
        {
          path: '/autoShip',
          component: PromoShip,
          need_props: 'no'
        },
        {
          path: '/fillRates',
          component: FillRates,
          need_props: 'no'
        },
        {
          path: '/finelineCatalog',
          component: FinelineCatalogLandingPage,
          need_props: 'no',
        },
        {
          path: '/finelineCatalog/build-catalog',
          component: FinelineIndex,
          need_props: 'no'
        },
        {
          path: '/finelineCatalog/flipbook-viewer',
          component: FlipbookViewer,
          need_props: 'yes',
        },
        {
          path: '/finelineCatalog/ad-configuration',
          component: FinelineCatalogAdConfiguration,
          need_props: false,
        },
        {
          path: '/discountCity',
          component: DiscountCity,
          need_props: 'no'
        },
        {
          path: '/discountCityItems/:eventId',
          component : DiscountCityItemListing,
          need_props: 'no'
        },
        {
          path: '/discountCityEventVendors/:eventId',
          component: DiscountCityEventVendors,
          need_props: 'no'
        },
        {
          path: '/wow',
          component: MonthlySpecials,
          need_props: 'yes'
        },        
        {
          path: '/wow/:eventId',
          component: MonthlySpecialEventVendors,
          need_props: 'yes'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:fromReunionHome',
          component: AllReunion,
          need_props: 'yes'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/vendors/:promoName',
          component: VendorListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/:promoName/vendor/:vendorId/:vendorName/palletListing',
          component: PalletListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/vendor/:vendorId/:vendorName/palletItemListing/:palletNumber/:promoName',
          component: PalletItemListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/:promoName/palletListing',
          component: PalletListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/dropShipPalletItems/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/:promoName/dropShipPalletListing',
          component: DropShipPalletListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/vendor/:vendorId/:vendorName/dropShipPalletItemListing/:palletNumber/:promoName/:palletId/:eventSupplierSelectionID',
          component: DropShipPalletItemListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/dropShipVendorsDC/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/vendors/:promoName',
          component: VendorListingDropShipDC,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/dropShipVendorItems/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/:promoName/vendor/:vendorId/:vendorName/dropShipPalletListing',
          component: DropShipPalletListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/eventName/:eventName/:relayNbr/:fromReunionHome/assortments',
          component: AssortmentsList,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/eventName/:eventName/:relayNbr/:fromReunionHome/assortments/:assortmentId/:assortmentMdlNbr',
          component: AssortmentItemListing,
          need_props: 'yes'
        },
        {
          path: '/reunionOrdering/items/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:promoName/:relayNbr/:relayYear/:fromReunionHome/vendor/',
          component: ItemListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/items/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:promoName/:relayNbr/:relayYear/:fromReunionHome/vendor/:vendorId/:vendorName',
          component: ItemListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/dropShipItems/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:promoName/:relayNbr/:relayYear/:fromReunionHome/vendor/',
          component: VendorDropShipItemListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/dropShipVendors/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:relayNbr/:relayYear/:fromReunionHome/vendors/:promoName',
          component: VendorDropShipVendorListing,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering/dropShipItems/:shoppingAreaName/:shoppingId/:flowType/events/:eventId/:promoName/:relayNbr/:relayYear/:fromReunionHome/vendor/:vendorId/:vendorName/:eventSupplierSelectionID',
          component: VendorDropShipItemListing,
          need_props: 'no'
        },
        {
          path: '/closeoutCorner',
          component: CloseoutCorner,
          need_props: 'no'
        },
        {
          path: '/reunionOrdering',
          component: ReunionOrdering,
          need_props: 'yes'
        },
        { path: '/corporateEbook',
          component: corporateEbook,
          need_props: 'no'
        },
        {
          path: '/corporateEbook/flipbook-viewer',
          component: FlipbookViewer,
          need_props: 'yes',
        },
        {
          path: '/admin/shoppingArea',
          component: ShoppingArea,
          need_props: 'no',
        },
        {
          path: '/admin/MenuOptions',
          component: MenuOptions,
          need_props: 'no',
        },
        {
          path: '/admin/promoLinking',
          component: PromoLinking,
          need_props: 'no',
        },
        {
          path: '/admin/limitQuantity/BargainBurners',
          component: LimitQuantity,
          need_props: 'no',
        },
        {
          path: '/admin/dropShip',
          component: DropShip,
          need_props: 'no',
        },
        {
          path: '/admin/cartreport',
          component: CartReport,
          need_props: 'no',
        },
        {
          path: '/admin/limitQuantity/Wow',
          component: LimitQuantity,
          need_props: 'no',
        },
        {
          path: '/admin/RARestrictions',
          component: RARestrictions,
          need_props: 'no',
        },
        {
          path: '/admin/CTBReopen',
          component: POGEnable,
          need_props: 'no',
        },
        {
          path: '/admin/dropShip',
          component: DropShip,
          need_props: 'no',
      },
      {
        path: '/CreditCardPaymentOption',
        component: CreditCardPaymentOption,
        need_props: 'no'

      },
      {
        path: '/retailAlerts',
        component: Retailer,
        need_props: 'no'

      },
       {
        path: '/appRedirect',
        component: BusinessTools,
        need_props: 'no'

      },
      {
        path: '/hfy',
        component: Home,
        need_props: 'yes'

      },
      {
          path: '',
          component: NotFound,
          need_props: 'no'
        }
      ]
    };
  }
  componentDidMount() {
    let prevUrl =
      window.location.pathname + window.location.hash + window.location.search;
      if(prevUrl.toLowerCase().includes('fieldconnect')) {
        window.location.replace(getfieldConnectUrl())
      }
  }
  componentWillReceiveProps(newProps){
    if(newProps.showLoader!==this.state.showProps){
      this.setState({showLoader:newProps.showLoader});
    }
  }
  getRenderRoute(component, props) {
    const {showLoader} = this.state;
    return (
      <AuthPage>
        <LayoutContainer
          isAuthenticated={this.props.isAuthenticated}
          {...props}
        >
          {component}
          {showLoader && <Loader active className="commonLoader"/>}
        </LayoutContainer>
      </AuthPage>
    );
  }

  render() {
    return (
      <>
      {!this.props.isAuthenticated && <AuthLoader /> }
       <Router history={history}>
        <ScrollToTop>
          <Switch>
            {this.state.list_of_pages.map((item, index) =>
              (
                <Route
                  key={index}
                  exact
                  path={item.path}
                  render={props =>
                    this.getRenderRoute(<item.component {...props} />, props)
                  }
                />
              )
            )}
          </Switch>
        </ScrollToTop>
      </Router>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.SessionReducer.isAuthenticated,
  showLoader : state.UtilityReducer.showLoader
});

export default connect(mapStateToProps)(RenderRoutes);
