import React, { Component } from 'react';
import { downloadExcel } from './GenericExcelExport/exportUtils';
import restUtils from '../../utils/restUtils';
import { utilities,shouldHideCostForPreference, shouldHideSUGG_RETAILForPreference } from '../../utils/utilities';
import { Loader, Popup, Icon } from 'semantic-ui-react';
import { assortmentLevels } from './constants';
import { orderNames } from '../Cart/constants';
import {REUNION_RETAIL_ASSORTMENTS, PLANOGRAM_ITEMS, FLASH_SALES, MONTHLY_SPECIALS, WOW, RETAIL_ASSORTMENTS, CTB_ASSORTMENTS } from '../Categories/constants';
import {getAllOrders, getAllDsOrders,getItemDetails, getWarehouseItems, getCloseOutItems, getExportItems, excelProductIdentifiers, getOrderStatusItemDetails,getHotDealsItems,getPlanogramListItems, getMomentumItems,getDirectShipItems,excelAssotmentIdentifiers} from '../common/GenericExcelExport/excelService';
import { deals, VARIABLEPRICING } from '../../../src/urlConstants';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import config from '../../config';
import { getAssortmentSavings, getAssortmentCreditValue } from '../../views/Cart/cartUtils'
 
export default class ExcelExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadExport: false
    };
    this.exportToCSV = this.exportToCSV.bind(this);
    this.showMessagesForRAmkt = this.showMessagesForRAmkt.bind(this)
    this.getRAMessage = this.getRAMessage.bind(this)
    this.showMsgsbasedonQty = this.showMsgsbasedonQty.bind(this)
    this.showOfferendDateMsg = this.showOfferendDateMsg.bind(this)
    this.raDate = this.raDate.bind(this)

  }
  componentDidUpdate(preProps, prevState, snapShot) {
    if(this.props.fileName === 'cartExport' && this.props?.multiStoreCart) {
      if(!preProps.order[0]?.isLoad && this.state.loadExport){
        this.exportToCSV(this.props,this.getRAMessage,this.raDate)
        this.setState({loadExport: false})
      }
    }
  }

   showMessagesForRAmkt = (product) => {
    const savings = utilities.PercentageSavings(product.savings);
    return (product.userQty > 1 ) 
    ? "save" + savings + "with $" + product.rebateAmount+ 
      "credit.Credit and dating will only apply to one assortment per Retail Assortment category"
    : product.userQty <= 1 
    ? "save" + savings+"with $"+ product.rebateAmount+"credit" 
    : "save" + savings+"with $"+ product.rebateAmount
    }

    raDate=(item)=>{
     return this.props.RAMarketFlag  ? this.showOfferendDateMsg(item)
            : item.expireddate ? 
              utilities.getDateFromTimeStamp(item.expireddate) : 'On Going'
    }

    showOfferendDateMsg = (product) => {
      return utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="Y" ? 'On Going'
              : utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="N" ? this.props.RAMarketDate
              : !utilities.isEmptyOrNullString(product.expireddate) && utilities.getDateFromTimeStamp(product.expireddate)
      }

    getRAMessage = (item) => {
      return (
          !utilities.isEmptyOrNullString(item.orderedModelNbr) ?                                   
            "Not Eligible for Credit, Previously Purchased" + item.orderedModelNbr
        : !utilities.isEmptyOrNullString(item.orderedDate) ?
            "Credit Redeemed" +item.orderedDate +' ('+ item.rebateAmount+')'
        :this.props.RAMarketFlag ? this.showMessagesForRAmkt(item)
        : this.showMsgsbasedonQty(item)
      )
    }

  showMsgsbasedonQty = (product) => {
      let savinCalc = parseInt(utilities.hyphenForZero(product && product.rebateAmount)) /  parseInt(utilities.emptyStringForZero(product.regCost));
      let rebateCredit = isNaN(savinCalc) ? 0 : parseFloat(savinCalc).toFixed(2);
      const  getSavings = utilities.PercentageSavings(rebateCredit);  
      let qtyMsg = '';
      if (product.userQty > 1) {
        if (product.deptID && product.deptID === 'X') {
          qtyMsg = "save" + getSavings + "with"+ product.rebateAmount+ "credit per bundle"
        } else {
          qtyMsg ="save" + getSavings + "with"+ product.rebateAmount+ "credit Credit and dating will only apply to one assortment per Retail Assortment category"
        }
      } else {
        if (product.deptID && product.deptID=== 'X') {
          qtyMsg = "save" + getSavings+ "with credit per bundle" 
        } else {
          qtyMsg =  "save" +getSavings+ "with" + product.rebateAmount+"credit"
        }
      }
      return qtyMsg;
    }

  exportToCSV = (props,getRAMessage,raDate) => {
    const file = props.fileName ? props.fileName === 'dom' ? 'Retail Event' : props.fileName  : 'Warehouse Products';
    let fileName = `${file}-${utilities.getCurrDate()}.xlsx`;
    const  hideTotalExtendCost = shouldHideCostForPreference(props.viewType,this.props.getprefer);
    const  hideSuggestedRetail = shouldHideSUGG_RETAILForPreference(props.viewType, props.getprefer);
    if (props.fileName === 'Closeout Items') {
      utilities.showToast("Exporting to Excel...");
      let allOrdersToDownload = [];
      let allOrdersData = {};
      getCloseOutItems(props.aliasSKURetailerGrpID, props.cartid, props.storeID, props.rdc, parseInt(props.totalItems), props.sortDir, props.sortBy, props.filterString, props.isShowAllWarehouse).then(response=>{
        response.map((details)=>{
          details.CLoseOutItemsList && details.CLoseOutItemsList.map((item)=>{
            allOrdersData = {
              'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
              'Item #': utilities.emptyForNull(parseInt(item?.Item_Number)),
              'Short Description': utilities.emptyForNull(item.Product_Name),
              'Long Description': config.showMediumDesp ?
              utilities.emptyForNull(item.Product_Title || item.Long_Description)
              : utilities.emptyForNull(item.Long_Description),
              'Model #': utilities.emptyForNull(item.Model),
              "UPC": utilities.emptyForNull(item.UpcNum),
              'Pack': `${item.S_Pack_Type ? item.S_Pack_Type : ''}${
                item.S_Pack_Qty ? item.S_Pack_Qty : ''
              }`,
              "Disc. Date": utilities.emptyForNull(item.discontinued_date),
            }
            if(props.viewType !== MEMBER_VIEW){
              if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
                allOrdersData = { ...allOrdersData,
                  'MC':utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    utilities.getCostForQtyPlpNew(item, item.cart_available),
                    props.getprefer
                  ),
                  'RC': utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    item.Regular_Cost,
                    props.getprefer
                  ),
                }
              } 
              if(props.getprefer === 'c2'){
                allOrdersData = { ...allOrdersData,
                  'SR': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.SuggestedRetail,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              } else if(props.getprefer !== 'c3'){
                allOrdersData = { ...allOrdersData,
                  'Suggested Retail': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.SuggestedRetail,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              };
            } else{
              allOrdersData = { ...allOrdersData,
              'Member Cost': utilities.getCostBasedOnnatvalue(
                props.viewType,
                utilities.getCostForQtyPlpNew(item, item.cart_available),
                props.getprefer
              ),
              'Member Cost Tier Indicator': item.Member_Cost_Tier ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(item, item.cart_available, true)): '',
              'Regular Cost': utilities.getCostBasedOnnatvalue(
                props.viewType,
                item.Regular_Cost,
                props.getprefer
              ),
              'Regular Cost Tier Indicator': item.tiered ? 'T2': '',
              'Suggested Retail': utilities.getCostBasedOnViewType(
                props.viewType,
                item.SuggestedRetail,
                'Suggested Retail',
                props.getprefer
              ),
              '% Off': utilities.percentageFormat(item.savings),
              }
            }
            allOrdersData = { ...allOrdersData,
              'Override Retail': utilities.emptyForNull(utilities.dollarForValue(item.cart_overrideretail)),
              'Quantity': utilities.emptyForNull(item.cart_available),
              "Max Quantity": utilities.emptyStringForZero(parseInt(item.maxQty)),
              'Available': props.isShowAllWarehouse ? '' : utilities.zeroForNull(parseInt(item?.Available)),
              'Stock Status': utilities.emptyForNull(item.whStockStatusMessage),              
              'Hub Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubIndictor(item),
              'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
              'Type (Referral/Sub/Alt)': utilities.emptyForNull(utilities.substituteItemExport(item?.alternateItem?.Label, item?.alternateItem?.Number)),
              'Referral/Sub/Alt Item #': utilities.emptyForNull(parseInt(item?.alternateItem?.Number)),
              'Restricted Product': utilities.itemRestricted(item?.Restrictions),
              'Restriction Message': utilities.itemRestrictionDetails(item?.Restrictions),
              'Assortment Item' : excelAssotmentIdentifiers(item),
              'Product Identifiers': excelProductIdentifiers(item),
              "Favorite Item": props.favorites.indexOf(parseInt(item.Item_Number)) > -1 ? 'Yes' : 'No',
              'Reason Code Description': utilities.emptyForNull(item.whStockStatusDiscMessage),
              'Category Name': utilities.emptyForNull(item.upcNum),
              'To Be Disc Date': utilities.emptyForNull(item.discontinued_date),
              'RDC #': utilities.emptyForNull(props.rdc),

              // 'Sub/Ref Model #': utilities.emptyForNull(item?.alternateItem?.Model),
              // 'Sub/Ref Description': utilities.emptyForNull(item?.alternateItem?.Description),
              // 'Sub/Ref Manufacturer': utilities.emptyForNull(item?.alternateItem?.Vendor),
              // 'Sub/Ref Pack': `${item?.alternateItem?.PackType ? item?.alternateItem?.PackType : ''}${
              //   item?.alternateItem?.PackQty ? item?.alternateItem?.PackQty : ''
              //   }`,
              // 'Sub/Ref Suggested Retail': utilities.emptyForNull(utilities.getCostBasedOnViewType(
              //   props.viewType,
              //   item?.alternateItem?.RetailCost
              // )),
              // 'Sub/Ref Member Cost': utilities.emptyForNull(utilities.getCostBasedOnViewType(
              //   props.viewType,
              //   item?.alternateItem?.MemberCost
              // )),
            };
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
              allOrdersData = {...allOrdersData, ...{'Alternate SKU#': item.Xref_ItemNbr !== '~' ? item.Xref_ItemNbr : ''}};
             }
            allOrdersToDownload.push(allOrdersData);
            return details;
          })
        })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      })
    }
    else if (props.fileName === 'PromoListing' || props.fileName === 'AutoShip') {
      let productsData=[];
      let promoListing={};
      utilities.showToast("Exporting to Excel...");
      promoListing = props?.itemsData?.map((orders) => {
        orders = orders?.orderList?.map((product, index) => {
          promoListing = {
          'Order Created Date':  utilities.emptyForNull(product.invoiceDateString),
          'Invoice #': utilities.emptyForNull(product.invoiceNbr),
          'PO #': utilities.emptyForNull(product.poNbr),
          'Promo #':utilities.emptyForNull(product.promoName ? product.promoName : product.relay_nbr),
          'RDC #': utilities.emptyForNull(product.rdcNbr),
          'Expected Release Week':utilities.emptyForNull(product.shipDateString),
          'Status':utilities.emptyForNull(product.displayedStatus),
        };
        productsData.push(promoListing);
      })
    });
    downloadExcel(productsData, fileName);
    }
    else if (props.fileName === 'PromoItemDetails') {
      let allOrdersToDownload = [];
      let row = {};
      utilities.showToast("Exporting to Excel...");
      const totalItems = this.props.orderDetails?.numberOfItems ? this.props.orderDetails?.numberOfItems : this.props.orderDetails?.itemCount
      getItemDetails(this.props.sortParam, this.props.sortDirection, this.props.orderDetails, this.props.storeId, this.props.userId, this.props.page, this.props.aliasSKURetailerGrpID, totalItems, '', props.fileName ).then(response=>{
        response.map((details) => {  
          details.promoShipItemList.map((product) => {    
            row = {...row, ...{'Item#': utilities.emptyForNull(parseInt(product?.itemNbr))}};
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
              row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(product.aliasSku)}};
             }
             row = {...row, ...{
            'UPC #':  utilities.emptyForNull(product.upc),
            'ADV Code #': utilities.emptyForNull(product.advCode),
            'Circular Page #': utilities.emptyForNull(product.circularPage),
            'Model #':utilities.emptyForNull(product.model),
            'Short Description':utilities.emptyForNull(product.shortDesc + "/n" + product.vendorName),
            'Program':utilities.emptyForNull(product.noteProgram),
            'Weight': utilities.emptyForNull(product.weight),
            'Pack': utilities.emptyForNull(product.pack),
          }};
          if(hideTotalExtendCost){
            row[utilities.getLabelsByType(props.viewType,"COST", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType,product.cost,utilities.getPreferenceHideMask(props.viewTypepromo));
            row[utilities.getLabelsByType(props.viewType,"PROMO_COST", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType,product.promoCost,  utilities.getPreferenceHideMask(props.viewTypepromo));
            row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getPrefer)] = this.props.orderDetails?.status.toLowerCase() === 'cancelled' ? '' : utilities.getCostBasedOnnatvalue(props.viewType,product.extCost,utilities.getPreferenceHideMask(props.viewTypepromo));
            row[utilities.getLabelsByType(props.viewType,"PROMO_RETAIL", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType,product.promoRetail,  utilities.getPreferenceHideMask(props.viewTypepromo));
          } else {
            row = { ...row }
          }
          row = {...row, ...{
            'Override retail':utilities.dollarForValue(utilities.emptyStringForZero(product.overrideRetail)),
            'Qty': utilities.emptyForNull(parseInt(product.qty)),
            'Status': utilities.emptyForNull(product.statusVal),
          }};
          
          allOrdersToDownload.push(row);
            return product;
          })
        })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      })
    }
    else if (props.fileName === deals) {
      let productsData = [];
      let row = {};
      utilities.showToast("Exporting to Excel...");     
      getHotDealsItems(props.aliasSKURetailerGrpID, props.cartid, props.storeID, props.rdc, props.totalItems, props.sortDir, props.sortBy).then(response => {
        response.map((details) => {
          details.HotDeals.map(function (item) {
            row = {
              ...row, ...{
                'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
                'Item#': utilities.emptyForNull(parseInt(item.Item_Number)),
                'Short Description': utilities.emptyForNull(item.Product_Title),
                'Long Description': config.showMediumDesp ?
                  utilities.emptyForNull((item.Product_Title || item.Long_Description))
                  : utilities.emptyForNull(item.Long_Description),
                'Model #': utilities.emptyForNull(item.Model),
                'Pack': `${item.packType ? item.packType : ''}${item.packQty ? item.packQty : ''
                  }`,
                'Disc. Date': item.tbdDate ? utilities.getDateFromTimeStamp(
                  new Date(item.tbdDate)
                ) : '',
              }
            };
            if (props.viewType !== MEMBER_VIEW) {
              if (props.getprefer !== 'c3' && props.getprefer !== 'c4') {
                row = {
                  ...row,
                  'MC': utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    utilities.getCostForQtyPlpNew(item, item.cart_available),
                    props.getprefer
                  ),
                  'RC': utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    item.Regular_Cost,
                    props.getprefer
                  ),
                }
              }
              if (props.getprefer === 'c2') {
                row = {
                  ...row,
                  'SR': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.srCost,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              } else if (props.getprefer !== 'c3') {
                row = {
                  ...row,
                  'Suggested Retail': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.srCost,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              };
            } else {
              row = {
                ...row,
                '% Off': utilities.percentageFormat(item.Savings),
                'Member Cost': utilities.getCostBasedOnnatvalue(
                  props.viewType,
                  utilities.getCostForQtyPlpNew(item, item.cart_available),
                  props.getprefer
                ),
                'Member Cost Tier Indicator': item.Member_Cost_Tier ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(item, item.cart_available, true)) : '',
                'Regular Cost': utilities.getCostBasedOnnatvalue(
                  props.viewType,
                  item.Regular_Cost,
                  props.getprefer
                ),
                'Regular Cost Tier Indicator': item.tiered ? 'T2' : '',
                'Suggested Retail': utilities.getCostBasedOnViewType(
                  props.viewType,
                  item.srCost,
                  'Suggested Retail',
                  props.getprefer
                ),
              }

            }
            row = {
              ...row,
              'Override Retail': utilities.emptyForNull(utilities.dollarForValue(item.cart_overrideretail)),
              'Quantity': utilities.emptyForNull(item.cart_available),
              'Available': props.isShowAllWarehouse ? '' : utilities.zeroForNull(item.available),
              'Hub Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubIndictor(item),
              'Hub & Spoke Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubAndSpokeIndictor(item),
              'Assortment Item' : excelAssotmentIdentifiers(item),
              'Product Identifiers': excelProductIdentifiers(item),
              'Favorite Item': props.favorites.indexOf(parseInt(item.Item_Number)) > -1 ? 'Yes' : 'No',
              'RDC #': utilities.emptyForNull(item.rdcNum),
            };
            if (!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)) {
              row = { ...row, ...{ 'Alternate SKU': utilities.emptyForNull(item.aliasSku) } };
            }
            productsData.push(row);
            return item;
          });
          downloadExcel(productsData, fileName);
          this.setState({ loading: false });
        });
      }).catch(error => {
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      });
    }
    else if (props.fileName === 'orderConfirmations') {
      utilities.showToast("Exporting to Excel...");
      let productFilename = props.order[0].DisplayName;
      let allOrdersToDownload = [];
      let fileType = `${productFilename}-${utilities.getCurrDate()}.xlsx`;
      props.order.map( function(multiStoreItem, index) {
        let row1;
                row1 = {...row1,...{'Store #':multiStoreItem.storeId,
                    'Order Date':utilities.getDateFromTimeStamp(multiStoreItem.lastModified)
                  }
                };
                if(!utilities.isEmptyOrNullString(props.poNum[multiStoreItem.storeId])){
                  row1 = {...row1,...{'PO #':props.poNum[multiStoreItem.storeId]}};
                } 
            
                row1 = {...row1,...{
                      'Source':'True Value Company',
                      'Ship To':props.cartProducts[index]?.[0]?.shipTo ?props.cartProducts[index][0].shipTo : multiStoreItem.shipTo,
                      'Shipping Method':props.cartProducts[index]?.[0]?.shipByOrder ?props.cartProducts[index][0].shipByOrder :'Regular Delivery',
                }};
                if(hideTotalExtendCost){
                  row1[utilities.getLabelsByType(props.viewType,"ORDER_TOTAL", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType,multiStoreItem.totalCost,props.getprefer);
                } else {
                  row1 = {...row1}
                }
                             
                if(!utilities.isEmptyOrNullString(props.cartProducts[index]?.[0]?.subTotal)){
                  if(hideTotalExtendCost){
                    row1[utilities.getLabelsByType(props.viewType,"SUB_TOTAL", props.getPrefer)] = props.cartProducts[index]?.[0]?.subTotal ? utilities.getCostBasedOnnatvalue(props.viewType,props.cartProducts[index]?.[0]?.subTotal,props.getprefer) : '';
                  } else {
                    row1 = {...row1}
                  }
                } 
        return(
        multiStoreItem.items.map(function (item) {
        const tieredCost = utilities.getCostForQtyPlpNew(item, parseFloat(item.itemQty ? item.itemQty : item.Qty && item.Qty !== 0 ? item.Qty : item.userQty));
        const extprice = utilities.emptyForNull(tieredCost && tieredCost * (item.itemQty ? item.itemQty : item.Qty && item.Qty !== 0 ? item.Qty : item.userQty));
        const domExtprice = utilities.emptyForNull((item.promoCost ? item.promoCost: 0)*item.itemQty);
        const raSavings = utilities.PercentageSavings(item.savings)
        const isDOM = props.order[0].TypeId === orderNames["DOM"].infoType;
        if(props.order[0].TypeId === 33) {
          let row= {
            'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
            'PO#':utilities.emptyForNull( props.poNum ),
            'Retailer Reference PO#': props?.dsRetailerPo ? props?.dsRetailerPo : '-',
            'Item#':item.item_nbr,
            'Item Indicator':item?.IsStocked ? '-' : 'NS',
            'Item Description':utilities.emptyForNull( item.ShortDescription ),
            'Vendor Name':utilities.emptyForNull( multiStoreItem.DisplayName ),
            'Pack': `${item.s_pack_qty ? item.s_pack_qty : ''  }`,
            'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
            'Added By':  item.userName ? item.userName : '',
            'UPC#': utilities.emptyForNull(item.Upc),
            'Model#': utilities.emptyForNull(item.Model),
            'Order Date': utilities.getDateFromTimeStamp1(),
            'Source': 'True Value Company',
            'Shipping Method': 'Direct Ship',
            'Ship To': props?.shipTo ? ( `${props.shipTo.addressLine1} ${props.shipTo.addressLine2},${props.shipTo.City},${props.shipTo.stateCode},${props.shipTo.postalCode}`):'',
            'Cost':item.DsCost <=0 ? '-' : utilities.getCostBasedOnViewType(
              props.viewType,
              item.DsCost
            ),
            'Sugg. Retail': utilities.getCostBasedOnViewType(
              props.viewType,
              item.SrpCost
            ),
            'Quantity': utilities.emptyForNull(parseInt(item?.itemQty)),
            'Order Total': utilities.getCostBasedOnViewType(
              props.viewType,
             multiStoreItem.totalCost
            ),
            'Ext Cost': item.DsCost <=0 ? '-' : utilities.getCostBasedOnViewType(
              props.viewType,
              item.DsCost * item.itemQty
            ),
            }
          allOrdersToDownload.push(row);
          return item;
        }
        else if (props.order[0].DisplayName === 'Retail Assortments') {
          let row= {...row1,
            'Department Name': utilities.emptyForNull(item.deptName),
            'Model#': utilities.emptyForNull(item.model),
            'Item Description': utilities.emptyForNull(item.product_title || item.description).trim(),}
          if(props.viewType === MEMBER_VIEW){
            row= { ...row, ...{
              'Savings': getAssortmentSavings(item, multiStoreItem.items),
            }}
          }
          row= { ...row, ...{
            'Delivery':  utilities.emptyForNull(item.month),
            'Qty': utilities.emptyForNull(parseInt(item?.userQty))}}

          if(hideTotalExtendCost){
            row[utilities.getLabelsByType(props.viewType,"CREDIT_VALUE", props.getPrefer)] = getAssortmentCreditValue(item, multiStoreItem.items, props.viewType, props.getprefer);
            row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getPrefer)] = utilities.isBinLabel(item?.userQty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, item.cost * item.userQty, props.getprefer);
          } else {
            row = {...row}
          }
            row = {...row,...{
            'Status': utilities.emptyForNull(props.status || 'Success')}
          };
          allOrdersToDownload.push(row);
          return item;
        }
        else if (props.order[0].DisplayName === 'Customized True Blue Assortments' || props.order[0].DisplayName === 'CTB Assortments') {
          let row= { 
            'Store Id': utilities.emptyForNull(multiStoreItem?.storeId),
            'Type': "POG",
            'CTB Free/Non Free': '-',           
            'Department Name': utilities.emptyForNull(item.deptName),
            'Description': utilities.emptyForNull(item.product_title || item.description),
            'POG': utilities.emptyForNull(item.ctb_pog_nbr),
            'Savings':utilities.hyphenForZeroPercentage(item.savings),
            'Markdown Credit':  utilities.dollarForValue(item.creditValue),
            'POG Selection': item.isMissingSelected? "Missing": 'All',
            'Ship Month':  utilities.emptyForNull(item.delivery),
            'Ext Cost': utilities.getCostBasedOnnatvalue('Member View', item.cost * item.userQty, props.getprefer),         
            'Status': utilities.emptyForNull(props.status === 'Submitted' ? 'Success' : props.status  || 'Success'),
            'SKU': "-",
            'Qty': 1,
          };
          allOrdersToDownload.push(row);
          return item;
        }
        else if(props.order[0].DisplayName === 'Merchandising Specials'){
          let isWow = item.isReqFromWow?'wow':props.order.DisplayName
          fileType = `${isWow}-${utilities.getCurrDate()}.xlsx`;
          let row = {};
          row = {...row,...row1};
         row = {...row, ...{'Item #': utilities.emptyForNull(item.item_nbr)}};
          if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
           row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
          }
         row = {...row, ...{'UPC #': utilities.emptyForNull(item.upcNum),
            'Model#': utilities.emptyForNull(item.model),
            'Item Description': utilities.emptyForNull(item.product_title || item.description).trim() +'  |  '+utilities.emptyForNull(item.vendorName) ,
            'pack':  utilities.emptyForNull(item.s_pack_type + item.s_pack_qty),
            'Cost': utilities.getCostBasedOnnatvalue('Member View', item.promoCost, props.getprefer),
            'Override Retail':utilities.dollarForValue(item.overrideRetail),
            'Ext Cost':utilities.getCostBasedOnnatvalue('Member View', item.promoCost * item.itemQty, props.getprefer),
            'Quantity 1': utilities.emptyForNull(parseInt(item?.userQty)),
            'Quantity 2': utilities.emptyForNull(parseInt(item?.userQty2)),     
            'Quantity 3': utilities.emptyForNull(parseInt(item?.userQty3)),
            'Quantity 4': utilities.emptyForNull(parseInt(item?.userQty4)),
          }};
           allOrdersToDownload.push(row);
           return item;
        }
        else {         
          let row = {};
          row = {...row,...row1};
         row = {...row, ...{'Item #': utilities.emptyForNull(parseInt(item?.item_nbr))}};
          if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
           row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
          }
         row = {...row, ...{'UPC #': utilities.emptyForNull(item.upcNum),
            'Model#': utilities.emptyForNull(item.model),
            'Description': utilities.emptyForNull(item.product_title || item.description).trim() + '  |  ' + utilities.emptyForNull(item.vendorName) ,
            'Rdc': utilities.emptyForNull(props.order[0].cartType ==='Planogram' ? item.rdcNum : `${utilities.rdcBasedonCentralship(item.rdctype, item.CsRdc, item.rdcNum)}${item && item.primaryWarehouseType === 'S' && item.rdctype === 'H' ? ' HUB': ''}`),
           }
          }

            if(props.viewType === MEMBER_VIEW){
              if(productFilename === 'Monthly Specials'){
                row = {...row,...{'Event Cost': utilities.isBinLabel(item?.userQty) ? '' :utilities.getCostBasedOnnatvalue( props.viewType, item.promoCost, props.getprefer)}}
              } else {
                row = {...row,...{'Cost': utilities.isBinLabel(item?.userQty) ? '' :utilities.getCostBasedOnnatvalue( props?.order[0]?.TypeId === 1 ? props.viewType : 'Member View', isDOM ? item.promoCost : tieredCost, props.getprefer)}}
              }
            }
            else {
              if(props.getprefer === "c3" || props.getprefer === "c4"){
                row = {...row}
              }
              else{
                if(productFilename === 'Monthly Specials'){
                  row = {...row,...{'EVC': utilities.getCostBasedOnnatvalue( props.viewType, item.promoCost, props.getprefer)}}
                } else {
                  row = {...row,...{'MC': utilities.getCostBasedOnnatvalue( props.viewType , isDOM ? item.promoCost : tieredCost, props.getprefer)}}
                }
            }
            }
            if(props.viewType === MEMBER_VIEW && productFilename !== 'Monthly Specials'){
              row = {...row, ...{
                'Tier Indicator': item.Member_Cost_Tier ? utilities.getTierLevelForQtyExcel(item, item.itemQty, '', props.viewType, props.getprefer): '',}}
            }
        if(props?.order[0]?.TypeId === 1){
          if(props.viewType !== MEMBER_VIEW && props.getprefer === 'c2'){
            row = {...row, ...{
              'SR': utilities.getCostBasedOnViewType(
                props.viewType,
                item.srCost,
                'Suggested Retail',
                props.getprefer
              ),
            }}
          } else if(props.viewType !== MEMBER_VIEW && props.getprefer === 'c3'){
            row = {...row}
          } else {
            row = {...row, ...{
              'Suggested Retail': utilities.getCostBasedOnViewType(
                props.viewType,
                item.srCost,
                'Suggested Retail',
                props.getprefer
              ),
            }}
          }
        }
        row = {...row, ...{
            'Override Retail':utilities.dollarForValue(item.overrideRetail),
            'Qty': utilities.emptyForNull(parseInt(item?.userQty))}}
            if(hideTotalExtendCost){
              row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getPrefer)] = utilities.isBinLabel(item?.userQty) ? '' :utilities.getCostBasedOnnatvalue(props.viewType, (isDOM ||productFilename === 'Monthly Specials') ? domExtprice : extprice, props.getprefer);
            } else {
              row = { ...row }
            }
            row = { ...row,...{
            'Status': utilities.emptyForNull(props.status === 'Submitted' ? 'Submitted' : props.status  || 'Success'),
          }};
          allOrdersToDownload.push(row);
          return item;
        }
      })
        )
    });
    if (this.props?.isExportFixtures) {
      const orderedFixtures =
        this.props?.fixtures?.filter(
          (f) =>
            this.props?.selectedFixtureIds?.includes(String(f?.id)) ||
            !f?.needed_prompt
        ) || [];
        if (orderedFixtures.length > 0) {
          orderedFixtures.map((fixture) => {
            allOrdersToDownload.push({
              'Store Id': utilities.emptyForNull(props.order[0].storeId),
              'Department Name': '-',
              'Type': 'Fixture',
              'CTB Free/Non Free': fixture?.ctbFree ? 'Free' : 'Non Free',
              'POG': fixture.pogNumber,
              'Description': fixture.fixtureName,
              'Savings': '-',
              'Markdown Credit': '-',
              'POG Selection': '-',
              'Ship Month': '-',
              'Ext Cost': '-',
              'Status': '-',
              'SKU': fixture.sku,
              'Qty': fixture.qty,
            });
          });
          }
          }
          if(this.props?.isExportLimitless){
            const orderedLimitless = this.props.limitlessPlanogram?.filter((f) => f?.IsLimitless === "Y") || []
            if (orderedLimitless.length > 0) {
              orderedLimitless.map((fixture) => {
                const selectDisplay  = this.props.selectedLimitlessId?.filter( x => x.assmtNbr === fixture?.number);
                const displayvalues = this.props.isDisplayItemLimitless?.filter(x => x.DisplayItemId == selectDisplay[0]?.displayItemsId )
  
                allOrdersToDownload.push({
                  'Store Id': utilities.emptyForNull(props.order[0].storeId),
                  'Department Name': fixture.deptName,
                  'Type': 'Limitless Planogram',
                  'CTB Free/Non Free': '-',
                  'POG': fixture.ctb_pog_nbr,
                  'Description': fixture.description,
                  'Savings': '-',
                  'Markdown Credit': '-',
                  'POG Selection': '-',
                  'Ship Month': '-',
                  'Ext Cost': '-',
                  'Status': '-',
                  'SKU': '-',
                  'Qty': '-',
                  'Selected Display Items Details' : displayvalues[0]?.Description
                });
              });
              }
        
          }
      if(props.order[0].TypeId === 33) {
        downloadExcel(allOrdersToDownload, `DirectShip_${props.poNum}__${utilities.getCurrDate()}.xlsx`);
      }
      else {
        downloadExcel(allOrdersToDownload, fileType);
      }
      
    }
    else if (props.fileName === 'cartExport') {
      utilities.showToast("Exporting to Excel...");
      let productFilename = props.order[0].DisplayName
      let allOrdersToDownload = [];
      let fileType = `${productFilename}-${utilities.getCurrDate()}.xlsx`;
      const  hideTotalExtendCostCart = shouldHideCostForPreference(props.viewType,this.props.getprefer.customerView);
      props.order.map( function(multiStoreItem) {
        return(
        multiStoreItem.items.map(function (item) {
          let maxQty = {}
          if(item.maxQtyItemRestriction > 0) {
            maxQty = {
              'Max Qty' : utilities.emptyForNull(parseInt(item?.maxQtyItemRestriction))
            }
          }
        const tieredCost = props?.order[0]?.cartInfoName == 'PROMO' ? item.promoCost : utilities.getCostForQtyPlpNew(item, parseFloat(item.itemQty ? item.itemQty : item.Qty && item.Qty !== 0 ? item.Qty : item.userQty));
        const extprice = utilities.emptyForNull(tieredCost && tieredCost * (item.itemQty ? item.itemQty : item.Qty && item.Qty !== 0 ? item.Qty : item.userQty));
        const raSavings = utilities.PercentageSavings(item.savings)

        let findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        const fieldEnum = [
          item.userQty,
          item.userQty2,
          item.userQty3,
          item.userQty4,
        ];
        let monthlyExtprice = findTotal(fieldEnum) * (item.promoCost ? item.promoCost : 0);
        if(props.order[0].TypeId === 33) {
          let row= {
            'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
            'Item#':item.item_nbr,
            'Item Indicator':item?.IsStocked ? '-' : 'NS',
            'Item Description':utilities.emptyForNull( item.ShortDescription ),
            'Vendor Name':utilities.emptyForNull( multiStoreItem.DisplayName ),
            'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
            'Store Name': utilities.emptyForNull(utilities.getStoreName(props.multiStoreDetails, multiStoreItem.userId)),
            'Added By':  item.userName ? item.userName : '',
            'UPC#': utilities.emptyForNull(item.Upc),
            'Model#': utilities.emptyForNull(item.Model),
            'pack':  utilities.emptyForNull(item.s_pack_qty),
            'Cost': item.DsCost <= 0 ? '-' :utilities.getCostBasedOnViewType(
              props.viewType,
              item.DsCost
            ),
            'Sugg. Retail': utilities.getCostBasedOnViewType(
              props.viewType,
              item.SrpCost
            ),
            'Quantity': utilities.emptyForNull(parseInt(item?.itemQty)),
            'Ext Cost': item.DsCost <=0 ? '-' : utilities.getCostBasedOnViewType(
              props.viewType,
              item.DsCost * item.itemQty
            ),
            }
          allOrdersToDownload.push(row);
          return item;
        }
        else if (props.order[0].DisplayName === 'Retail Assortments') {
          let row= {
            'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
            'Cart Description':utilities.emptyForNull( multiStoreItem.DisplayName ),
            'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
            'Store Name': utilities.emptyForNull(utilities.getStoreName(props.multiStoreDetails, multiStoreItem.userId)),
            'Added By':  item.userName ? item.userName : '',
            'Department Name': utilities.emptyForNull(item.deptName),
            'Model#': utilities.emptyForNull(item.model),
            'Item Description': utilities.emptyForNull(item.product_title || item.description),
            'Manufacturer': utilities.emptyForNull(item.vendorName),}
          if(props.viewType === MEMBER_VIEW){
            row= { ...row, ...{
              'Savings': getAssortmentSavings(item, multiStoreItem.items),
            }}
          }
          row= { ...row, ...{
            'Offer End Date': raDate(item),
            'Delivery':  utilities.emptyForNull(item.delivery),
            'Qty': utilities.emptyForNull(parseInt(item.userQty)),
          }}
          if(props.viewType !== MEMBER_VIEW){
            if(hideTotalExtendCost){
              row[utilities.getLabelsByType(props.viewType,"CREDIT_VALUE", props.getPrefer)] = getAssortmentCreditValue(item, multiStoreItem.items, props.viewType, props.getprefer);
              row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType, item.cost * item.userQty, props.getprefer);
            } else {
              row = {...row}
            }
          } else {
            row= { ...row, ...{
              'Credit Value': getAssortmentCreditValue(item, multiStoreItem.items, props.viewType, props.getprefer),
              'Ext Cost': utilities.getCostBasedOnnatvalue(props.viewType, item.cost * item.userQty, props.getprefer),
              'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
            }};
          }
          row= { ...row, ...{
            'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),            
            'Error Message': utilities.emptyForNull(item.message),
          }};
          allOrdersToDownload.push(row);
          return item;
        }
        
        else if (props.order[0].DisplayName === 'CTB Assortments' || props.order[0].DisplayName === 'Customized True Blue Assortments') {
          let row= {
            'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
            'Cart Description':utilities.emptyForNull( multiStoreItem.DisplayName ),
            'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
            'Store Name': utilities.emptyForNull(utilities.getStoreName(props.multiStoreDetails, multiStoreItem.userId)),
            'Added By':  item.userName ? item.userName : '',
            'Department Name': utilities.emptyForNull(item.deptName),
            'POG Description': utilities.emptyForNull(item.product_title || item.description),
            'POG': utilities.emptyForNull(item.ctb_pog_nbr),
            'Savings':utilities.hyphenForZeroPercentage(item.savings),
            'Markdown Credit':  utilities.dollarForValue(item.creditValue),
            'POG Selection': item.isMissingSelected? "Missing": 'All',
            'Ship Month':  utilities.emptyForNull(item.delivery),
            'Ext Cost': utilities.getCostBasedOnnatvalue('Member View', item.cost * item.userQty, props.getprefer),         
            'Error Message': utilities.emptyForNull(item.message),
          };
          allOrdersToDownload.push(row);
          return item;
        }
        else if(props.order[0].DisplayName === 'Merchandising Specials'){
          let isWow = item.isReqFromWow?'wow':props.order.DisplayName
          fileType = `${isWow}-${utilities.getCurrDate()}.xlsx`;
          let row = {
          'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
          'Cart Description': utilities.emptyForNull(multiStoreItem.DisplayName),
          'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
          'Store Name': utilities.emptyForNull(utilities.getStoreName(props.multiStoreDetails, multiStoreItem.userId)),
          'Added By':  item.userName ? item.userName : '',};
         row = {...row, ...{'Item #': utilities.emptyForNull(item.item_nbr)}};
          if(utilities.isAliasSkuRequired(props.multiStoreDetails)){
           row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
          }
         row = {...row, ...{'UPC #': utilities.emptyForNull(item.upcNum)?.toString(),
            'Model#': utilities.emptyForNull(item.model),
            'Item Description': utilities.emptyForNull(item.product_title || item.description),
            'Manufacturer': utilities.emptyForNull(item.vendorName),
            'pack':  utilities.emptyForNull(item.s_pack_type + item.s_pack_qty),
            'Cost': utilities.isBinLabel(item?.userQty) ? '':utilities.getCostBasedOnnatvalue('Member View', item.promoCost, props.getprefer),
            'Tier Indicator': item.tiered ? `T${utilities.getTierLevelForQty(item, item?.userQty)}`: '',
            'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
            'Max Qty': item.maxQtyItemRestriction > 0 ? parseInt(item.maxQtyItemRestriction): '',
            'Override Retail':utilities.dollarForValue(item.overrideRetail),
            'Ext Cost':utilities.isBinLabel(item.userQty) ? '' :utilities.getCostBasedOnnatvalue('Member View', item.promoCost * item.itemQty, props.getprefer),
            'Error Message': utilities.emptyForNull(item.message),
            'Quantity 1': utilities.emptyForNull(parseInt(item?.userQty)),
            'Quantity 2': utilities.emptyForNull(parseInt(item?.userQty2)),     
            'Quantity 3': utilities.emptyForNull(parseInt(item?.userQty3)),
            'Quantity 4': utilities.emptyForNull(parseInt(item?.userQty4)),
          }};
           allOrdersToDownload.push(row);
           return item;
        }
        else if(props.order[0].DisplayName === 'Monthly Specials'){
          let row = {
          'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
          'Cart Description':utilities.emptyForNull( multiStoreItem.DisplayName),
          'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
          'Store Name': utilities.emptyForNull(utilities.getStoreName(props.multiStoreDetails, multiStoreItem.userId)),
          'Added By':  utilities.emptyForNull(item.userName),};
         row = {...row, ...{'Item #': utilities.emptyForNull(item.item_nbr)}};
         if(utilities.isAliasSkuRequired(props.multiStoreDetails)){
           row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
          }
         row = {...row, ...{'UPC #': utilities.emptyForNull(item.upcNum)?.toString(),
            'Model#': utilities.emptyForNull(item.model),
            'Description': utilities.emptyForNull(item.product_title || item.description),
            'Vendor Name': utilities.emptyForNull(item.vendorName),
            'Favorite Item': props.favorites && item.item_nbr && props.favorites.indexOf(parseInt(item.item_nbr)) > -1 ? 'Yes' : 'No',
            'Restricted Item': utilities.itemRestrictedCart(item.Restricted),
            'Pack':  utilities.emptyForNull(item.s_pack_type + item.s_pack_qty),
            'Ship Date 1': utilities.emptyForNull(item.ShipDate1),
            'Ship Date 1 Qty': utilities.emptyForNull(item.userQty),
            'Ship Date 2': utilities.emptyForNull(item.ShipDate2),
            'Ship Date 2 Qty': utilities.emptyForNull(item.userQty2),
            'Ship Date 3': utilities.emptyForNull(item.ShipDate3),
            'Ship Date 3 Qty': utilities.emptyForNull(item.userQty3),
            'Ship Date 4': utilities.emptyForNull(item.ShipDate4),
            'Ship Date 4 Qty': utilities.emptyForNull(item.userQty4),
            'Max Qty': item.maxQtyItemRestriction > 0 ? parseInt(item?.maxQtyItemRestriction): '',
          }};
          if(props.viewType !== MEMBER_VIEW){
            if(hideTotalExtendCost){
              row[utilities.getLabelsByType(props.viewType,"EVENT_COST", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType, item.promoCost, props.getprefer);
              row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getPrefer)] = utilities.isBinLabel(item.userQty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, monthlyExtprice, props.getprefer);
            } else {
              row = {...row}
            }
          } else {
            row= { ...row, ...{
              'Event Cost': utilities.getCostBasedOnnatvalue(props.viewType, item.promoCost, props.getprefer),
              'Ext Cost':utilities.isBinLabel(item.userQty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, monthlyExtprice, props.getprefer),            
            }};
          }
          row= { ...row, ...{
            'Override Retail':utilities.dollarForValue(item.overrideRetail),
            'Error Message': utilities.emptyForNull(item.message),
          }};
          allOrdersToDownload.push(row);
          return item;
        }
        else {
          let row = {
          'Cart Name': `${multiStoreItem.cartName}-${multiStoreItem.storeId == props.storeId ? multiStoreItem.cartId : multiStoreItem.parentId}`,
          'Cart Description':utilities.emptyForNull( multiStoreItem.DisplayName),
          'Store Id': utilities.emptyForNull(multiStoreItem.storeId),
          'Store Name': utilities.emptyForNull(utilities.getStoreName(props.multiStoreDetails, multiStoreItem.userId)),
          'Added By':  utilities.emptyForNull(item.userName),};
         row = {...row, ...{'Item #': utilities.emptyForNull(item.item_nbr)}};
         if(utilities.isAliasSkuRequired(props.multiStoreDetails)){
           row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
          }
         row = {...row, ...{'UPC #': utilities.emptyForNull(item.upcNum)?.toString(),
            'Model#': utilities.emptyForNull(item.model),
            'Description': utilities.emptyForNull(item.product_title || item.description),
            'Vendor Name': utilities.emptyForNull(item.vendorName),
            'Favorite Item': props.favorites && item.item_nbr && props.favorites.indexOf(parseInt(item.item_nbr)) > -1 ? 'Yes' : 'No',
            'Restricted Item': utilities.itemRestrictedCart(item.Restricted),
            'RDC': utilities.emptyForNull(props?.isPlanogram ? item.rdcNum : utilities.rdcBasedonCentralship(item.rdctype, item.CsRdc, item.rdcNum)),
            'Hub Indicator': utilities.excelHubIndictor(item),
            'Pack':  utilities.emptyForNull(item.s_pack_type + item.s_pack_qty),
            'Available': utilities.zeroForNull(parseInt(item?.Available)),
            'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
            'Max Qty': item.maxQtyItemRestriction > 0 ? parseInt(item?.maxQtyItemRestriction): ''}};
          if(props.viewType === MEMBER_VIEW){
            row = {...row,...{'Cost': utilities.isBinLabel(item.userQty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, props?.order[0]?.cartInfoName == 'PROMO' ? item.promoCost : tieredCost, props.getprefer),}}
          }
          else {
            if(props.getprefer === "c3" || props.getprefer === "c4"){
              row = {...row}
            }
            else{
            row = {...row,...{'MC': utilities.isBinLabel(item.userQty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, props?.order[0]?.cartInfoName == 'PROMO' ? item.promoCost : tieredCost, props.getprefer),}}
          }
          }

          if(props.order[0].DisplayName !== 'Promotional Items' && props.viewType === MEMBER_VIEW){
          row = {...row, ...{
            'Tier Indicator': item.tiered ? `T${utilities.getCostForQtyPlpNew(item, item?.userQty, true)}`: '',
          }}}
          if(props?.order[0]?.TypeId === 1){
            if(props.viewType !== MEMBER_VIEW && props.getprefer === 'c2'){
              row = {...row, ...{
                'SR': utilities.getCostBasedOnViewType(
                  props.viewType,
                  item.srCost,
                  'Suggested Retail',
                  props.getprefer
                ),
              }}
            }  else if(props.viewType !== MEMBER_VIEW && props.getprefer === 'c3'){
              row = {...row} } else {
              row = {...row, ...{
                'Suggested Retail': utilities.getCostBasedOnViewType(
                  props.viewType,
                  item.srCost,
                  'Suggested Retail',
                  props.getprefer
                ),
              }}
            }
          }
          row = {...row, ...{
            'Override Retail':utilities.dollarForValue(item.overrideRetail),
            'Qty': utilities.emptyForNull(parseInt(item?.userQty))}}
            if(hideTotalExtendCost){
              row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getPrefer)] = utilities.isBinLabel(item?.userQty) ? '' :utilities.getCostBasedOnnatvalue(props.viewType, extprice, props.getprefer);
            } else {
              row = { ...row }
            }
            row = { ...row, ...{            
            'Error Message': utilities.emptyForNull(item.message),
          }};
          allOrdersToDownload.push(row);
          return item;
        }
      })
        )
    });
      downloadExcel(allOrdersToDownload, fileType);
      this.setState({ loading: false });
    }
    
    else if (props.fileName === 'All Orders') {
      let allOrdersToDownload = [];
      let allOrdersData = {};

      utilities.showToast("Exporting to Excel...");

      getAllOrders(this.props.searchNumber, this.props.searchParam, this.props.sortParam, this.props.sortDirection, this.props.appliedFilters, this.props.storeId, this.props.userId, this.props.rdcNum, this.props.aliasSKURetailerGrpID, this.props.totalPages, this.props.pageSize).then(response=>{
        response.map((orderResult)=>orderResult.orderGroupList.map(((orders) => (
          orders.orderList.map((order) => {
            allOrdersData = {
              'Order Created Date': utilities.emptyForNull(order.orderDateString),
              'Invoice Date': utilities.emptyForNull(order.invoiceDateString),
              'Invoice#': utilities.emptyForNull(parseInt(order?.displayedInvoiceNumber)),
              'PO#': utilities.emptyForNull(order.memberOrderRef),
              'Event #': utilities.emptyStringForZero(order.eventNumber),
              'Order Type': utilities.emptyForNull(order.orderTypeDesc),
              'Description and Category': utilities.emptyForNull(order.assortment_description),
              'Item Count': order.numberOfItems ? parseInt(order.numberOfItems) : 0,
              'RDC': utilities.emptyForNull(order.rdcNumber),
              'Ship To': utilities.emptyForNull(order.consumerName),
              'Expected Release Week': utilities.emptyForNull(order.shipDateString),
            }
            if(hideTotalExtendCost){
              allOrdersData[utilities.getLabelsByType(props.viewType,"TOTAL_COST", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType, order.subCostTotal, props.getprefer);
            } else {
              allOrdersData = { ...allOrdersData }
            }
            allOrdersData = {...allOrdersData, ...{
              'Cancel Code': order.cancelReasonCode === '-' ? '' : utilities.emptyForNull(order.cancelReasonCode),
              'Tracking #': utilities.emptyForNull(order.retailerTrackingNumber),
              'Status': utilities.emptyForNull(order.displayedStatus)
            }};
            allOrdersToDownload.push(allOrdersData);
            return order;
          })
        ))));
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      })
    } else if (props.fileName === 'DirectShip All Orders') {
      let allOrdersToDownload = [];
      let allOrdersData = {};

      utilities.showToast("Exporting to Excel...");
      getAllDsOrders(this.props.searchNumber, this.props.searchParam, this.props.sortParam, this.props.sortDirection, this.props.appliedFilters, this.props.storeId, this.props.userId, this.props.rdcNum, this.props.aliasSKURetailerGrpID, this.props.totalPages, this.props.pageSize).then(response=>{
          response[0].OrdersList.map((order) => {
            allOrdersData = {
              'Order Created Date': utilities.emptyForNull(order.OrderCreatedDate),
              'PO#': utilities.emptyForNull(order.PoNumber),
              'Retailer Reference PO#': utilities.emptyForNull(order.RetailerRefPoNumber),
              'Vendor Name': utilities.emptyForNull(order.VendorName),
              'Item Count': utilities.emptyForNull(order.ItemsCount),
            }
            allOrdersData[utilities.getLabelsByType(props.viewType,"TOTAL_COST", props.getPrefer)] = utilities.getCostBasedOnnatvalue(props.viewType, order.OrderValue, props.getprefer);
            allOrdersData = {...allOrdersData, ...{
              'Status': utilities.emptyForNull(order.Status)
            }};
            allOrdersToDownload.push(allOrdersData);
            return order;
          });
        downloadExcel(allOrdersToDownload, `DirectShip All Orders.xlsx`);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      })
    }  else if (props.fileName === 'CancelledItems') {
      utilities.showToast("Exporting to Excel...");
      let productsData = props.totalItems.map(function (orders) {
        let row = {};
       row = {...row, ...{ 'Order Date': utilities.emptyForNull(orders.invoiceDate),
        'PO#': utilities.emptyForNull(orders.memberRefNum),
        'Item#': utilities.emptyForNull(parseInt(orders.itemNbr))}};
        if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
         row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(orders.aliasSku)}};
        }
       row = {...row, ...{'Model#': utilities.emptyForNull(orders.modelNum),
        'Item Description': utilities.emptyForNull(orders.shortDesc),
        'QTY': utilities.emptyForNull(parseInt(orders.userQty)),
        'Status': utilities.emptyForNull(orders.status),
        'Expected Relaese Week': utilities.emptyForNull(orders.shipDate),
        'Canceled Date': utilities.emptyForNull(orders.dateSubmitted)
        }};
        return row;
      });
      downloadExcel(productsData, fileName);
      this.setState({ loading: false });
    } else if (props.fileName === 'DirectShip Order Item Details') {
      let allOrdersToDownload = [];
      let row = {};

      utilities.showToast("Exporting to Excel...");
       this.props.orderResults.map((order) => {    
          row = 
          {'Item#':order.item_nbr,
          'Item Indicator':order?.IsStocked ? '-' : 'NS',    
          'Model #': utilities.emptyForNull(order.Model),      
          'UPC #': utilities.emptyForNull(order.Upc),
          'PO#': utilities.naForNull(this.props.orderDetails.PoNumber),
          'Retailer Reference Po #': utilities.naForNull(this.props.orderDetails.RetailerRefPoNumber),
          'Item Description': utilities.emptyForNull(order.ShortDescription),
       };
       row['DS Cost'] = utilities.getCostBasedOnnatvalue(props.viewType, order.DsCost, props.getprefer)
       row[utilities.getLabelsByType(props.viewType,"SUGG_RETAIL", props.getPrefer)] = utilities.getCostBasedOnViewType(props.viewType, order.SrpCost, 'Suggested Retail', props.getprefer)
       row = {...row, ...{'Ord Qty': utilities.emptyForNull(order.itemQty)}};  
       row = {...row, ...{'Ext Cost':  order.DsCost <=0 ? '-' 
             : utilities.getCostBasedOnViewType(
                  props.viewType,
                  order.DsCost * order.itemQty
              )}};
          allOrdersToDownload.push(row);
            return order;
      })
        downloadExcel(allOrdersToDownload, `DirectShip_${this.props.orderDetails.PoNumber}.xlsx`);
        this.setState({ loading: false });
    }else if (props.fileName === 'Order Item Details') {
      let allOrdersToDownload = [];
      let row = {};

      utilities.showToast("Exporting to Excel...");
 
       this.props.orderResults.map((order) => {    
          row = {...row, ...{'Item#': utilities.emptyForNull(parseInt(order.itemNbr))}};
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
            row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(order.aliasSku)}};
            }
          row = {...row, ...
          {
            // 'ADV Code #': utilities.emptyForNull(order.advCode),
          'UPC #': utilities.emptyForNull(order.upc),
          'Model #': utilities.emptyForNull(order.model),
          'Item Description': utilities.emptyForNull(order.shortDesc),
          'Manufacturer Name': utilities.emptyForNull(order.vendorName),
          'Seq #': utilities.emptyForNull(order.seqNumber),
          'RDC': utilities.emptyForNull(order.rdcNbr),
          'PO #': utilities.naForNull(order.poNbr),
          'Invoice #': utilities.emptyForNull(this.props.orderDetails.displayedInvoiceNumber),
          'Weight': utilities.emptyForNull(order.weight),
          'Pack': utilities.emptyForNull(order.pack),
          'Ord Qty': utilities.emptyForNull(parseInt(order.qty))
          }};
          if(props.checkFillRateAndQtyCanShow){
            row = {...row, ...{
                'Fill Qty': order.fillQty > 0 ?  utilities.zeroForNull(parseInt(order.fillQty)): utilities.zeroForNull(parseInt(order.fill_qty)),
                'Fill Code': order.fillCode >0 ? utilities.emptyForNull(order.fillCode): utilities.emptyForNull(order.fill_cd),
              }};
            if(hideTotalExtendCost){
                row[utilities.getLabelsByType(props.viewType,"COST", props.getprefer)] = utilities.isBinLabel(order.qty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, order.cost, props.getprefer);
                row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getprefer)] = utilities.isBinLabel(order.qty) ? '' : utilities.getCostBasedOnnatvalue(props.viewType, order.extCost, props.getprefer);
            } else {
              row = { ...row }
            }
          }else{
            if(hideTotalExtendCost){
              row[utilities.getLabelsByType(props.viewType,"COST", props.getprefer)] = utilities.getCostBasedOnnatvalue(props.viewType, order.cost, props.getprefer);
              row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getprefer)] = utilities.getCostBasedOnnatvalue(props.viewType, order.extCost, props.getprefer);
            } else {
              row = { ...row }
            }
          }
          if(hideSuggestedRetail){
            row[utilities.getLabelsByType(props.viewType,"SUGG_RETAIL", props.getprefer)] = order.retail_price > 0 ? `* ${utilities.getCostBasedOnViewType(props.viewType, order.retail_price, 'Suggested Retail', props.getprefer)}`:
            utilities.getCostBasedOnViewType(props.viewType, order.retailPrice, 'Suggested Retail', props.getprefer);
          }
          if(props.onVariablePricing){
            if(hideSuggestedRetail){
              row[utilities.getLabelsByType(props.viewType,"VAR_RETAIL", props.getprefer)] = utilities.getCostBasedOnViewType(props.viewType, order.variablePrice, 'Suggested Retail', props.getprefer);
            }
            row = {...row, ...{
              'Var. Method': utilities.emptyForNull(order.variableMethod),
            }};
          }
          row = {...row, ...{
            'Margin': utilities.zeroForNull((order.margin*100).toFixed(1))+'%',
            'Source': utilities.emptyForNull(order.source),
            'Status': utilities.emptyForNull(order.status),
          }};
          allOrdersToDownload.push(row);
            return order;
          })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
    } else if (props.fileName === 'Item Details') {
      utilities.showToast("Exporting to Excel...");
      let allOrdersData = props.orderResults.map(function (order) {
        return {
          'Item#': utilities.emptyForNull(parseInt(order.item_nbr)),
          'ADV Code #': utilities.emptyForNull(order.adv_code),
          'UPC': utilities.emptyForNull(order.UPC),
          'Model #': utilities.emptyForNull(order.model),
          'Item Description': utilities.emptyForNull(order.Title),
          'Program': utilities.emptyForNull(order.program),
          'Seq #': utilities.emptyForNull(order.item_seq_nbr),
          'RDC': utilities.emptyForNull(order.rdc_nbr),
          'Weight': utilities.emptyForNull(order.weight),
          'Pack': utilities.emptyForNull(order.pack_qty),
          'Qty': utilities.emptyForNull(parseInt(order.quantity)),
          'Cost': utilities.emptyForNull(order.cost),
          'Ext. Cost': utilities.emptyForNull(order.ext_cost),
          'Sugg. Retail': utilities.emptyForNull(order.sugg_retail),
          'Var. Price': utilities.emptyForNull(order.var_price),
          'Var. Method': utilities.emptyForNull(order.var_method),
          'Source': utilities.emptyForNull(order.source),
          'Cancel Code': utilities.emptyForNull(order.cancel_code),
          'Status': utilities.getStatus(order.status)
        };
      });
      downloadExcel(allOrdersData, fileName);
      this.setState({ loading: false });
    } else if (props.fileName === 'Edit Item Details') {
      utilities.showToast("Exporting to Excel...");
    
      let allOrdersData = props.orderResults.map(function (order) {
        let row = {};
       row = {...row, ...{'Item#': utilities.emptyForNull(parseInt(order.itemNum))}};
        if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
         row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(order.aliasSku)}};
        }
       row = {...row, ...{'UPC': utilities.emptyForNull(order.upcNum),
        'Model #': utilities.emptyForNull(order.modelNum),
        'Item Description': utilities.emptyForNull(order.shortDesc),
        'Program': utilities.emptyForNull(order.noteProgram),
        'Seq #': utilities.emptyForNull(order.seqNum),
        'RDC': utilities.emptyForNull(order.rdcNum),
        'Weight': utilities.emptyForNull(order.weight),
        'Pack': utilities.emptyForNull(order.packType) + utilities.zeroForNull(order.packQty),
        'Qty': utilities.emptyForNull(parseInt(order.userQty)),
      }};
      if(hideTotalExtendCost){
        row[utilities.getLabelsByType(props.viewType,"COST", props.getprefer)] = utilities.isBinLabel(order?.userQty) ? '' :utilities.getCostBasedOnnatvalue(props.viewType, order.cost, props.getprefer);
        row[utilities.getLabelsByType(props.viewType,"EXTENDED_COST", props.getprefer)] = utilities.isBinLabel(order?.userQty) ? '' :utilities.getCostBasedOnnatvalue(props.viewType, order.extCost, props.getprefer);
      } else {
        row = { ...row }
      }

      if(hideSuggestedRetail){
        row[utilities.getLabelsByType(props.viewType,"SUGG_RETAIL", props.getprefer)] = order.overrideRetail && order.overrideRetail > 0 ? `* ${utilities.getCostBasedOnViewType(props.viewType, order.overrideRetail, 'Suggested Retail', props.getprefer)}`:
        utilities.getCostBasedOnViewType(props.viewType, order.srCost, 'Suggested Retail', props.getprefer);
      } else {
        row = { ...row }
      }
      row = {...row, ...{
        'Source': utilities.emptyForNull(order.source),
        'Status': utilities.emptyForNull(order.status)
        }};
        return row;
      });
      downloadExcel(allOrdersData, fileName);
      this.setState({ loading: false });
    } else if (props.fileName === 'Discontinued Items') {
      utilities.showToast("Exporting to Excel...");
      let allOrdersToDownload = [];
      let allOrdersData = {};
      getExportItems('discontinuedItem',props.rdc, props.storeID, props.affiliate, props.primaryWarehouseNum, props.groupUrlId, props.easyAskDic, props.cartid, parseInt(props.totalItems), props.filterString, props.isShowAllWarehouse, props.sortDir, props.sortBy).then(response=>{
        response.map((details)=>{
          details.source && details.source.products && details.source.products.items && details.source.products.items.map((item)=>{
            allOrdersData = {
              'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
              'Item#': utilities.emptyForNull(parseInt(item.Item_Number)),
              'Short Description': utilities.emptyForNull(item.Product_Name),
              'Long Description': config.showMediumDesp ?
              utilities.emptyForNull((item.Product_Title || item.Long_Description))
              : utilities.emptyForNull(item.Long_Description),
              'Model #': utilities.emptyForNull(item.Model),
              'Pack': `${item.S_Pack_Type ? item.S_Pack_Type : ''}${
                item.S_Pack_Qty ? item.S_Pack_Qty : ''
              }`,
              'Disc. Date': item.discontinued_date ?  utilities.getDateFromTimeStamp(
                new Date(item.discontinued_date)
              ) : '',
            }
            if(props.viewType !== MEMBER_VIEW){
                if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
                allOrdersData = { ...allOrdersData,
                  'MC':utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    utilities.getCostForQtyPlpNew(item, item.cart_available),
                    props.getprefer
                  ),
                  'RC': utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    item.Regular_Cost,
                    props.getprefer
                  ),
                }
              }
              if(props.getprefer === 'c2'){
                allOrdersData = { ...allOrdersData,
                  'SR': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.SuggestedRetail,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              } else if(props.getprefer !== 'c3'){
                allOrdersData = { ...allOrdersData,
                  'Suggested Retail': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.SuggestedRetail,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              };
            } else {
              allOrdersData = { ...allOrdersData,
              '% Off': utilities.percentageFormat(item.savings),
              'Member Cost':utilities.getCostBasedOnnatvalue(
                props.viewType,
                utilities.getCostForQtyPlpNew(item, item.cart_available),
                props.getprefer
              ),
              'Member Cost Tier Indicator': item.Member_Cost_Tier ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(item, item.cart_available, true)): '', 
              'Regular Cost': utilities.getCostBasedOnnatvalue(
                props.viewType,
                item.Regular_Cost,
                props.getprefer
              ),
              'Regular Cost Tier Indicator': item.tiered ? 'T2': '',
              'Suggested Retail': utilities.getCostBasedOnViewType(
                props.viewType,
                item.SuggestedRetail,
                'Suggested Retail',
                props.getprefer
              ),
              }
            }
            allOrdersData = { ...allOrdersData,
              'Override Retail': utilities.emptyForNull(utilities.dollarForValue(item.cart_overrideretail)),
              'Quantity': utilities.emptyForNull(item.cart_available),
              'Max Quantity': utilities.emptyStringForZero(parseInt(item.maxQty)),
              'Available': props.isShowAllWarehouse ? '' : utilities.zeroForNull(item.Available),
              'Hub Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubIndictor(item),
              'Hub & Spoke Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubAndSpokeIndictor(item),
              'Stock Status': utilities.emptyForNull(item.whStockStatusMessage),
              'Type (Referral/Sub/Alt)': utilities.emptyForNull(utilities.substituteItemExport(item?.alternateItem?.Label, item?.alternateItem?.Number)),
              'Referral/Sub/Alt Item #': utilities.emptyForNull(parseInt(item?.alternateItem?.Number)),
              'Restricted Product': utilities.itemRestricted(item?.Restrictions),
              'Restriction Message': utilities.itemRestrictionDetails(item?.Restrictions),
              'Assortment Item' : excelAssotmentIdentifiers(item),
              'Product Identifiers': excelProductIdentifiers(item),
              'Favorite Item': props.favorites.indexOf(parseInt(item.Item_Number)) > -1 ? 'Yes' : 'No',            
              'RDC #': utilities.emptyForNull(props.rdc),             
              'Reason Code Description': utilities.emptyForNull(item.whStockStatusDiscMessage),

              // 'Sub/Ref Model #': utilities.emptyForNull(item?.alternateItem?.Model),
              // 'Sub/Ref Description': utilities.emptyForNull(item?.alternateItem?.Description),
              // 'Sub/Ref Manufacturer': utilities.emptyForNull(item?.alternateItem?.Vendor),
              // 'Sub/Ref Pack': `${item?.alternateItem?.PackType ? item?.alternateItem?.PackType : ''}${
              //   item?.alternateItem?.PackQty ? item?.alternateItem?.PackQty : ''
              //   }`,
              // 'Sub/Ref Suggested Retail': utilities.emptyForNull(utilities.getCostBasedOnViewType(
              //   props.viewType,
              //   item?.alternateItem?.RetailCost
              // )),
              // 'Sub/Ref Member Cost': utilities.emptyForNull(utilities.getCostBasedOnViewType(
              //   props.viewType,
              //   item?.alternateItem?.MemberCost
              // )),
            };
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
              allOrdersData = {...allOrdersData, ...{'Alternate SKU': item.Xref_ItemNbr !== '~' ? item.Xref_ItemNbr : ''}};
             }
             
            allOrdersToDownload.push(allOrdersData);
            return details;
          })
        })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
        this.setState({ loading: false });
      })
    }  else if (props.fileName === 'Momentum Items') {
      utilities.showToast("Exporting to Excel...");
      let allOrdersToDownload = [];
      let allOrdersData = {};
      getMomentumItems(props.sortBy, props.sortOrder, props.sortDepartment, props.storeId, props.userId, props.aliasSKURetailerGrpID, props.currentCartId, props.multiStoreDetails, props.rdc, props.totalItems ).then(response=>{
        response.map((details)=>{
          details.favoriteList.length > 0 && details.favoriteList.map((item)=>{
            allOrdersData = {
              'Fineline Department': utilities.emptyForNull(item.departmentName),
              'Fineline Class': utilities.emptyForNull(item.Class),
              'Fineline Subclass': utilities.emptyForNull(item?.SubClass),
              'Vendor Name': utilities.emptyForNull(item.vendorName),
              'Item #': utilities.emptyForNull(parseInt(item?.itemNbr)),
              'Short Description':utilities.emptyForNull(item.shortDescription),
              'Long Description': utilities.emptyForNull(item.Description),
              'Model #': utilities.emptyForNull(item.Model),             
              'Pack': `${item.sPackType ? item.sPackType : ''}${
                item.sPackQty ? item.sPackQty : ''
              }`,
            }
            if(props.viewType !== MEMBER_VIEW){
              if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
                allOrdersData = { ...allOrdersData,
                  'MC': item.pricing ? utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    utilities.getCostForQtyPlpNew(item.pricing, item.cart_available),
                    props.getprefer
                  ) : '',
                }
              }
              if(props.getprefer === 'c2'){
                allOrdersData = { ...allOrdersData,
                  'SR': item.pricing ? utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.pricing.srCost,
                    'Suggested Retail',
                    props.getprefer
                  ) : '',
                }
              } else if(props.getprefer !== 'c3'){
                allOrdersData = { ...allOrdersData,
                  'Suggested Retail': item.pricing ? utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.pricing.srCost,
                    'Suggested Retail',
                    props.getprefer
                  ) : '',
                }
              };
            } else {
              allOrdersData = { ...allOrdersData,
              'Member Cost': item.pricing ? utilities.getCostBasedOnnatvalue(
                props.viewType,
                utilities.getCostForQtyPlpNew(item.pricing, item.cart_available),
                props.getprefer
              ) : '',
              'Member Cost Tier Indicator': item?.pricing?.Member_Cost_Tier ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(item.pricing, item.cart_available, true)): '', 
              'Suggested Retail': item.pricing ? utilities.getCostBasedOnViewType(
                props.viewType,
                item.pricing.srCost,
                'Suggested Retail',
                props.getprefer
              ) : '',
              }
            }
            allOrdersData = { ...allOrdersData,
              'Override Retail': utilities.emptyForNull(utilities.dollarForValue(item.cart_overrideretail)),
              'Quantity': utilities.emptyForNull(item.cart_available),
              'Momentum Code': utilities.emptyForNull(item.MomentumCode),
              'Max Quantity': utilities.emptyStringForZero(parseInt(item.maxQty)),
              'Available': utilities.zeroForNull(item.available),
              'Hub Indicator': utilities.excelHubIndictor(item),
              'Hub & Spoke Indicator': utilities.excelHubAndSpokeIndictor(item),
              'Stock Status': utilities.emptyForNull(item.StockStatusMessage),
              'Type (Referral/Sub/Alt)': utilities.emptyForNull(utilities.substituteItemExport(item?.alternateItem?.Label, item?.alternateItem?.Number)),
              'Referral/Sub/Alt Item #': utilities.emptyForNull(parseInt(item?.alternateItem?.Number)),
              'Restricted Product': utilities.itemRestricted(item?.Restrictions),
              'Restriction Message': utilities.itemRestrictionDetails(item?.Restrictions).replace('{@ShowSubscribeLink}',''),
              'Assortment Item' : excelAssotmentIdentifiers(item),
              'Product Identifiers': excelProductIdentifiers(item),
              'Favorite Item': utilities.emptyForNull(item.favoriteItem),
              'Reason Code Description': utilities.emptyForNull(item.whStockStatusDiscMessage),    
            };
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
              allOrdersData = {...allOrdersData, ...{'Alternate SKU': item.aliasSku !== '~' ? item.aliasSku : ''}};
             }
             
            allOrdersToDownload.push(allOrdersData);
            return details;
          })
        })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
        this.setState({ loading: false });
      })
    } else if (props.fileName === "Reunion") {
      utilities.showToast("Exporting to Excel...");
      let productsData = props.itemsData.map(function (item) {
        return {
          'Item#': utilities.emptyForNull(parseInt(item.Item_Number)),
          'Short Description': utilities.emptyForNull(item.Product_Name),
          'Item Description': config.showMediumDesp ?
           utilities.emptyForNull(item.productTitle || item.Product_Title || item.Long_Description)
           : utilities.emptyForNull(item.Long_Description),
          'Suggested Retail': utilities.getCostBasedOnViewType(
            props.viewType,
            item.Srp_Cost,
            'Suggested Retail',
            props.getprefer
          ),
          'WH Cost': utilities.emptyForNull(parseInt(item.future_cost)),
          'Evt Cost': utilities.emptyForNull(parseInt(item.spc_cost)),
          'Model #': utilities.emptyForNull(item.Model),
          "UPC": utilities.emptyForNull(item.UpcNum),
          "Savings": utilities.emptyForNull(item.Savings),
          "Non Stocked": utilities.emptyForNull((!item.stocked || item.stocked !== '1') ? 'Y' : 'N')
        };
      });
      downloadExcel(productsData, fileName);
    } else if (props.fileName === "ImpulseMerchandising") {
      utilities.showToast("Exporting to Excel...");
      let productsData = props.itemsData.map(function (item) {
        return {
          'Item#': utilities.emptyForNull(parseInt(item.Item_Number)),
          'Short Description': utilities.emptyForNull(item.Product_Name),
          'Item Description': config.showMediumDesp ?
           utilities.emptyForNull(item.Product_Title || item.productTitle || item.Long_Description)
           : utilities.emptyForNull(item.Long_Description),
          'Member Cost':  utilities.getCostBasedOnnatvalue(props.viewType, item.Member_Cost, props.getprefer),
          'Suggested Qty': utilities.emptyForNull(item.recQty),
          'Model #': utilities.emptyForNull(item.Model),
          "PACK": utilities.emptyForNull(item.S_Pack_Type + item.S_Pack_Qty),
        };
      });
      downloadExcel(productsData, fileName);
    } else if (props.fileName === RETAIL_ASSORTMENTS || props.fileName === CTB_ASSORTMENTS) {
      utilities.showToast("Exporting to Excel...");
      let itemList = props.itemsData || [];
      if ([0, 2].includes(props.missing) && props.isCtbAssortment) {
        itemList = props.itemsData.filter((product) => {
          if (parseInt(product.IsMissingItem) === 0) {
            return product;
          } else {
            return;
          }
        });
      }
      let productsData = itemList.map(function (item) {
        let row = {};
        row = {...row, ...{
          ...(props.fileName === CTB_ASSORTMENTS && {
            "Store Id": utilities.emptyForNull(props?.storeId),
            "POG #": utilities.emptyForNull(props?.planogramNumber),
          }),
          'Item#': utilities.emptyForNull(parseInt(item.Item_Number))
        }};
        if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID) && (props.fileName === FLASH_SALES || props.fileName === REUNION_RETAIL_ASSORTMENTS || props.fileName === CTB_ASSORTMENTS || props.fileName === RETAIL_ASSORTMENTS)){
         row = {...row, ...{'Alternate SKU#': item.aliasSku}};
        }
       row = {...row, ...{
        'Store Id': utilities.emptyForNull(props?.storeId),
        'Model #': utilities.emptyForNull(item.model),
        'Short Description': utilities.emptyForNull(item.Product_Name),
        'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
        'Item Description': config.showMediumDesp ?
         utilities.emptyForNull(item.Product_Title || item.productTitle || item.Long_Description)
         : utilities.emptyForNull(item.Long_Description),
        'Quantity': utilities.emptyForNull(parseInt(item.Available)),
        'UPC': utilities.emptyForNull(item.UpcNum),
       }};
       if(props.fileName === CTB_ASSORTMENTS) {
        row = {...row, ... {
          "POG selection":item.IsMissingItem === '1'? 'All': 'Missing'
        }}
       }
       if(props.viewType !== MEMBER_VIEW){
        if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
          row = { ...row,
            'MC':utilities.getCostBasedOnnatvalue(
              props.viewType,
              props.RAMarketFlag==='Y' ? item.Reunion_Cost : item.pricing ? item.pricing.Cost : '', 
              props.getprefer
            ),
          }
        }
        if(props.getprefer === 'c2'){
          row = { ...row,
            'SR': utilities.getCostBasedOnViewType(
              props.viewType,
              item.SuggestedRetail,
              'Suggested Retail',
              props.getprefer
            ), 
          }
        } else if(props.getprefer !== 'c3'){
          row = { ...row,
          'Suggested Retail': utilities.getCostBasedOnViewType(
            props.viewType,
            item.SuggestedRetail,
            'Suggested Retail',
            props.getprefer
          ),
          }
        };
      } else{
        row = {...row, ...{
        'Member Cost': utilities.getCostBasedOnnatvalue(props.viewType, 
                        props.RAMarketFlag==='Y' ? item.Reunion_Cost : item.pricing ? item.pricing.Cost : '', 
                        props.getprefer),
        'Suggested Retail': utilities.getCostBasedOnViewType(
          props.viewType,
          item.SuggestedRetail,
          'Suggested Retail',
          props.getprefer
        ),
      }}};
        return row;
      });
      let updatedFileName = fileName;
      if (props.fileName === CTB_ASSORTMENTS) {
        debugger;
        updatedFileName = fileName.replace(
          /Store/g,
          utilities.emptyForNull(props?.storeId)
        );
        updatedFileName = updatedFileName.replace(
          /POG/g,
          utilities.emptyForNull(props?.planogramNumber)
        );
      }
      downloadExcel(productsData, updatedFileName);
    }else if (props.fileName === REUNION_RETAIL_ASSORTMENTS || props.fileName === FLASH_SALES) {
      utilities.showToast("Exporting to Excel...");
      let productsData = props.itemsData.map(function (item) {
        let row = {};
       row = {...row, ...{'Item#': utilities.emptyForNull(parseInt(item.Item_Number))}};
        if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID) && (props.fileName === FLASH_SALES || props.fileName === REUNION_RETAIL_ASSORTMENTS || props.fileName === RETAIL_ASSORTMENTS || props.fileName === CTB_ASSORTMENTS)){
         row = {...row, ...{'Alternate SKU#': item.aliasSku}};
        }
       row = {...row, ...{'Short Description': utilities.emptyForNull(item.Product_Name),
        'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
        'Item Description': config.showMediumDesp ?
         utilities.emptyForNull(item.Product_Title || item.productTitle || item.Long_Description)
         : utilities.emptyForNull(item.Long_Description),
        'Quantity': utilities.emptyForNull(parseInt(item.Available)),
        'Member Cost': props.RAMarketFlag==='Y' && item.Reunion_Cost && parseInt(item.Reunion_Cost) === 0 ? '' 
                      : utilities.getCostBasedOnnatvalue(props.viewType, 
                        props.RAMarketFlag==='Y' ? item.Reunion_Cost : item.pricing ? item.pricing.Cost : '', 
                        props.getprefer),
        'Model #': utilities.emptyForNull(item.model),
        'Sugg. Retail': utilities.emptyForNull(item.SuggestedRetail)
        }};
        return row;
      });
      downloadExcel(productsData, fileName);
    } else if (props.fileName === PLANOGRAM_ITEMS ) {
      utilities.showToast("Exporting to Excel...");
      let planogramItemsData = props?.itemsData && props?.itemsData.map((item)=>{
            let row = {};
            row = {...row, ...{
                  'Description': utilities.emptyForNull(props.planogramData.departmentName),
                  'Category': utilities.emptyForNull(props.planogramData.categoryName),
                  'POG Description': props.planogramData.planogramSubName ? utilities.emptyForNull(props.planogramData.planogramSubName)
                    + ' - ' + utilities.emptyForNull(props.assortmentId) : utilities.emptyForNull(props.planogramData.planogramSubName),
                  'Size': utilities.emptyForNull(props.planogramData.sizeString),
                  'Sku Count': utilities.emptyForNull(parseInt(props?.planogramData?.nbrSkus)),
                  'Store Typical': utilities.emptyForNull(props.planogramData.storeTypical),
                  'Region': utilities.emptyForNull(props.planogramData.region),
                  'Type': utilities.emptyForNull(props.planogramData.type),
                  'Urbanicity': utilities.emptyForNull(props.planogramData.urbanicity),
                  'Last Updated': utilities.getDateFromTimeStamp(props.planogramData.lastUpdatedDate),
                  'Core': utilities.emptyForNull(props.planogramData.core),
                }
              }
              const totalMemberCost = props.planogramMemberCost.map((item)=>item.orderQty * item.memberCost).reduce((a,b) => a+b);
              row = {...row, ...{
                  'Vendor':  utilities.emptyForNull(item.Vendor_Name),
                  'Loc ID': utilities.emptyForNull(parseInt(item?.locId)),
                  'Item#': utilities.emptyForNull(parseInt(item?.Item_Number)),
                  'Item Description': config.showMediumDesp ? utilities.emptyForNull( item.Product_Title || item.Product_Name) : utilities.emptyForNull(item.Product_Name),
                  'Model#': utilities.emptyForNull(item.Model),
                  'UPC': utilities.emptyForNull(item.UpcNum),
                }}
                if(props.viewType !== MEMBER_VIEW){
                  if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
                    row = { ...row,
                      'Total MC': utilities.getCostBasedOnnatvalue(
                        props.viewType,
                        totalMemberCost,
                        props.getprefer
                      ),
                      'T1 Cost': utilities.getCostBasedOnnatvalue(props.viewType, item?.pricing?.Tiers[0]?.Cost, props.getprefer),
                      'T2 Cost': utilities.getCostBasedOnnatvalue(props.viewType, item?.pricing?.Tiers[1]?.Cost, props.getprefer),
                    }
                  }
                  if(props.getprefer === 'c2'){
                    row = { ...row,
                      'SR': utilities.getCostBasedOnViewType(
                        props.viewType,
                        item.SuggestedRetail,
                        'Suggested Retail',
                        props.getprefer
                      ),
                    }
                  } else if(props.getprefer !== 'c3'){
                    row = { ...row,
                    'Suggested Retail': utilities.getCostBasedOnViewType(
                      props.viewType,
                      item.SuggestedRetail,
                      'Suggested Retail',
                      props.getprefer
                    ),
                    }
                  };
                } else {
                  row = {...row, ...{
                    'Total Member Cost': utilities.floatDollarForValue(totalMemberCost),
                    'Suggested Retail': utilities.getCostBasedOnViewType(
                      props.viewType,
                      item.SuggestedRetail,
                      'Suggested Retail',
                      props.getprefer
                    ),
                    'T1 Cost': utilities.roundWithdollar(item?.pricing?.Tiers[0]?.Cost),
                    'T2 Cost': utilities.roundWithdollar(item?.pricing?.Tiers[1]?.Cost),
                  }
                }}
                  
                row = {...row, ...{
                  'Variable Retail': '',
                  'Available': utilities.zeroForNull(parseInt(item?.Available)),
                  'Pack': utilities.emptyForNull(`${item.S_Pack_Type ? item.S_Pack_Type : ''}${item.S_Pack_Qty ? item.S_Pack_Qty : ''}`),
                  'Sugg. Qty': utilities.emptyForNull(parseInt(item?.recQty)),
                  'Order Qty': utilities.emptyForNull(parseInt(item?.cart_available)),
                  'Max Qty': utilities.emptyForNull(parseInt(item?.maxQty)),
                  'No of Facings': utilities.emptyForNull(parseInt(item?.facings)),
                  'Made in USA': utilities.emptyForNull(item.madInUSAInd),
                  'Assortment Item': item.AssortmentLevel && item.AssortmentLevel.trim() ? utilities.emptyForNull(item.AssortmentLevel)
                  + '-' + assortmentLevels[parseInt(item.AssortmentLevel.replace(/\D/g,''))] : ''
                }}
                if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
                  row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(item.aliasSku)}};
                }
                return row;
              }); 
              downloadExcel(planogramItemsData, props.fileName + '-' +props.assortmentId);        
    } else if (props.url) {
      utilities.showToast("Exporting to Excel...");
      let url = props.url + `&ResultsPerPage=${props.totalItems}`;
      restUtils
        .getData(url)
        .then(response => {
          var categoryData = response.data.source.products.items.map(
            product => {
              let row = {};
              const catgPath = product.CategoryPath.split('>');
              row = {...row, ...{Category: catgPath[1],
                  Class: catgPath[2],
                  Subclass: catgPath[3],
                  'Item Number': utilities.emptyForNull(product.Item_Number)}
                };
                if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
                  row = {...row, ...{'Alternate SKU#':  product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr : ''}};
                }
                row = {...row, ...{Model: utilities.emptyForNull(product.Model),
                  'Item Description': config.showMediumDesp ?
                  utilities.emptyForNull((product.Product_Title || product.Long_Description))
                   : utilities.emptyForNull((product.Long_Description)),
                  Pack: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
                    product.S_Pack_Qty ? product.S_Pack_Qty : ''
                    }`,
                  'Available': utilities.zeroForNull(parseInt(product.Available)),
                  'SUGG. RETAIL': utilities.getCostBasedOnViewType(
                    props.viewType,
                    product.Srp_Cost
                  ),
                  'Member Cost': utilities.getCostBasedOnViewType(
                    props.viewType,
                    utilities.getCostForQty(product, 0, true)
                  )
                }};
              return row;
            }
          );
          downloadExcel(categoryData, fileName);
          this.setState({ loading: false });
        })
        .catch(error => {
          utilities.showToast("Unexpected error occured please try after some time");
          console.log(error);
          this.setState({ loading: false });
        });
    }
    
    else if(this.props.fileName ==='dom'){
      utilities.showToast("Exporting to Excel...");
      let productsData = props.itemsData.map(function (product) {
        let row = {};
        row = {...row, ...{'Item #': utilities.emptyForNull(parseInt(product.Item_Number))}};
        if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
         row = {...row, ...{'Alternate SKU#': utilities.emptyForNull(product.aliasSku)}};
        }
        row = {...row, ...{
          'Short Description': utilities.emptyForNull(product.short_desc),
          'Model #': utilities.emptyForNull(product.Model),
          'Item Description': config.showMediumDesp ?
          utilities.emptyForNull((product.Product_Title || product.Long_Description))
           : utilities.emptyForNull(product.Long_Description) ,
          'Vendor Name': utilities.emptyForNull(product.Vendor_Name),
          Pack: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
            product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`,
          'Max Quantity': utilities.emptyStringForZero(parseInt(product.maxQty)),
          'Available': utilities.zeroForNull(parseInt(product.Available)),
          'Member Cost': utilities.getCostBasedOnnatvalue(props.viewType, product.Member_Cost, props.getprefer),
          'Promo Retail': !utilities.isEmptyOrNullString(product.promo_retail) ? '$' + Number(product.promo_retail).toFixed(2) : '',
          'Promo Cost': !utilities.isEmptyOrNullString(product.spc_cost) ? '$' + Number(product.spc_cost).toFixed(2) : '',
          'Suggested Retail': utilities.getCostBasedOnViewType(
            props.viewType,
            product.srCost,
            'Suggested Retail',
            props.getprefer
          ),
          }
        };
        return row;
      });
      downloadExcel(productsData, fileName);
      this.setState({ loading: false });
    }
    else if(this.props.fileName === MONTHLY_SPECIALS || this.props.fileName === WOW){
      utilities.showToast("Exporting to Excel...");
      let productsData = props.itemsData.map(function (product) {
        const futureCost = (product.future_cost && parseInt(product.future_cost)) !== 0 ? product.future_cost : product.Cost;
        return {
          'Item Number': utilities.emptyForNull(parseInt(product.Item_Number)),
          'Short Description': utilities.emptyForNull(product.shortDesc),
          'Model': utilities.emptyForNull(product.Model),
          'Pack': `${product.S_Pack_Type ? product.S_Pack_Type : ''}${product.S_Pack_Qty ? product.S_Pack_Qty : ''}`,
          'UPC': utilities.emptyForNull(product.UpcNum),
          'Item Description': config.showMediumDesp ?
           utilities.emptyForNull(product.Product_Title || product.productTitle || product.Product_Description) 
          : utilities.emptyForNull(product.Product_Description),
          Pack: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
            product.S_Pack_Qty ? product.S_Pack_Qty : ''
            }`,
          'WH COST':utilities.getCostBasedOnnatvalue(props.viewType,futureCost, props.getprefer),
          'EVT COST': utilities.emptyForNull('$' + product.spc_cost),
          'Savings': utilities.emptyForNull(product.Savings),
          'Ship Week 1': product.shipdate1 && product.shipDate1 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipdate1) : '',
          'Ship Week 2': product.shipdate2 && product.shipdate2 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipdate2) : '',
          'Ship Week 3': product.shipdate3 && product.shipdate3 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipdate3) : '',
          'Ship Week 4': product.shipdate4 && product.shipdate4 !== 'Invalid date' ? utilities.getDateFromTimeStamp(product.shipdate4) : '',
          'Non Stocked': product.stocked && product.stocked !== 'N/A' ? product.stocked : '',
          'Vendor Name': utilities.emptyForNull(product.Vendor_Name),
          'SEQ': utilities.emptyForNull(product.seq_nbr)
        };

      });
      downloadExcel(productsData, fileName);
      this.setState({ loading: false });
    }
    else if(this.props.fileName === 'New Items'){
      utilities.showToast("Exporting to Excel...");
      let allOrdersToDownload = [];
      let allOrdersData = {};
      getExportItems('newItems',props.rdc, props.storeID, props.affiliate, props.primaryWarehouseNum, props.groupUrlId, props.easyAskDic, props.cartid, parseInt(props.totalItems), props.filterString, props.isShowAllWarehouse, props.sortDir, props.sortBy, props.multiStoreDetails ).then(response=>{
        response.map((details)=>{
          details.source && details.source.products && details.source.products.items && details.source.products.items.map((item)=>{
            allOrdersData = {
              'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
              'Item #': utilities.emptyForNull(parseInt(item.Item_Number)),
              'Short Description': utilities.emptyForNull(item.Product_Name),
              'Long Description':  config.showMediumDesp ?
              utilities.emptyForNull((item.Product_Title || item.Long_Description))
              : utilities.emptyForNull((item.Long_Description)),
              'Model #': utilities.emptyForNull(item.Model),
              'Pack': `${item.S_Pack_Type ? item.S_Pack_Type : ''}${
                item.S_Pack_Qty ? item.S_Pack_Qty : ''
              }`,
            }
            if(props.viewType !== MEMBER_VIEW){
              if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
                allOrdersData = { ...allOrdersData,
                  'MC': utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    utilities.getCostForQtyPlpNew(item, item.cart_available),
                    props.getprefer
                  ),
                }
              }
              if(props.getprefer === 'c2'){
                allOrdersData = { ...allOrdersData,
                  'SR': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.SuggestedRetail,
                    'Suggested Retail',
                    props.getprefer
                  ),
                }
              } else if(props.getprefer !== 'c3'){
                allOrdersData = { ...allOrdersData,
                'Suggested Retail': utilities.getCostBasedOnViewType(
                  props.viewType,
                  item.SuggestedRetail,
                  'Suggested Retail',
                  props.getprefer
                ),
                }
              };
            } else{
              allOrdersData = { ...allOrdersData,
              'Member Cost': utilities.getCostBasedOnnatvalue(
                props.viewType,
                utilities.getCostForQtyPlpNew(item, item.cart_available),
                props.getprefer
              ),
              'Member Cost Tier Indicator': item.Member_Cost_Tier ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(item, item.cart_available, true)): '',
              'Suggested Retail': utilities.getCostBasedOnViewType(
                props.viewType,
                item.SuggestedRetail,
                'Suggested Retail',
                props.getprefer
              ),
              }
            }
            allOrdersData = { ...allOrdersData,
              'Override Retail': utilities.emptyForNull(utilities.dollarForValue(item.cart_overrideretail)),
              'Quantity': utilities.emptyForNull(item.cart_available),
              'Max Quantity': utilities.emptyStringForZero(parseInt(item.maxQty)),
              'Available': props.isShowAllWarehouse ? '' : utilities.zeroForNull(parseInt(item.Available)),
              'Hub Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubIndictor(item),
              'Hub & Spoke Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubAndSpokeIndictor(item),
              'Stock Status': utilities.emptyForNull(item.whStockStatusMessage),
              'Date Added': utilities.emptyForNull(item.creation_date),
              'Type (Sub/Ref)': utilities.emptyForNull(utilities.substituteItemExport(item?.alternateItem?.Label, item?.alternateItem?.Number)),
              'Sub/Ref Item': utilities.emptyForNull(parseInt(item?.alternateItem?.Number)),
              'Restricted Product': utilities.itemRestricted(item?.Restrictions),
              'Restriction Message': utilities.itemRestrictionDetails(item?.Restrictions),
              'Assortment Item' : excelAssotmentIdentifiers(item),
              'Product Identifiers': excelProductIdentifiers(item),
              'Favorite Item': props.favorites.indexOf(parseInt(item.Item_Number)) > -1 ? 'Yes' : 'No',             
            };
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
              allOrdersData = {...allOrdersData, ...{'Alternate SKU': item.Xref_ItemNbr !== '~' ? item.Xref_ItemNbr : ''}};
             }

             allOrdersData = {...allOrdersData, ...{
              'Reason Code Description': utilities.emptyForNull(item.whStockStatusDiscMessage),
            }};

            allOrdersToDownload.push(allOrdersData);
            return details;
          })
        })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
        this.setState({ loading: false });
      })
    } else if(file === 'DirectShip Products' ){
      utilities.showToast("Exporting to Excel...");
      let allOrdersToDownload = [];
      let allOrdersData = {};
      getDirectShipItems(props.multiStoreDetails.userID,props.totalItems, props.sortBy, props.sortDir, props.vendorId, props.filterString).then(response=>{
        response.map((details)=>{
          details && details.DirectShipItems && details.DirectShipItems.map((item)=>{
          allOrdersData = {
              'Vendor Name': utilities.emptyForNull(decodeURIComponent(props.vendorName)),
              'Item #': utilities.emptyForNull(parseInt(item?.Item_Number)),
              'Item Indicator':item?.IsStocked ? '-' : 'NS',
              'Short Description':utilities.emptyForNull(item.Product_Name),
              'Long Description': config.showMediumDesp ?
              utilities.emptyForNull((item.Product_Title || item.productTitle || item.Long_Description))
              : utilities.emptyForNull((item.Long_Description)),
              'Pack': `${item.s_pack_qty ? item.s_pack_qty : ''  }`,
              'Model #': utilities.emptyForNull(item.Model),   
              'UPC#': utilities.emptyForNull(item.UpcNum),
              'DS Cost':item.DSCost <=0  ? '-' : utilities.getCostBasedOnViewType(
                props.viewType,
                item.DSCost
              ),
              'Sugg. Retail': utilities.getCostBasedOnViewType(
                props.viewType,
                item.Srp_Cost
              ),
              'Quantity': utilities.emptyForNull(item.cart_available)                          
            }
            allOrdersToDownload.push(allOrdersData);
            return details;
          })
        })
        downloadExcel(allOrdersToDownload, `DirectShip_${decodeURIComponent(props.vendorName)}_${utilities.getCurrDate()}.xlsx`);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      })
    } 
    else if(file === 'Warehouse Products' || file === 'Vendor Products' ){
      utilities.showToast("Exporting to Excel...");
      let allOrdersToDownload = [];
      let allOrdersData = {};
      const vendorName = props.vendorName && file === 'Vendor Products' ? props.vendorName : ''
      const vendorId = props.vendorId && file === 'Vendor Products' ? props.vendorId : ''
      getWarehouseItems(props.rdc, props.storeID, props.affiliate, props.primaryWarehouseNum, props.groupUrlId, props.easyAskDic, props.catPath, props.category, props.subCategory, props.cartid, parseInt(props.totalItems), vendorName, vendorId, file, props.description, props.descriptionValue, props.filterString, props.isShowAllWarehouse, props.sortDir, props.sortBy ).then(response=>{
        response.map((details)=>{
          details.source && details.source.products && details.source.products.items && details.source.products.items.map((item)=>{
          allOrdersData = {
              'Fineline Department': utilities.emptyForNull(item.Department),
              'Fineline Class': utilities.emptyForNull(item.Class),
              'Fineline Subclass': utilities.emptyForNull(item?.SubClass),
              'Vendor Name': utilities.emptyForNull(item.Vendor_Name),
              'Item #': utilities.emptyForNull(parseInt(item?.Item_Number)),
              'Short Description':utilities.emptyForNull(item.Product_Name),
              'Long Description': config.showMediumDesp ?
              utilities.emptyForNull((item.Product_Title || item.productTitle || item.Long_Description))
              : utilities.emptyForNull((item.Long_Description)),
              'Model #': utilities.emptyForNull(item.Model),             
              'Pack':`${item.S_Pack_Type ? item.S_Pack_Type : ''}${
                item.S_Pack_Qty ? item.S_Pack_Qty : ''
                }`,
            }
            if(props.viewType !== MEMBER_VIEW){
              if(props.getprefer !== 'c3' && props.getprefer !== 'c4'){
                allOrdersData = { ...allOrdersData,
                  'MC':utilities.getCostBasedOnnatvalue(
                    props.viewType,
                    utilities.getCostForQtyPlpNew(item, item.cart_available),
                    props.getprefer
                  ),
                }
              }
              if(props.getprefer === 'c2'){
                allOrdersData = { ...allOrdersData,
                  'SR': utilities.getCostBasedOnViewType(
                    props.viewType,
                    item.SuggestedRetail,
                    'Suggested Retail',
                    props.getprefer
                  ), 
                }
              } else if(props.getprefer !== 'c3'){
                allOrdersData = { ...allOrdersData,
                'Suggested Retail': utilities.getCostBasedOnViewType(
                  props.viewType,
                  item.SuggestedRetail,
                  'Suggested Retail',
                  props.getprefer
                ),
                }
              };
            } else{
              allOrdersData = { ...allOrdersData,
                'Member Cost':utilities.getCostBasedOnnatvalue(
                  props.viewType,
                  utilities.getCostForQtyPlpNew(item, item.cart_available),
                  props.getprefer
                ),
                'Member Cost Tier Indicator': item.Member_Cost_Tier ? utilities.excelTierFormat(utilities.getCostForQtyPlpNew(item, item.cart_available, true)): '',
                'Suggested Retail': utilities.getCostBasedOnViewType(
                  props.viewType,
                  item.SuggestedRetail,
                  'Suggested Retail',
                  props.getprefer
                ),
              };
            }
            allOrdersData = { ...allOrdersData,
              'Override Retail': utilities.emptyForNull(utilities.dollarForValue(item.cart_overrideretail)),
              'Quantity': utilities.emptyForNull(item.cart_available),
              'Max Quantity': utilities.emptyStringForZero(parseInt(item.maxQty)),
              'Available': props.isShowAllWarehouse ? '' : utilities.zeroForNull(parseInt(item?.Available)),
              'Hub Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubIndictor(item),
              'Hub & Spoke Indicator': props.isShowAllWarehouse ? '' : utilities.excelHubAndSpokeIndictor(item),
              'Stock Status': utilities.emptyForNull(item.whStockStatusMessage),
              'Type (Referral/Sub/Alt)': utilities.emptyForNull(utilities.substituteItemExport(item?.alternateItem?.Label, item?.alternateItem?.Number)),
              'Referral/Sub/Alt Item #': utilities.emptyForNull(parseInt(item?.alternateItem?.Number)),
              'Restricted Product': utilities.itemRestricted(item?.Restrictions),
              'Restriction Message': utilities.itemRestrictionDetails(item?.Restrictions),
              'Assortment Item' : excelAssotmentIdentifiers(item),
              'Product Identifiers': excelProductIdentifiers(item),
              'Favorite Item': props.favorites.indexOf(parseInt(item.Item_Number)) > -1 ? 'Yes' : 'No',
              'Reason Code Description': utilities.emptyForNull(item.whStockStatusDiscMessage),           
            };
            if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
              allOrdersData = {...allOrdersData, ...{'Alternate SKU': item.Xref_ItemNbr !== '~' ? item.Xref_ItemNbr : ''}};
            }
            allOrdersToDownload.push(allOrdersData);
            return details;
          })
        })
        downloadExcel(allOrdersToDownload, fileName);
        this.setState({ loading: false });
      }).catch(error=>{
        utilities.showToast("Unexpected error occured please try after some time");
        console.log(error);
      })
    }
    else {
      utilities.showToast("Exporting to Excel...");
      let productsData = props.itemsData.map(function (product) {
        let row = {};
        row = {...row, ...{Category: product.Department,
          Class: product.Class,
          Subclass: product.SubClass,
          'Item Number': utilities.emptyForNull(parseInt(product.Item_Number))}};
        if(!utilities.isEmptyOrNullString(props.aliasSKURetailerGrpID)){
          row = {...row, ...{'Alternate SKU#': product.Xref_ItemNbr !== '~' ? product.Xref_ItemNbr : ''}};
        }
        row = {...row, ...{'Short Description': utilities.emptyForNull(product.Product_Name),
        Model: utilities.emptyForNull(product.Model),
        'Item Description': config.showMediumDesp ?
        utilities.emptyForNull((product.Product_Title || product.productTitle || product.Long_Description))
         : utilities.emptyForNull(product.Long_Description),
        Pack: `${product.S_Pack_Type ? product.S_Pack_Type : ''}${
          product.S_Pack_Qty ? product.S_Pack_Qty : ''
          }`,
        'Available': utilities.zeroForNull(parseInt(product.Available)),
        'Member Cost': utilities.getCostBasedOnnatvalue(
          props.viewType,
          utilities.getCostForQty(product, 0, true),
          props.getprefer
        )
        }};
        return row;
      });
      downloadExcel(productsData, fileName);
      this.setState({ loading: false });
    }
  };
  
  exportCart =()=> {
    this.props.loadData(this.props.index, this.props.cart)
    this.setState({loadExport: true})
  }

  render() {
    return (
      <>
        {this.state.loading && (
          <div class="exportLoader">
            <Loader active />
          </div>
        )}

        <span className={this.props.className}>
          <span>Export</span>
          <Popup
            trigger={<Icon
                    className='file excel icon large'
                    style={{ cursor: 'pointer', verticalAlign: 'initial' }}
                    onClick={() => this.props.fileName ==='cartExport' && this.props?.multiStoreCart?  this.exportCart(): this.exportToCSV(this.props,this.getRAMessage,this.raDate)}
                    ></Icon>}
            content="Export"
            size='mini'
            position='top right'
            basic
          />          
        </span>
      </>
    );
  }
}
