import React from 'react';
import './styles.css';
import renderImages from '../common/RenderImages';
import search_img from '../images/Search.svg';
import PlanogramLandingListing from './PlanogramLandingListing';
import PlanogramLandingGrid from './PlanogramLandingGrid';
import restUtils from '../../utils/restUtils';
import { connect } from 'react-redux';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { Loader } from 'semantic-ui-react';
import Filter from '../common/Filter';
import utilities from '../../utils/utilities';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import _ from 'lodash';
import {getPlanograms} from './actions';
import { isArray } from 'util';
import { planogramsListByDeptUrl, PLANOGRAM_LIST_FILTER } from '../../urlConstants';
import { updateProductListViewPreference } from '../Preferences/action';
import { bindActionCreators } from 'redux';

class Planograms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planogramsData: [],
      serverError: false,
      loading: false,
      filters: [],
      clearFilters: false,
      appliedFilters: {},
      showFilters: this.props.getprefer && this.props.getprefer.filterView ? this.props.getprefer.filterView === 'E'
        ? true : false : window.innerWidth > 768 ? true : false,
      displayType:  (this.props.getprefer && this.props.getprefer.prdctListlayoutFormat && this.props.getprefer.prdctListlayoutFormat!=='L')?'grid':'list',
      isNoProducts: false,
      seleDeptName: '',
      seleCatgName: '',
      searchType: 'ItemNumber',
      searchNumber: '',
      backPath: ''
    };
    this.filterString = {};
    this.sortParam = '';
    this.sortDirection = '';
    this.patterns = { ItemNumber: '[0-9]+', PlanogramNumber: '[a-zA-Z0-9]+', planogramSubName: '[a-zA-Z &-0-9]+' };
    this.handleSort = this.handleSort.bind(this);
    this.scrollPosition = 0;
    this.appliedFilters = {};
    this.pathname = '';
    this.searchParam = "ItemNumber";
	  this.searchNumber='';
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    } else {
      this.getPlanogramsData();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      deptName,
      searchType,
      searchNumber
    } = nextProps.match ? nextProps.match.params : this.state;
    this.filterString = {};
    this.appliedFilters = {};
    this.searchParam = "ItemNumber";
    this.searchNumber='';
    this.sortParam = '';
    this.sortDirection = '';
    this.setState({
      appliedFilters: {},
      clearFilters: false,
      searchNumber: '',
    });
    if (
      this.state.seleDeptName !== deptName ||
      this.state.searchType !== searchType ||
      this.state.searchNumber !== searchNumber
    ) {
      this.pathname = nextProps.location && nextProps.location.pathname;
      this.getPlanogramsData(nextProps);
    }
  }

  getPlanogramsData = newProps => {
    this.setState({ loading: true });
    let { deptName, catgName } = newProps ? newProps.match.params : this.props.match ? this.props.match.params : this.state;
    this.setState({ seleDeptName: deptName, seleCatgName: catgName });
    if(newProps || this.props?.match){
      deptName = decodeURIComponent(deptName);
    }
    deptName = encodeURIComponent(deptName);
    let url = `${planogramsListByDeptUrl}${this.props.storeId}&deptName=${deptName}`;
    if (catgName) {
      let catgNameEncode = encodeURIComponent(catgName);
      url = url + '&categoryName=' + catgNameEncode;
    }
    const sortParam = this.sortParam.length > 0 ? this.sortParam :'Size';
	  const sortType = this.sortDirection.length > 0 ? this.sortDirection : 'asc';
    url = url + `&SortParam=${sortParam}&SortType=${sortType}`;
    restUtils.getData(url)
      .then(response => {
        const data = response.data.departmentList[0];
        if(catgName){
          const catgNameEncoded = encodeURIComponent(catgName);
          this.filterString = '&Category=' + catgNameEncoded;
        }
        if(!_.isEmpty(this.filterString)){
          this.appliedFilters = utilities.getFilterArray(this.filterString);
        }
        this.setState({
          planogramsData: data.planogramsList,
          filters: data.filters,
          appliedFilters: this.appliedFilters,
          loading: false,
          serverError: false,
          isNoProducts: data.length === 0
        });
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  };

  setBackButtonPath = (planogram = [],fromLandingPage = false) => {
    let pathName = '';
    if(this.searchNumber.length > 0){
      pathName = pathName + '&searchBy='+this.searchParam+'='+encodeURIComponent(this.searchNumber);
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam=' + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType=' + this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    pathName = this.scrollPosition > 0 ? pathName + '&scrollPosition='+this.scrollPosition : pathName;
    if (!_.isEmpty(this.appliedFilters)) {
      pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(this.appliedFilters);
    }
    let pushUrl = '';
    if(pathName !== ''){
      pushUrl = this.pathname + '?' + pathName;
      window.history.pushState(window.history.state, "", pushUrl);
    }
    else{
      pushUrl = this.pathname;
    }
    this.setState ({backPath: pushUrl});
    if(fromLandingPage){
      planogram = { ...planogram, backPath: pushUrl }    
      this.props.getPlanograms(planogram);
    }
  }

  setScrollPosition = (planogram) => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath(planogram,true);
  }

  getUrlFilters = (queryParam) => {
    let { deptName,catgName } = this.props.match ? this.props.match.params : this.state;
    let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    if(searchParam !== ''){
      let search = searchParam.split('=');
      this.state.searchType = search[0]
      this.searchParam = search[0];
      this.searchNumber = search[1] && decodeURIComponent(search[1].split('&')[0]);
    }
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' :
                         queryParam[1].includes('SortType=desc') ? 'desc' : '';
    this.scrollPosition = queryParam[1].includes('scrollPosition') ? queryParam[1].split('scrollPosition=')[1].split('&')[0] : 0;
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    this.filterString = this.filterString.includes('&Department') ? this.filterString :
    this.filterString + "&Department=" + encodeURIComponent(deptName).split(',');
    let filterApplied = utilities.getFilterArray(this.filterString);
    this.appliedFilters = utilities.getFilterArrayDecode(this.filterString);
    this.filterString = utilities.getQueryJsonAssortment(filterApplied);
    this.setState({ seleDeptName: deptName, appliedFilters: this.appliedFilters, 
                    searchNumber: this.searchNumber, backPath: this.pathname,seleCatgName:catgName});
    this.getFilteredData();
  }

  handleSort(e) {
    this.setState({ displayType: e });
  }

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  filterCallback = (isAdd, filterKey, filterValue) => {
    let appliedFilters = this.state.appliedFilters;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: filterValue.split(',') };
    } else if (keyExists) {
      appliedFilters[filterKey] = isArray(appliedFilters[filterKey])
        ? appliedFilters[filterKey]
        : appliedFilters[filterKey].split(',');
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        value.push(filterValue);
      }
      if (!value.length && keyExists) delete appliedFilters[filterKey];
      else appliedFilters[filterKey] = value;
    }
    this.appliedFilters = appliedFilters;
    this.filterString = utilities.getQueryJsonAssortment(
      appliedFilters,
      this.state.seleDeptName,
      this.state.seleCatgName
    );
    this.setState({ appliedFilters, clearFilters: false });
    this.getFilteredData();
  };

  getFilteredData = () => {
    if(this.searchParam === 'ItemNumber' && parseInt(this.searchNumber) === 0){
      utilities.showToast('Invalid item number');
      return;
    }
    let searchArr = {};
    if (this.filterString.filterBy === undefined || this.filterString.filterBy.length === 0) {
      const queryJson = [];
      const deptArr = { name: 'Department', valueList: decodeURIComponent(this.state.seleDeptName).split(',') };
      queryJson.push(deptArr);
      if (this.state.seleCatgName) {
        const catgArr = { name: 'Category', valueList: this.state.seleCatgName.split(',') };
        queryJson.push(catgArr);
      }
      this.filterString = { filterBy: queryJson };
    }

    if(this.searchNumber){
      searchArr = { name: this.searchParam, valueList: this.searchNumber.split(",") }
    }

    const sortArr = { sortParam: this.sortParam.length > 0 ? this.sortParam :'Size', 
                      sortType: this.sortDirection.length > 0 ? this.sortDirection : 'asc' };
    let requestPayLoad = { storeId: this.props.storeId, ...this.filterString, sortBy: sortArr, searchBy: searchArr, aliasSKURetailerGrpID: this.props.aliasSKURetailerGrpID };
    this.setState({ loading: true });
    restUtils.postData(PLANOGRAM_LIST_FILTER, requestPayLoad)
      .then(response => {
        const data = response.data.departmentList[0];
        document.getElementById('search_planograms').value = '';
        this.setState({
          isNoProducts: data.planogramsList.length === 0, loading: false, serverError: false,
          planogramsData: data.planogramsList, filters: data.filters
        });
        this.setState({showFilters:true})
        for (let key in this.appliedFilters) {
          document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
        }
        this.setState({showFilters:this.props.getprefer && this.props.getprefer.filterView ? this.props.getprefer.filterView === 'E'
        ? true : false : window.innerWidth > 768 ? true : false})
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  };

  changeSortParam = e => {
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  };

  changeSortDirection = e => {
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  };

  changeSearchType = e => {
    this.searchParam = e.currentTarget.value;
    this.scrollPosition = window.pageYOffset;
    this.searchNumber = '';
    this.setState({searchType: this.searchParam, searchNumber: ''});
    this.getFilteredData();
  };

  searchItems = (e) => { 
    this.searchNumber = this.state.searchNumber;
    this.scrollPosition = window.pageYOffset;
    this.getFilteredData();
  }

  handleEnterKey = e => {
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  onFilterChange = e => {
    const { validity = {}, value } = e.target;
    this.scrollPosition = window.pageYOffset;
    if (!validity.valid) {
      e.preventDefault();
      return;
    }
    this.setState({
      searchNumber: value
    });
  };

  render() {
    const { serverError, searchType, searchNumber,displayType, seleDeptName } = this.state;
    const { multiStoreDetails } = this.props
    const planogramPageLink = '/planograms/'+seleDeptName;
    const breadcrumbPath = [{ link: '/home', text: 'HOME' },
    { link: planogramPageLink, text: seleDeptName }];
    return serverError ? (
      <InternalServerError />
    ) : (
        <div className='planograms_landing_page'>
          {this.state.loading && (
            <div>
              <Loader active />
            </div>
          )}
          <div className='page-header'>Planograms</div>
          <BreadcrumbComp path={breadcrumbPath} />
          <br />
          <br />
          <div className='sortFieldsPlanogram'>
            <div className='ui input ' id='input_search'>
              <span>
                {window.innerWidth < 768 ? 'Search' : 'Planogram Search'}
              </span>
              <select className='sort-criteria combo' onChange={this.changeSearchType} id='pl_dropdown' value={this.searchParam}>
                <option value='ItemNumber'>Item #</option>
                <option value='PlanogramNumber'>Planogram #</option>
                <option value='planogramSubName'>Description</option>
                {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && (
                   <option value={'AlternateSKU'}>Alternate SKU #</option>)}
              </select>
              <input id='search_planograms' onKeyDown={this.handleEnterKey} type='text'
                pattern={this.patterns[searchType]}
                onChange={this.onFilterChange}
                value={this.state.searchNumber}
              />
              {renderImages.getImage({ src: search_img, className: 'search_icon_address cursor_p', onClick: this.searchItems })}
            </div>
            <div className='sort_filter'>
              <span className='filter-label'>Sort By</span>
              <select className='sort-criteria combo' onChange={this.changeSortParam} id='pl_dropdown' value={this.sortParam}>
                <option value='Size'>Size</option>
                <option value='Type'>Type</option>
                {(multiStoreDetails?.access !== 1 || multiStoreDetails?.PogMbr) && 
                  <option value='Member Cost'>Member Cost</option>
                }
                <option value='Last Updated'>Last Updated</option>
              </select>
              <select className='sort-order combo' onChange={this.changeSortDirection} value={this.sortDirection}>
                <option value='asc'>Ascending</option>
                <option value='desc'>Descending</option>
              </select>

              {window.innerWidth >= 1023 && (
                <span id='icon_align'>
                  <i className='list icon cursor_p' value='List' id='list_icon' onClick={() => this.handleSort('list')}></i>{' '} |
                  <i className='th icon cursor_p' value='Grid' id='grid_icon' onClick={() => this.handleSort('grid')}></i>
                </span>
              )}
            </div>
          </div>
          <br />
          <div className={window.innerWidth > 1024 ? 'displayFlex allOrdersTable' : 'allOrdersTable'}>
          {window.innerWidth < 768 &&
              <span className='planogram_msg'>
                IMPORTANT: For first-time orders of Retail Assortments, visit Retail Assortment.
                Ordering from this planogram site will not include special discounts.
            </span>
            }
            <div style = {{flex: '1 0 20%'}}>
            <Filter
              clearFilters={this.state.clearFilters}
              filtersApplied={!_.isEmpty(this.state.appliedFilters)}
              appliedFilters={this.state.appliedFilters}
              toggleShowFilter={this.toggleShowFilter}
              showFilters={this.state.showFilters}
              filters={this.state.filters}
              callback={this.filterCallback}
              isNoProducts={this.state.isNoProducts}
              selecDept={this.state.seleDeptName}
              seleCatg={this.state.seleCatgName?this.state.seleCatgName:this.state.appliedFilters["Category"]}
              screenName={'Planograms'}
            />
            </div>
            <div className='full-width pl_data'>
            {window.innerWidth > 768 &&
              <span className='planogram_msg'>
                IMPORTANT: For first-time orders of Retail Assortments, visit Retail Assortment.
                Ordering from this planogram site will not include special discounts.
            </span>
            }
              {displayType === 'list' && window.innerWidth >= 1024 ? (
                <PlanogramLandingListing
                  planograms={this.state.planogramsData}
                  multiStoreDetails={multiStoreDetails}
                  isNoProducts={this.state.isNoProducts}
                  backPath={this.state.backPath}
                  setBackButtonPath={this.setScrollPosition}
                />
              ) : (
                  <PlanogramLandingGrid
                    planograms={this.state.planogramsData}
                    multiStoreDetails={multiStoreDetails}
                    isNoProducts={this.state.isNoProducts}
                    backPath={this.state.backPath}
                    setBackButtonPath={this.setScrollPosition}
                  />
                )}
            </div>
          </div>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    storeId: state.SessionReducer.storeId,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
  };
};

const mapDispatchToProps  = (dispatch)=>{
  return Object.assign({ dispatch }, bindActionCreators({updateProductListViewPreference,getPlanograms}, dispatch));
};

export default connect(mapStateToProps, mapDispatchToProps)(Planograms);
