import React from "react";
import utilities from "../../utils/utilities";
import { getVendorLabel } from "../ReunionEvent/AllReunion/itemListingUtilities";
import renderImages from "../common/RenderImages";
import warning from "../images/warning.png";
import { computeTotalCost, computeShipweekTotalCost, computePromoTotalCost, computeAssortmentTotalCost } from './actions';
import { isShoppingAreaCart } from './constants';


export const getPack = (product) => {
  return (
    utilities.emptyForNull(product.Pack_type || product.s_pack_type) +
    "" +
    (product.s_pack_qty || product.Pack)
  );
};

export const getDescription = (product, isRA, isCheckout = false,cartTypeId =false,isMSorReunnion =false, errorMsg =[]) => {
  return (
    <>
      <p className="no_margin">
        {(isRA
          ? product.description
          : product.product_title
          ? product.product_title
          : product.shortDescription
          ? product.shortDescription
          : product.description || " ") + " "} 
          {cartTypeId && cartTypeId === 44 && <span>{utilities.emptyForNull(product.Region)}</span>}
      </p>
      {getVendorLabel(null, product.vendorName, product.vendorId,'',isMSorReunnion)}
      {product.hazMat && product.hazMat === "Y" && (
        <p className="hazmat-indicatorcart">{"HAZMAT"}</p>
      )}
      {isCheckout && (
        <>
          {product.prop65 === "Y" && (
            <div className="warning_prop65">
              {renderImages.getImage({
                src: warning,
                className: "warning_icon",
                alt: "warn",
              })}
              <span className="warning prop65_checkout">Prop65</span>
            </div>
          )}
          {errorMsg !== "" ? <div className="checkoutErrorMsg">
             <ul>
              {errorMsg.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </div>: ""}
        </>
      )}
    </>
  );
};
export const getTierLevel = (product) => {
  const tierLevel = utilities.getTierLevelForQty(
    product,
    parseFloat(
      product.itemQty
        ? product.itemQty
        : product.Qty && product.Qty !== 0
        ? product.Qty
        : product.userQty
    )
  );
  return tierLevel;
};

export const getTierLevelNew = (product) => {
  const tierLevel = utilities.getCostForQtyPlpNew(
    product,
    parseFloat(
      product.itemQty
        ? product.itemQty
        : product.Qty && product.Qty !== 0
        ? product.Qty
        : product.userQty
    ),
    true
  );
  const tierLevelFinal = tierLevel && tierLevel.toString().includes('T') ? tierLevel.toString().split('T')[1] : tierLevel
  return tierLevelFinal;
};

export const findTotal = array => array.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

export const getTieredCost = (product) => {
  return utilities.getCostForQty(
    product,
    parseFloat(
      product.itemQty
        ? product.itemQty
        : product.Qty && product.Qty !== 0
        ? product.Qty
        : product.userQty
    ),
    false
  );
};

export const getTieredCostNew = (product) => {
  return utilities.getCostForQtyPlpNew(
    product,
    parseFloat(
      product.itemQty
        ? product.itemQty
        : product.Qty && product.Qty !== 0
        ? product.Qty
        : product.userQty ? product.userQty : product.quantity
    )
  );
};

export const totalCost = (cart) =>{
  const cost = cart.TypeId === 2
  ? computePromoTotalCost(cart.items)
  : isShoppingAreaCart(cart.cartInfoName) || cart.TypeId === 10
  ? computeShipweekTotalCost(cart.items, cart.cartInfoName)
  : cart.TypeId === 4 || cart.TypeId === 44
  ? computeAssortmentTotalCost(cart.items)
  : computeTotalCost(cart.items)
  return cost
}


export const checkCreditRedeemedByRefNum = (assortment,products) => {
  const redemmedModelNbr = getRedeemedModelNbr(assortment,products);
  return redemmedModelNbr !== '';
}
export const getAssortmentSavings = (product,products) => {
  return !utilities.isEmptyOrNullString(product.orderedDate) ||
    !utilities.isEmptyOrNullString(product.orderedModelNbr) ||
    checkCreditRedeemedByRefNum(product,products)
    ? ""
    : utilities.PercentageSavings(product.savings);
};
export const getAssortmentCreditValue = (product,products, viewType, getPrefer) =>{
  return (
   
      (!utilities.isEmptyOrNullString(product.orderedDate) || !utilities.isEmptyOrNullString(product.orderedModelNbr)
      || checkCreditRedeemedByRefNum(product,products))?
      ''
      : `${!utilities.isEmptyOrNullString(product.creditValue)? viewType && getPrefer ? utilities.getCostBasedOnnatvalue(viewType, product.creditValue, getPrefer): '$'+product.creditValue:'-'}${product.deptID && product.deptID.trim() ==='X' ? ' per bundle' : ''}`
)
}
export const showOfferendDateMsg = (product,marketDate) => {
  return utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="Y" ? 'On Going'
          : utilities.isEmptyOrNullString(product.expireddate) && product.RAInd==="N" ? marketDate
          : !utilities.isEmptyOrNullString(product.expireddate) && utilities.getDateFromTimeStamp(product.expireddate)
}
export const getRedeemedModelNbr = (assortment,products) =>{
  let redemmedModelNbr = '';
  products.forEach(product => {      
    if(product.xrefNumber === assortment.xrefNumber
          && product.assortmentType === assortment.assortmentType
          && product.creditValue >= assortment.creditValue 
          && product.number !== assortment.number) { 
            redemmedModelNbr = product.model 
          }
  });
  return redemmedModelNbr;
}

export const getRAMessage = (product,products,marketFlag, raCartScreen = false, showCreditPrice = true, viewType, getPrefer) => {
  const rebateAmount = utilities.getPricePrecision(product.creditValue);
  let creditPrice = ''
  if(raCartScreen){
    creditPrice = (!utilities.isEmptyOrNullString(product.orderedDate) || !utilities.isEmptyOrNullString(product.orderedModelNbr)
    || checkCreditRedeemedByRefNum(product,products))?
    ''
    : `${!utilities.isEmptyOrNullString(product.creditValue)? viewType && getPrefer ? utilities.getCostBasedOnnatvalue(viewType, product.creditValue, getPrefer): '$'+product.creditValue:'-'}${product.deptID && product.deptID.trim() ==='X' ? ' per bundle' : ''}`
  }
  return (
    <>
    {creditPrice && showCreditPrice &&
      <p className='no_margin'>
        {creditPrice}
      </p>}
    
      {!utilities.isEmptyOrNullString(product.orderedModelNbr) ?                                   
      <p className='no_margin credit_msg_color'>
        Not Eligible for Credit, Previously Purchased {product.orderedModelNbr}
      </p>
    : !utilities.isEmptyOrNullString(product.orderedDate) ?
      <p className='no_margin credit_msg_color'>
        Credit Redeemed {product.orderedDate +' ('+ rebateAmount+')'}
      </p>
    : checkCreditRedeemedByRefNum(product,products) ? 
      <p className='no_margin credit_msg_color'>
        Credit Redeemed by assortment {getRedeemedModelNbr(product,products)} in current order
      </p>
    : marketFlag ? showMessagesForRAmkt(product, products)
    : raCartScreen ? showMsgsbasedonQty(product) : ''}
    </>
  )
}
export const showMsgsbasedonQty = (product) => {
  const creditAmount = utilities.getPricePrecision(product && product.creditValue);
  const rebateAmount = utilities.emptyStringForZero(product && product.rebateAmount);
  let savinCalc = creditAmount && rebateAmount 
                       ? parseInt(utilities.hyphenForZero(creditAmount)) /  parseInt(rebateAmount) 
                       : '-';
  let rebateCredit = isNaN(savinCalc) ? 0 : parseFloat(savinCalc).toFixed(2);
  const  getSavings = !utilities.isEmptyOrNullString(rebateCredit) ? (
                        (parseFloat(rebateCredit) * 100).toFixed(2) + ' %'
                      ) : '';
  let qtyMsg = '';
  if (product.userQty > 1) {
    qtyMsg = <>
      <br/>
      <p><span class='credit_msg_color'>Credit and dating will only apply<br />
              to one assortment per Retail Assortment category.</span></p></>
  }
  return qtyMsg;
}

export const showMessagesForRAmkt = (product, products) => {
  const modelNbr = product.model && product.model.substring(0,2);
  const savings = utilities.PercentageSavings(product.savings);
  const rebateAmount = utilities.getPricePrecision(product.creditValue);
  return (modelNbr === "RA" || modelNbr === "MS") 
          && (product.userQty > 1 && IsAsstmtExists(products, product))
  ? <><p className="hideRaMessage">save {savings} with ${rebateAmount} credit<br/></p>
      <p><span class='credit_msg_color'> Credit and dating will only apply
        to one assortment per Retail Assortment category.</span>
    </p></>
  : product.userQty <= 1 && (modelNbr === "RA" || modelNbr === "MS")
    ? <p className="hideRaMessage">save {savings} with ${rebateAmount} credit</p> 
    : <p className="hideRaMessage">save {savings} with ${rebateAmount}</p>
  }

export const IsAsstmtExists = (products, assortment) => {
    let returnVal = false;
    products.forEach(product => {
      if(product.number === assortment.number) {
        returnVal = true;
      }
    });
    return returnVal;
  }
export const getErrorMessages = (product) => {
  // Check if the product has a checkoutValidator array
  if (!product?.checkoutValidator || !Array.isArray(product?.checkoutValidator)) {
    return []; // Return an empty array if not present
  }

  // Filter and map to extract messages with checkoutValid as false
  return product?.checkoutValidator
    .filter(item => item?.checkoutValid === false) // Filter for invalid entries
    .map(item => item?.message); // Extract the message
}
export const checkoutValidmessages = (products) => {
  return products.filter(product => Array.isArray(product?.checkoutValidator) && product?.checkoutValidator?.length > 0) // Filter products with non-empty checkoutValidator
      .map(product => product?.checkoutValidator.map(validator => validator?.message)) // Extract the messages
      .flat();
}