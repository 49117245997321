import React, { Component } from 'react';
import CartProductsTable from './CartProductsTable';
import { connect } from 'react-redux';
import history from '../../history';
import { bindActionCreators } from 'redux';
import { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts } from './actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Loader, Grid, Responsive } from 'semantic-ui-react';
import { utilities, shouldHideCostForPreference } from '../../utils/utilities';
import { restUtils } from '../../utils/restUtils';
import { RA_PLACE_ORDER_URL } from '../../urlConstants';
import { MaskPrice } from '../common/MaskPrice';
import UnderConstruction from "../UnderConstruction";
import { totalCost } from './cartUtils';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import FixtureTable from "../Fixtures/FixtureTable";
import LimitlessTable from "../Limitless/LimitlessTable";

class CheckoutRA extends Component {
  constructor(props) {
    super(props);
    const checkedoutCart = props.carts.find(cart => {
      return parseInt(cart.cartId) === parseInt(props.match.params.cartName);
    });
    const {isReunionShoppingAreaCart,flowType,isMultiStore,multiStoreCarts} = props?.location?.state;
    const currentAssortmentIds = checkedoutCart?.items?.map((i) => i?.ctb_pog_nbr) || [];
    const displayItems = checkedoutCart?.displayItems || [];   
    const filteredFixtures = checkedoutCart?.fixtures?.filter((f) =>
         currentAssortmentIds.includes(f?.pogNumber)
	     ) || [];   
    const limitlessPOG = checkedoutCart?.items?.filter((f) => f?.IsLimitless === "Y") || [];
   
    this.state = {
      cartName: _.get(props, 'match.params.cartName', ''),
      cart: checkedoutCart,
      isLoading: false,
      isError: false,
      errorMsg: '',
      tstmsg: '',
      disablePlaceorderbtn: this.props.address.viewOrders,
      isReunionShoppingAreaCart,
      flowType,
      cartSelected:[],
      isMultiStore : isMultiStore,
      multiStoreCarts : multiStoreCarts,
      isOrderWindowClosed : false,
      isCtbAssortment: this.props.location?.state && this.props.location?.state?.isCtbAssortment ? this.props.location?.state?.isCtbAssortment : false,
      fixtures: filteredFixtures || [],
	    selectedFixtureIds: [],
      limitlessPlanogram:limitlessPOG || [],
      selectedLimitlessId:[],
      displayItemsLimitless: displayItems || [],
      displayItemsValues:[]
    };
  }
  componentDidMount(){
    if(this.props.address.viewOrders === true){
      utilities.showToast(`Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.`, true);
    }
  }
  placeOrderRA = (multiStoreCarts = [],isMultiStore) => {
    const { cart, isReunionShoppingAreaCart,flowType } = this.state;
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }else{
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(msDetails => msDetails?.items?.length>0);
    }      
    this.setState({ isLoading: true});
    const { userName, storeId, storeRDC } = this.props;
    const url = RA_PLACE_ORDER_URL;
    /** Limitless Plangoram Merge Array object and Get Payload details */
    const poghArry = [];
    const displayArry=[];
    const limitlessPogs = this.state.limitlessPlanogram?.reduce((acc, _fixture) => {
      if(this.state.selectedLimitlessId.includes(String(_fixture?.number))) {
        acc.push(_fixture);
      }
      return acc;
    }, []);        
    limitlessPogs.map((item,index)=> {        
        return poghArry.push({"assmtNbr":item["number"],"pognbr":item["ctb_pog_nbr"],"model":item["model"]});        
    })     
    const pogDisplayValue = Object.entries(this.state.displayItemsValues);
    pogDisplayValue.map((item,index)=> {  
      return displayArry.push({"assmtNbr":item[0],"displayItemsId":item[1]});    
    })
    const mergeArrays = (arr1 = [], arr2 = []) => {
      let res = [];
      res = arr1.map(obj => {
        const index = arr2.findIndex(el => el["assmtNbr"] == obj["assmtNbr"]);
        const { displayItemsId } = index !== -1 ? arr2[index] : {};
        return {
            ...obj,
            displayItemsId
        };
      });
      return res;
    };         
  const selectLimitlessPOGIds =  mergeArrays(poghArry, displayArry);
  /** Limitless Program code END */
    if(multiStoreCarts.length === 0)
      multiStoreCarts = [cart];
    let reqPayload = multiStoreCarts?.map(cart => {
      const assortmentOrders = cart?.items?.map((item) => {
        return {
          memberNbr: storeId,
          rdcType: storeRDC,
          releaseDate: item.rlsDate,
          molUserName: userName,
          itemNbr: item.number,
          itemQty: item.userQty,
          shoppingcartId: cart.cartId
        }
      });
      const selectedFixtures = this.state.fixtures?.reduce((acc, _fixture) => {
        if(this.state.selectedFixtureIds.includes(String(_fixture?.id))) {
          acc.push(_fixture);
        }
        return acc;
      }, []);      
      return { 
              storeId: cart?.storeId? cart.storeId :storeId,
              cartName:cart.TypeId === 44 ? 'CTBAssort':cart.cartName,
              cartInfoid : cart.TypeId,        
              assortmentOrders,
	            fixtures: selectedFixtures,
              displayItems:selectLimitlessPOGIds  //Limitless POG Planogram Payload details
            };
    }) 
    restUtils.postData(url, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        }
        else {
          history.push({
            pathname: `/orderConfirmation/${this.state.cartName}`,
            state: {
              cart: multiStoreCarts,
              ...this.state.cart,
              poNum: cart.cartName,
              orderTotal: totalCost(cart),
              isReunionShoppingAreaCart,
              flowType:this.state.flowType,
              fixtures: this.state.fixtures,
	            selectedFixtureIds: this.state.selectedFixtureIds,
              limitlessPOG:this.state.limitlessPlanogram,
              selectedLimitlessId:selectLimitlessPOGIds,
              displayItems:this.state.displayItemsLimitless,
              displayItemsLimitless:this.state.displayItemsLimitless
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      if(err?.response?.status === 412){
        this.setState({ isOrderWindowClosed : true, disablePlaceorderbtn: false, isLoading: false });
      }else{
        utilities.showToast(`Failed to Submit order. Please try later`, true);
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
      }     
    });
  }

  cancelOrder = (cartId) => {
    history.push('/cart');
    this.props.deleteCart(cartId);
    this.props.notifyCarts();
  };

  toastDelay = (centretoast) => {
    this.setState({ tstmsg: centretoast });
    setTimeout(() => {
      this.setState({ tstmsg: '' });
    }, 5000);
  }

  placeOrderAll = () => {
    const { multiStoreCarts } = this.state;
    if(this.state.isCtbAssortment && this.isLimitlessTableAvailable() && this.state.selectedLimitlessId.length !== this.state.limitlessPlanogram.length){
      this.toastDelay(utilities.centreshowToast('Please select one display option for Limlitless POG on order to submit',true));
      return;
    } else {
    this.placeOrderRA(multiStoreCarts,true);
    }
  }

  placeOrderSelected = () => {
    let { multiStoreCarts } = this.state;
    let cartSelected = this.state.cartSelected;
    if(cartSelected && cartSelected.length > 0){
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(cart =>
                                      cartSelected.includes(cart.storeId))
      if(this.state.isCtbAssortment && this.isLimitlessTableAvailable() && this.state.selectedLimitlessId.length !== this.state.limitlessPlanogram.length) {
        this.toastDelay(utilities.centreshowToast('Please select one display option for Limlitless POG on order to submit',true));
        return;
      }   else {                             
        this.placeOrderRA(multiStoreCarts,true);
      }
    }else{
      this.toastDelay(utilities.centreshowToast('Please select atleast one order to submit',true));
      return;
    }
  }

  handleStoreCheck= (storeId) => {
    let {cartSelected} = this.state
    if(cartSelected.includes(storeId)){
      cartSelected = cartSelected.filter(item => item !== storeId)
    }else{
      cartSelected.push(storeId)
    }
     this.setState({
      cartSelected
     })
  }

  updateFixtureSelection = (selections) => {
    const keys = Object.keys(selections) || [];
    const ids = keys.map((k) => {
      const t = k?.split("-");
      if (t.length > 1) {
        return t[1];
      }
      return k;
    });
    this.setState({
      selectedFixtureIds: ids,
    });
  };
  /** Limitless POG Selection Code */
  updateLimitlessSelection = (selections) => {
    const keys = Object.keys(selections) || [];
    const ids = keys.map((k) => {
      const t = k?.split("-");
      if (t.length > 1) {
        return t[1];
      }
      return k;
    });
    this.setState({
      selectedLimitlessId: ids,
      displayItemsValues:selections
    });     
  };
   /** Limitless POG Selection Code End */
  isFixtureTableAvailable = () => {
    const neededPromptFixtures = this.state.fixtures?.filter((f) => f?.needed_prompt) || [];
    return this.state.isCtbAssortment && neededPromptFixtures.length > 0;
  };
  /** Limitless POG Filter IsLimitless === Y  Code Start */
  isLimitlessTableAvailable = () => {
    const neededLimitlessPOG = this.state.limitlessPlanogram?.filter((f) => f?.IsLimitless === "Y") || [];
    return this.state.isCtbAssortment && neededLimitlessPOG.length > 0;
  };  
  /** Limitless POG Filter IsLimitless === Y  Code End */
  render() {  
    const { cart, isMultiStore, isOrderWindowClosed} = this.state;
    const  getCheckoutPrefer = this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1'
  	const  hideTotalExtendCost = shouldHideCostForPreference(this.props.viewType,getCheckoutPrefer);
    let {multiStoreCarts} = this.state;
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    if(!isMultiStore){  
      multiStoreCarts = [cart];
    } 
    return (
      <>
      {isOrderWindowClosed  ? (
        <UnderConstruction
        eventMessage={'Ordering Window has Ended'}
        shoppingAreaName={cart?.DisplayName}
        />
      ) :( 
      <div className='page-wrap'>
        <div className='page-header'>Checkout {cart ? `${cart.cartName}-${cart.cartId}` : ''}</div>
        { this.state.disablePlaceorderbtn && <div className='Note-Message'>Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.</div>}
        {!cart ? (
          <h3>
            Order already placed!{' '}
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </h3>
        ) : this.state.isError ? (
          <div className='checkoutErrorBacttoCart'>
            <h3>
              {this.state.errorMsg ? this.state.errorMsg : 'Checkout failed!'}
            </h3>
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </div>
        ) : (
              <>
                <div className='checkout-table'>
                  {this.state.isLoading && (
                    <div class="loaderWrapper">
                    <Loader active />
                    <p>Please wait while we process your order... <br/> Please don’t press the Back Button while your order is processing </p>
                    </div>
                  )}
                   {isMultiStore &&
                  <>
                    <div className='t-right'> 
                      <Link to='/cart' className='multi-store-back-to-cart'>
                        Back to Cart
                      </Link>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderSelected()} disabled={this.state.disablePlaceorderbtn}>
                        Place Selected Orders
                      </button>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderAll()} disabled={this.state.disablePlaceorderbtn}>
                        Place All Orders
                      </button>
                    </div>    
                    <br/>                  
                  </>}
                  {multiStoreCarts.map(cart => 
                  <>
                  {isMultiStore &&  cart?.items?.length > 0 &&
                  <>
                    <div className='cart-accordion accordion-header t-center'>
                      <span>
                        {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, cart.userId)}
                      </span>
                      <span className='Multi-cart-accordion-store-select'>
                      {'Select Store '} <input
                        type='checkbox'
                        name = {'storeCheck'+cart.userId}
                        onChange={() => this.handleStoreCheck(cart.storeId)}
                      />
                      </span>
                    </div>
                  </>
                  }
                   {cart?.items?.length > 0 && 
	                    <>
                  <Responsive minWidth='1025'>
                    <div className='place-orderRA'>
                      <div>
                      {!isMultiStore && <Link to='/cart' className='back-to-cart'>
                          Back to Cart
                        </Link>}
                        <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}>
                          Cancel Order
                        </button>
                        {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                          || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                          || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                          || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                          || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                          || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                          && !this.props.hq && this.props.allowedorder)
                          ? !this.props.isProspectStore ?
                           <button className='ui red button place-order-btn' onClick={()=>
                           { 
                            if(this.state.isCtbAssortment && this.isLimitlessTableAvailable() && this.state.selectedLimitlessId.length !== this.state.limitlessPlanogram.length) {
        this.toastDelay(utilities.centreshowToast('Please select one display option for Limlitless POG on order to submit',true));
        return;
      }   else { 
                            this.placeOrderRA([multiStoreCarts.find(carts=> parseInt(carts.cartId)===parseInt(cart.cartId))],true) }}} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                           :<> 
                           <button className='ui red button place-order-btn' disabled> Place Order </button>
                           <p className="disabledMessage">*Ordering restricted</p>
	                           </>
                          : ''
                        }
                      </div>
                    </div>
                  </Responsive>
                  <Responsive maxWidth='1024'>
                    <div className='place-orderRA'>
                    <Link to='/cart' className='back-to-cart'> Back to Cart </Link>
                      <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}> Cancel Order </button>
                      {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                        || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                        || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                        || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                        || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                        || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                        && !this.props.hq && this.props.allowedorder)
                        ? !this.props.isProspectStore ?
                         <button className='ui red button place-order-btn' onClick={()=>
                          { if(this.state.isCtbAssortment && this.isLimitlessTableAvailable() && this.state.selectedLimitlessId.length !== this.state.limitlessPlanogram.length) {
                            this.toastDelay(utilities.centreshowToast('Please select one display option for Limlitless POG on order to submit',true));
                            return;
                          }   else { 
                          this.placeOrderRA([multiStoreCarts.find(carts=> parseInt(carts.cartId)===parseInt(cart.cartId))],true)
                        } } } disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                         :<> 
                           <button className='ui red button place-order-btn' disabled> Place Order </button>
                           <p className="disabledMessage">*Ordering restricted</p>
	                         </>
                        : ''
                      }
                    </div>
                  </Responsive>
                  </>
                  }
                  
                  <>
                    <CartProductsTable
                      cart={cart}
                      products={cart && cart.items}
                      isCheckout={true}
                      userId = {cart.userId}
                    />
                    {this.isFixtureTableAvailable() && (
                             <>
                               <div style={{ fontWeight: 600 }}>
                                Please indicate below the Optional Free With CTB fixtures you would like to Receive.
                               </div>
                               <FixtureTable
                                 noIndicators={true}
                                 fixtures={this.state.fixtures}
                                 updateFixtureSelection={
                                   this.updateFixtureSelection
                                 }
                               />
                               <br />
                               <br />
                             </>
	                           )}                            
                    {this.isLimitlessTableAvailable() && (
                             <>
                               <div style={{ fontWeight: 600 }}>
                                  Limitless Planogram
                               </div>
                               <LimitlessTable
                                 limitlessPlanogram={this.state.limitlessPlanogram}
                                 isDisplayItemLimitless = {this.state.displayItemsLimitless}
                                 updateLimitlessSelection={
                                   this.updateLimitlessSelection
                                 }
                               />
                               <br />
                               <br />
                             </>
	                           )}
                     {cart?.items?.length > 0 &&
                     <>
                    <Responsive minWidth='1025'>
                      <Grid columns={3} id='shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                          <div>
                            <span>Ship to:</span>
                            <span className='RAshipping'>Store</span>
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Regular Delivery </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column id='RA'>
                          <div className='column RAmessage t-right'>
                            <p >
                              {' '}
                                  Prices are subject to change. Items will be priced
                                  at the time the order is fulfilled, not at the
                                  time of order placement.
                                </p>
                          </div>
                          <br />
                          { hideTotalExtendCost ?
                          <div className="extended">
                            <div className='store-combo-div totalCost'>
                              {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={this.props.viewType}
                                fieldVal={totalCost(cart)}
                                field='cart-totals'
                              />}
                            </div>
                          </div> : "" }
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                    <Responsive maxWidth='1024'>
                      <Grid id='shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                          <div>
                            <span>Ship to:</span>
                            <span className='RAshipping'>Store</span>
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Regular Delivery </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column className='Ramobile_checkout'>
                          <div>
                            <p> {' '}
                                Prices are subject to change. Items will be priced
                                at the time the order is fulfilled, not at the
                                time of order placement.
                              </p>
                          </div>
                          <br />
                          { hideTotalExtendCost ?
                          <div className="extended">
                            <div className='store-combo-div totalCost'>
                              TOTAL EXTENDED COST{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={this.props.viewType}
                                fieldVal={totalCost(cart)}
                                field='cart-totals'
                              />}
                            </div>
                          </div> : "" }
                        </Grid.Column>
                      </Grid>
                    </Responsive>
                    </>
  }
  
                  </>
                  </>
               )} 
                  <div id="centresnackbar" className={this.state.tstmsg && `show`} >{this.state.tstmsg}</div>
                </div>
              </>
            )}
      </div>
      )}
      </>
    );
  }
}

const mapStateToProps = state => {
  let carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    carts,
    userName: state.SessionReducer.userName,
    getprefer: state.preference.listData,
    address: state.SessionReducer.address,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    storeRDC: state.SessionReducer.rdc,
    allowedorder: state.SessionReducer.address.allowedorder,
    hq: state.SessionReducer.address.hq,
    isProspectStore : state.SessionReducer.address.isProspectStore
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutRA);
