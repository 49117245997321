import { isArray } from 'util';
import React from 'react';
import { Popup } from 'semantic-ui-react';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../components/NavBar/menuConstants';
import _ from 'lodash';
import { assortmentImpulepdf, assortmentPlanopdf} from "../urlConstants";
import store from '../configureStore';
import { logout } from '../views/Login/actions';
import { setLoader } from './actions';
import jwt from 'jsonwebtoken';
import { downloadExcel } from '../views/common/GenericExcelExport/exportUtils';
import { TierIcon } from '../views/common/TierIcon';
import { MaskPrice } from '../views/common/MaskPrice';
import { customerViewConstants, memberViewConstants } from '../views/common/constants';
import {whsStockCode} from '../views/common/constants';
import moment from 'moment-timezone';
import {  
  DISCONTINUED,CLOSEOUTS,HOTDEALS
} from '../views/Categories/constants';

const allRegionStores = ["00001"];
function reverseString(str) {
  return str?
              str
              .split('')
              .reverse()
              .join('')
            :'';
}

export function maskPrice(price,maskPattern) {
  if(price) {
    price = price.split('.');
    const maskpattrn = maskPattern?maskPattern:'N';
    return reverseString(price[1]) + maskpattrn + reverseString(price[0]);
  }
}

export const utilities = {
  titleCase: str => {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  },

  validateUrl: url =>{
    const regex = new RegExp(/^(http|https):\/\/[^ "]+$/);
    return regex.test(url)
  },

  getCurrDate: () => {
    let tempDate = new Date();
    return (
      tempDate.getMonth() +
      1 +
      '-' +
      tempDate.getDate() +
      '-' +
      tempDate.getFullYear()
    );
  },
  getCurrDateYearFirst: () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  },
  isPastDate: (date) => {
    if(!date){
      return false;
    }
    let pickedDate = new Date(date).toLocaleString();
    let today = new Date().toLocaleString();
    return pickedDate<=today;
  },
  replaceAll: (string,find,replaceStr)=>{
    if(!string || string === null){
      return '';
    }
    return string.toString().replace(new RegExp(find,'g'),replaceStr)
  },
  replaceSpace:(string)=>{
    if(!string || string === null){
      return '';
    }
     return string.toString().replace(/%20/g, " ")
  },
  getShowImg: (getprefer) => {
    let ShowHideImage = getprefer?.thumbnailImage &&
        getprefer.thumbnailImage !== 'Y'
        ? 'Show Images'
        : 'Hide Images';
    return ShowHideImage;
  },
  
  getShowFilters: (getprefer) => {
      let  ShowFilters = getprefer?.filterView
          ? getprefer.filterView === 'E'
              ? true
              : false
          : window.innerWidth <= 768
              ? false
              : true;
      return ShowFilters;
  },

  getResultperPage:(getPrefer)=>{
    let resultPerPage = getPrefer?.resultPerPage || getPrefer?.resultPerPage !== 0
    ? getPrefer?.resultPerPage
    : parseInt(50);
   return resultPerPage;
  },

  getViewType: (getprefer) => {
      let  ViewType = getprefer?.prdctListlayoutFormat &&
          getprefer?.prdctListlayoutFormat === 'L' ? 'list'
          : getprefer?.prdctListlayoutFormat === 'G' ? 'grid'
          : getprefer?.prdctListlayoutFormat === 'C' && 'classic'
      return ViewType;
  },

  getGridView: (getprefer) => {
    return (getprefer?.prdctListlayoutFormat &&
      getprefer.prdctListlayoutFormat !== 'L');
  },

  getPreferenceHideMask: (getprefer) => {
    let  preferenceView = getprefer?.customerView
    ? getprefer?.customerView
    : 'c1'
    return preferenceView;
},

totalQtyForMultipleShipWeeks: (cart) =>{
  let {items=[]} = cart;
  return items.reduce((qty1,item2)=>{
    const qty = utilities.zeroForNull(item2.userQty) + utilities.zeroForNull(item2.userQty2) + utilities.zeroForNull(item2.userQty3) + utilities.zeroForNull(item2.userQty4);
    return qty1+qty;
  },0)
},

  getCostBasedOnnatvalue: (viewType, price,getprefer) => {
    const CostWithDigits = price ? Number(price).toFixed(2) : '';
    if(viewType !=='Member View' && (getprefer === 'c1' || getprefer === 'c2')){
      return  maskPrice(CostWithDigits);
    }
    if(viewType !=='Member View' && (getprefer === 'c3' || getprefer === 'c4')){
      return "";
    }
    return  CostWithDigits && CostWithDigits != 'NaN' && parseFloat(CostWithDigits) !== 0 ? '$' + utilities.toIntlNumberFormat(CostWithDigits) : ''
  },

  refInclude:val=>{
    if(val?.indexOf('(REF)') !== -1){
      return  val.split('(REF)')[0];
    }
    else if(val?.indexOf('(SUB)') !== -1){
      return val.split('(SUB)')[0];
    }
      return val;
  },

  getCostBasedOnViewType: (viewType, price,field,getprefer,maskPattern) => {
    const CostWithDigits = Number(price).toFixed(2);
    const isMC = field === 'Member Cost'|| field==='cart-totals' || field==='Member Cost black' || field==='Memeber Missing Cost' || field ==="Credit Amount" || field ==="Mask Price" || field ==="Regular Cost";
    const isRP = field === "Suggested Retail";
    const isSP = field === "Saving Cost";
    const formattedCost = utilities.toIntlNumberFormat(CostWithDigits);
    if(getprefer === 'c1' && viewType !==MEMBER_VIEW ){
      return isMC || isSP ? maskPrice(CostWithDigits) :'$' + formattedCost;
    }
    if(getprefer === 'c2' && viewType !== MEMBER_VIEW){
      return isMC || isRP || isSP ? maskPrice(CostWithDigits) : maskPrice(CostWithDigits);
    }
    if(getprefer === 'c3' && viewType !== MEMBER_VIEW ){
      return isMC || isRP ? '' : '';
    }
    if(getprefer === 'c4' && viewType !== MEMBER_VIEW ){
      return isMC ? '' : '$' +formattedCost;
    }
    return viewType === MEMBER_VIEW
      ? ( formattedCost ? '$' + formattedCost : '')
      : maskPrice(CostWithDigits,maskPattern);
	  
  },
  isAllRegionStore:(storeId)=>{
     return allRegionStores.includes(storeId);
  },
  getCurrentTimeInCST() {
    return (
      new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }) +
      ' CST'
    );
  },

  changePriceToNumber: price => {
    return price ? '$' + Number(price).toFixed(2) : '$0';
  },

  changePriceToNumberFormat: price => {
    return price ? '$' + Number(price).toFixed(2) : '-';
  },
 
  changePriceToNumberEmpty: price => {
    return price ? '$' + Number(price).toFixed(2) : '';
  },
  
  changePriceToDecimalNumber: price => {
    return price ? '$' + Number(price).toFixed(2) : '$0.00';
  },

  changeNegativePriceToNumber: price => {
    const formatPrice = price ? price.toString().replace('-','') : 0
    return formatPrice ? '$' + Number(formatPrice).toFixed(2) : '$0.00';
  },
 
  emptyPriceCheck: price => {
    const fieldValueCheck = price && price?.props?.fieldVal && price?.props?.fieldVal !== 'NaN' ? true : false;
    const childFieldValueCheck = price && price?.props?.children && price?.props?.children[0]?.props?.fieldVal && price.props.children[0].props.fieldVal !== 'NaN' ? true : false;
    return fieldValueCheck || childFieldValueCheck ? price : '-';
  },
  
  getQueryStr: obj => {
    let queryStr = '';
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (isArray(obj[property]))
          queryStr = queryStr + '&' + property + '=' + obj[property].join(',');
        else queryStr = queryStr + '&' + property + '=' + obj[property];
      }
    }
    return queryStr;
  },
  getDOMQueryStr: obj =>{
    let queryStr = '';
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (isArray(obj[property])){
          queryStr = queryStr + obj[property].join(',')+',';
        }else
            queryStr = queryStr + obj[property];
      }
    }
    return decodeURIComponent(queryStr);
  },

  msExist:(msDetails)=>{
    return !_.isEmpty(msDetails?.childStoreList) 
      && Object.keys(msDetails?.childStoreList)?.length;
  },
  
  shipDateVendorAutoDrop:(product,qa)=>{
    let shipDate = [];
      if(product?.shipDate1){
        shipDate=  {...shipDate, 'shipdate1':qa?.[product?.shipDate1]}
      }
      if(product?.shipDate2){
        shipDate=  {...shipDate, 'shipdate2':qa?.[product?.shipDate2]}
      }
      if(product?.shipDate3){
        shipDate=  {...shipDate, 'shipdate3':qa?.[product?.shipDate3]}
      }
      if(product?.shipDate4){
        shipDate=  {...shipDate, 'shipdate4':qa?.[product?.shipDate4]}
      }
      return  shipDate;
  
  },


  shipDateVendorAuto:(product,qa)=>{
    let shipDate = [];
    if(product?.shipDate1){
      shipDate=  {...shipDate, 'shipdate1':qa?.[0]}
    }
    if(product?.shipDate2){
      shipDate=  {...shipDate, 'shipdate2':qa?.[1]}
    }
    if(product?.shipDate3){
      shipDate=  {...shipDate, 'shipdate3':qa?.[2]}
    }
    if(product?.shipDate4){
      shipDate=  {...shipDate, 'shipdate4':qa?.[3]}
    }
    return  shipDate;
  },
  
  shipDate:product=>{
    let shipDate = [];
    if(product?.shipDate1){
      shipDate=  {...shipDate, 'shipdate1':utilities.getDateFromTimeStampShip(product.shipDate1)}
    }
    if(product?.shipDate2){
      shipDate=  {...shipDate, 'shipdate2':utilities.getDateFromTimeStampShip(product.shipDate2)}
    }
    if(product?.shipDate3){
      shipDate=  {...shipDate, 'shipdate3':utilities.getDateFromTimeStampShip(product.shipDate3)}
    }
    if(product?.shipDate4){
      shipDate=  {...shipDate, 'shipdate4':utilities.getDateFromTimeStampShip(product.shipDate4)}
    }
    return  shipDate;
  },

  shipDateVendor:product=>{
    let shipDate = [];
    if(product?.shipDate1){
      shipDate=  {...shipDate, 'shipdate1':product.shipDate1}
    }
    if(product?.shipDate2){
      shipDate=  {...shipDate, 'shipdate2':product.shipDate2}
    }
    if(product?.shipDate3){
      shipDate=  {...shipDate, 'shipdate3':product.shipDate3}
    }
    if(product?.shipDate4){
      shipDate=  {...shipDate, 'shipdate4':product.shipDate4}
    }
    return  shipDate;
  },
   
  getFilterArray: queryStr => {
    let obj = {};
    let filterString = queryStr.split('&');
    filterString.map((filter, index) => {
       if(index > 0) {
        let fieldName = decodeURIComponent(filter.split('=')[0]);
        let fieldValue = filter.split('=')[1];
        obj = { ...obj, [fieldName]: decodeURIComponent(fieldValue) }; 
       }
       return filter; // added this so the linter doesn't throw any errors. TODO: map should always return something to make an array, we should modify this  and use the 'Array.reduce' function
    });
    return obj;
  },
  getFilterListFromObject : appliedFilters =>{
    let filterList = [];
    for(let name in appliedFilters) {
      if (appliedFilters.hasOwnProperty(name)) {
        filterList.push({
          name,
          valueList: appliedFilters[name]
        })
      }
    }
    return filterList;
  },
  getFilterArrayDecode: queryStr => {
    let obj = {};
    let filterString = queryStr.split('&');
    filterString.map((filter, index) => {
       if(index > 0) {
        let fieldName = decodeURIComponent(filter.split('=')[0]);
        let fieldValue = decodeURIComponent(filter.split('=')[1]);
        obj = { ...obj, [fieldName]: fieldValue }; 
       }
       return filter; // added this so the linter doesn't throw any errors. TODO: map should always return something to make an array, we should modify this  and use the 'Array.reduce' function
    });
    return obj;
  },


  getFilterArrayAsObject: queryStr => {
    let obj = {};
    let filterString = queryStr.split('&');
    filterString.map((filter, index) => {
       if(index > 0)
       {
        obj = { ...obj, [decodeURIComponent(filter.split('=')[0])]: [decodeURIComponent(filter.split('=')[1])] }; 
       }
       return filter; // added this so the linter doesn't throw any errors. TODO: map should always return something to make an array, we should modify this  and use the 'Array.reduce' function
    });
    return obj;
  },

  getFilterArrayAsMultiSelectObject: queryStr => {
    let obj = {};
    let filterString = queryStr.split('&');
    filterString.map((filter, index) => {
       if(index > 0)
       {
        let valueList = [];
        let fieldValue = filter.split('=')[1].split(',');
        if (isArray(fieldValue)) {
          valueList = fieldValue.map((value) => {
            return decodeURIComponent(value);
          })
        }else{
          valueList = [fieldValue];
        }    
        obj = { ...obj, [decodeURIComponent(filter.split('=')[0])]: valueList }; 
       }
       return filter; // added this so the linter doesn't throw any errors. TODO: map should always return something to make an array, we should modify this  and use the 'Array.reduce' function
    });
    return obj;
  },

  getQueryStrForFilterString: obj => {
    let queryStr = '';
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (isArray(obj[property])) {
          let valueList = '';
          obj[property].forEach((value,index) => {
            valueList = index > 0 ? valueList + ',' : valueList;
            valueList = valueList + encodeURIComponent(value);
          })
          queryStr = queryStr + '&' + encodeURIComponent(property) + '=' + valueList;
        }          
        else queryStr = queryStr + '&' + encodeURIComponent(property) + '=' + encodeURIComponent(obj[property]);
      }
    }
    return queryStr;
  },
  getQueryStrForCtbFilterString: obj => {
    let queryStr = '';
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (isArray(obj[property])) {
          let valueList = '';
          obj[property].forEach((value,index) => {
            valueList = index > 0 ? valueList + '|' : valueList;
            valueList = valueList + encodeURIComponent(value);
          })
          queryStr = queryStr + '&' + encodeURIComponent(property) + '=' + valueList;
        }          
        else queryStr = queryStr + '&' + encodeURIComponent(property) + '=' + encodeURIComponent(obj[property]);
      }
    }
    return queryStr;
  },

  getQueryJson: obj => {
    let queryJson = [];

    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        let filter = {};
        filter.criterionName = property;
        if (isArray(obj[property]))
          filter.criterionValue = obj[property].join(',');
        else filter.criterionValue = obj[property];
        queryJson.push(filter);
      }
    }
    return { filterBy: queryJson };
  },

  getQueryStrFromJson: (queryJson, screenName) => {
    let obj = {};
    for (let index in queryJson.filterBy) {
      let filter = queryJson.filterBy[index];
      screenName === 'editOrders' ? obj = { ...obj , [filter.criterionName] : decodeURIComponent(filter.criterionValue) } 
      : obj = { ...obj , [filter.name] : filter.valueList }
    }
    return obj;
  },
  
  getScanFilterValue: (value, flowType) => {
    let obj = {}
    let key = 'Vendor Name'
    switch(parseInt(flowType)) {
      case 3:
        key = 'Vendors'
      break;
      case 5:
        key = 'Vendor'
      default:
        key = key
    }
    obj = {
      name: key,
      valueList: [value]
    }
    return [obj]
  },
  isValidQuantity: qty => {
    if (!qty || qty.length === 0 || parseInt(qty) < 0) {
      return false;
    }
    return true;
  },

  isValidOr: or => {
    if (or && /^(\d{0,5}\.\d{0,2}|\d{0,5})$/.test(or)) {
      return true;
    }
    return false;
  },

  isValidslash: or => {
    if (or && or.match(/^[A-Za-z0-9 ]+$/) ){
      return true;
    }
    return false;
  },

  getFilterForRequest : (obj) => {
    let finalObj = {}
    Object.keys(obj).forEach((key, index)=> {
      finalObj[key] = obj[key].split(",")
    })
    return finalObj;
  },

  getQueryJsonAssortment: (obj, seledept, seleCatg,selectVendor,isMonth) => {
    let queryJson = [];
    if (!obj.hasOwnProperty('Department') && seledept) {
      const deptArr = { name: 'Department', valueList: seledept.split(',') };
      queryJson.push(deptArr);
    }
    if (!obj.hasOwnProperty('Category') && seleCatg) {
      const catgArr = { name: 'Category', valueList: seleCatg.split(',') };
      queryJson.push(catgArr);
    }
    if (!obj.hasOwnProperty('Vendor Name') && selectVendor) {
      const vendorArr = { name: 'Vendor Name', valueList: [selectVendor] };
      queryJson.push(vendorArr);
    }
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        let filter = {};
        filter.name = property;
        let filterValue = isArray(obj[property]) ? obj[property] : 
                            obj[property].indexOf(',') > - 1 ? obj[property].split(',') : decodeURIComponent(obj[property]);
        let value = [];
        if(isArray(filterValue)){
          filterValue.forEach((element) => {
            value.push(decodeURIComponent(element))
          }) 
          filter.valueList = value;
        }
        else filter.valueList = [filterValue];
        queryJson.push(filter);
      }
    }
    return isMonth ? queryJson : { filterBy: queryJson };
  },
  getQueryJsonCtbAssortment: (obj, seledept, seleCatg,selectVendor,isMonth) => {
    let queryJson = [];
    if (!obj.hasOwnProperty('Department') && seledept) {
      const deptArr = { name: 'Department', valueList: seledept.split('|') };
      queryJson.push(deptArr);
    }
    if (!obj.hasOwnProperty('Category') && seleCatg) {
      const catgArr = { name: 'Category', valueList: seleCatg.split('|') };
      queryJson.push(catgArr);
    }
    if (!obj.hasOwnProperty('Vendor Name') && selectVendor) {
      const vendorArr = { name: 'Vendor Name', valueList: [selectVendor] };
      queryJson.push(vendorArr);
    }
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        let filter = {};
        filter.name = property;
        let filterValue = isArray(obj[property]) ? obj[property] : 
                            obj[property].indexOf('|') > - 1 ? obj[property].split('|') : decodeURIComponent(obj[property]);
        let value = [];
        if(isArray(filterValue)){
          filterValue.forEach((element) => {
            value.push(decodeURIComponent(element))
          }) 
          filter.valueList = value;
        }
        else filter.valueList = [filterValue];
        queryJson.push(filter);
      }
    }
    return isMonth ? queryJson : { filterBy: queryJson };
  },
  getTierLevelForQtyExcel: (product, qty, tableType, viewType, getprefer) => {
    if(viewType !=='Member View' && (getprefer === 'c3' || getprefer === 'c4')){
      return "";
    }
    if(qty && parseInt(qty) > 0){
      return `T${utilities.getTierLevelForQty(product, qty, tableType)}`
    } else {
      return product?.Member_Cost_Tier
    }
  },

  getTierLevelForQtyPlp: (product, qty, tableType) => {
    if(qty && parseInt(qty) > 0){
      return utilities.getTierLevelForQty(product, qty, tableType)
    } else {
      return product?.Member_Cost_Tier
    }
  },
  
  getTierLevelForQty: (product, qty, tableType) => {
    if (!product.tiered) {
      return null;
    }
    qty = parseInt(qty);
    let tierLevel = 1;
    if(product.ME_qty == 0){
      tierLevel = 2;
    } 
    if (isNaN(qty)) {
      return tierLevel;
    }
    if (product.cost && parseFloat(product.cost) !== 0 && qty >= product.MM_qty) {
      tierLevel = 2;
    }
    if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      tierLevel = 3;
    }
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
      tierLevel = 4;
    }
    return tierLevel;
  },

  getTierLevelForQtyHotDeals: (product, qty) => {
    let productPricing = (product && product.pricing) ? product.pricing : product;
    if (!productPricing.tiered) {
      return null;
    }
    qty = parseInt(qty);
    let tierLevel = 2;
    if (isNaN(qty)) {
      return tierLevel;
    }
    if(productPricing.ME_Cost 
        && parseFloat(productPricing.ME_Cost) !== 0 && qty >= productPricing.ME_qty) {
      tierLevel = 1;
    }
    if (productPricing.cost 
          && parseFloat(productPricing.cost) !== 0 && qty >= productPricing.MM_qty) {
      tierLevel = 2;
    }
    if (productPricing.MC_Cost 
          && parseFloat(productPricing.MC_Cost) !== 0 && qty >= productPricing.MC_qty) {
      tierLevel = 3;
    }
    if (productPricing.MP_Cost 
          && parseFloat(productPricing.MP_Cost) !== 0 && qty >= productPricing.MP_qty) {
      tierLevel = 4;
    }
    return tierLevel;
  },

  getCostForQtyOrderPad: (product, qty) => {
    if (!product.tiered) {
      return product.Cost;
    }
    qty = parseInt(qty);
    let cost = product.ME_Cost || product.Member_Cost;
    if (isNaN(qty)) {
      return cost;
    }
    if (product.Cost && parseFloat(product.cost) !== 0 && qty >= product.MM_qty) {
      cost = product.Cost;
    }
    if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      cost = product.MC_Cost;
    }
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
      cost = product.MP_Cost;
    }
    return parseFloat(cost).toFixed(2);
  },

  getCostForQtyPlp: (product, qty, isPLP) => {
    if(qty && parseInt(qty) > 0){
      return utilities.getCostForQtyNew(product, qty, isPLP)
    } else {
      return parseFloat(product.Member_Cost).toFixed(2)
    }
  },

  getCostForQtyPlpNew: (product, qty, getTier=false) => {
    if(qty && parseInt(qty) > 0){
      return utilities.getCostForQtyTier(product, qty, getTier)
    } else {
      if(getTier){
        return product?.Member_Cost_Tier
      } else {
        return parseFloat(product.Member_Cost).toFixed(2)
      }
    }
  },

  getCostForQtyTier: (product, qty, getTier) => {
    let cost = product.Member_Cost;
    let tier = product.Member_Cost_Tier ? product.Member_Cost_Tier.split('T')[1] : ''; 
    if(product.tiered){
      const tierQtyFilter = product.Tiers && product.Tiers.length > 0 && product.Tiers.filter(t=>t.Quantity !== 0 && qty >= t.Quantity);
      if(tierQtyFilter && tierQtyFilter.length > 0){
        const tierData = tierQtyFilter[tierQtyFilter.length - 1];
        cost = tierData.PromoCost > 0 ? tierData.PromoCost : tierData.Cost;
        tier = tierData.Tier;
      }
    } else {
      // This validation is to check the case where we have just the promo cost table without tiers
      const promoFilter = product.Tiers && product.Tiers.length > 0 && product.Tiers.filter(t=>t.PromoCost !== 0);
      if(promoFilter && promoFilter.length > 0) {
        const minPromo = promoFilter.reduce((prev, curr) =>{
          return prev.PromoCost < curr.PromoCost ? prev : curr;
        })
        cost = minPromo.PromoCost
        tier = minPromo.Tier
      }
    }
    return getTier ? tier : utilities.zeroForNull(parseFloat(cost)).toFixed(2);
  },

  // This function is used only for warehouse, newitems, discontinued, closeout, favorties PLPs Member cost 
  getCostForQtyNew: (product, qty, isPLP) => {
    let cost = 0;
    if(!product) {
      return cost;
    }

    cost = product.Member_Cost;

    if(!product.tiered){
      return product.cost || product.Cost
    }

    qty = parseInt(qty);

    if (isNaN(qty)) {
      if(product.ME_Cost != 0){
        return product.ME_Cost
      }
      return cost;
    }
        
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
    cost = product.MP_Cost;
    } else if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      cost = product.MC_Cost;
    } else if (qty >= product.MM_qty) {
      if(product.memCostZoned && product.memCostZonedFlag == true){
        cost = product.memCostZoned
      } else if(product.cost || product.Cost){
        cost = product.cost || product.Cost
      }
    } else if (product.ME_Cost && parseFloat(product.ME_Cost) !== 0 && qty < product.MM_qty) {
      cost = product.ME_Cost;
    } 

    return parseFloat(cost).toFixed(2);
  },

  // This is existing pricing function used on pages other than getCostForQtyNew
  getCostForQty: (product, qty, isPLP) => {
    let cost = 0;
    if(!product) {
      return cost;
    }
    if (!product.tiered) {
      return product.Cost || product.cost;
    }
    qty = parseInt(qty);
    cost = product.ME_Cost || product.Member_Cost;
    let product_cost = product.cost || product.Cost;
    if (isNaN(qty)) {
      return cost;
    }
    if (product_cost && parseFloat(product_cost) !== 0 && qty >= product.MM_qty) {
      cost = product_cost;
    }
    if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      cost = product.MC_Cost;
    }
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
      cost = product.MP_Cost;
    }
    return parseFloat(cost).toFixed(2);
  },

  planogramGetCostForQty: (product, qty, isPLP) => {
    let cost = 0;
    if(!product) {
      return cost;
    }
    if (!product.tiered) {
      return product.Cost || product.cost;
    }
    qty = parseInt(qty);
    if(product.ME_Cost == 0){
      if(product.Member_Cost < product.cost || product.Member_Cost < product.Cost){
        cost = product.cost
      } else{
        cost = product.Member_Cost
      }
    } else {
      cost = product.ME_Cost
    }

    let product_cost = product.cost || product.Cost;
    if (isNaN(qty)) {
      return cost;
    }
    if (product_cost && parseFloat(product_cost) !== 0 && qty >= product.MM_qty) {
      cost = product_cost;
    }
    if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      cost = product.MC_Cost;
    }
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
      cost = product.MP_Cost;
    }
    return parseFloat(cost).toFixed(2);
  },

  getCostForQtyHotDeals: (product, qty, isPLP) => {
    let cost = 0;
    if(!product) {
      return cost;
    }
    cost = product.cost || product.Cost;
    if (!product.tiered) {
      return cost;
    }
    qty = parseInt(qty);
    if (isNaN(qty)) {
      return cost;
    }
    if (product.ME_Cost && parseFloat(product.ME_Cost) !== 0 && qty >= product.ME_qty) {
      cost = product.ME_Cost;
    }
    if (cost && parseFloat(cost) !== 0 && qty >= product.MM_qty) {
      cost = product.cost;
    }
    if (product.MC_Cost && parseFloat(product.MC_Cost) !== 0 && qty >= product.MC_qty) {
      cost = product.MC_Cost;
    }
    if (product.MP_Cost && parseFloat(product.MP_Cost) !== 0 && qty >= product.MP_qty) {
      cost = product.MP_Cost;
    }
    return parseFloat(cost).toFixed(2);
  },

  getCostWithTier2 : (product,memberCost,quantity,memberViewType) =>{
    return (
      (
       ( (product.tiered) 
          || (product?.pricing?.tiered)) && memberViewType === MEMBER_VIEW
      ) ?
        <>
          {memberCost}
          <TierIcon 
            tierLevel={
                utilities.getTierLevelForQtyHotDeals((product || (product?.pricing)), quantity)} 
          />
        </>
        : memberCost
    );
  },
  
  getEventCost : (product,getprefer,viewType) =>{
    let priceEvt = product && product.spcCost
    return <MaskPrice
              getprefer={getprefer}
              viewType={viewType}
              fieldVal={priceEvt !== 0?priceEvt:''}
              field={"Member Cost"}
            />
  },
  getFormattedDate: datetime => {
    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'America/Chicago'
    };
    let fullDate = new Date(datetime);
    let newDate = fullDate.toLocaleDateString('en-US', dateOptions);
    return newDate;
  },

  getDateFromTimeStamp: date => {
    let fullDate = new Date(date);
    return fullDate.toLocaleDateString();
  }, 

  getDateFromTimeStampShip: date => {
    let fullDate = new Date(date);
    return fullDate.toLocaleDateString("en-US", { 
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }, 
  getmonthYearFromTimeStampShip: date => {
    let fullDate = new Date(date);
    return fullDate.toLocaleDateString("en-US", { 
      year: "2-digit",
      month: "2-digit",
      day: "2-digit"
    });
  },
  /* getDateFromTimeStamp1 using in order confirmation page */
  getDateFromTimeStamp1: ()=> {
    let fullDate = new Date();
    return fullDate.toLocaleDateString();
  },
  objSortValues : (obj) =>{
    let sortable = [];
    for (let entry in obj) {
        sortable.push([entry, obj[entry]]);
    }
    sortable.sort((a, b) => {
      return (a[1] < b[1]) ? -1 : (a[1] > b[1]) ? 1 : 0;
    }); 
    return sortable;
  },
  getDateStringFromTimeStamp: date => {
    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    };
    let fullDate = new Date(date);
    let newDate = fullDate.toLocaleDateString('en-US', dateOptions);
    return newDate;
  }, 
  getFormattedDate:(date) =>{
    let MM = (1 + date.getMonth()).toString();
    MM = MM.length > 1 ? MM : '0' + MM;
  
    let DD = date.getDate().toString();
    DD = DD.length > 1 ? DD : '0' + DD;
    
    return `${MM}/${DD}`;
  },
  multihyphenForNull: value => {
    return value && value !== '' ? value : ' -- ';
  },

  hyphenForNull: value => {
    return value && value !== '' ? value : ' - ';
  },
  commify: (n) => {
    let parts = n.toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, ",") +
      (decimalPart ? "." + decimalPart : "")
    );
  },
  substituteItemExport: (name, value) =>{
    if(value !== 0 && name){
      return name.replace(':', '')
    } else {
      return null
    }
  },

  itemRestrictedCart: value => {
    return value ? 'Restricted Product' : ''
  },

  itemRestricted: value => {
    return value && value.length > 0 ? 'Y' : 'N'
  },

  itemRestrictionDetails: value => {
    let message = ''
    value && value.length > 0 && value.map((msg)=>{
      message = message == '' ? message + msg.Message : message + `,${msg.Message}`
    })
    return message
  },

  restrictedDetail: details => {
    let dealerProgram = false
    let filterDealerProgram = details.filter(item => item.Type === 'Dealer-Program')
    let nonDealerProgram =  details.filter(item => item.Type !== 'Dealer-Program')
    if(filterDealerProgram && filterDealerProgram.length > 0){
      dealerProgram = true
    }
    return (
      <p className='restrictionMsg'>
        <span className='restrictionMsgcolor'>{dealerProgram ? 'Dealer Program Item' : 'Restricted Product'}</span>
        {!dealerProgram ? <span className='link-style tooltip'>
          <i className='info circle icon'/> 
          <span class="tooltiptext">
          <ul className='tooltip-list'>
            {
              nonDealerProgram.map((msg)=>{
                return(
                <li>{msg.Message.replace('{@ShowSubscribeLink}','')}</li>)
              })
            }
          </ul>
          </span>
        </span>: 
        <>
          <span className='link-style tooltip'>
          <i className='info circle icon'/> 
          <span class="tooltiptext">
            {filterDealerProgram && filterDealerProgram.length > 0 && filterDealerProgram.map(item=>{
              if(item?.Message && item?.Message.includes('{@ShowSubscribeLink}')) {
                return (<span key={item.Message}>
                {item?.Message.split('{@')[0]} To participate in the program, please click <a onClick= {() => window.open('/content/dealer-programs')} className='dealer-program'>here</a> to access the Dealer Program enrollment information.
              </span>)
              }else {
                return (
                  <span key={item?.Message}>
                      {item?.Message}
                  </span>
                )
              }
              return ''
            })}
            </span>
          </span>
        </> }
      </p>
    )
  },
 dsDealerProgramQuantity : (details) => {
  let filterDealerProgram = details.filter(item => item.Type === 'Dealer-Program')
  let dealerMessage  = filterDealerProgram.filter(item => item?.Message && item?.Message.includes('{@ShowSubscribeLink}'))
  return dealerMessage.length > 0 ;
 },
  naForNull: value => {
    return value && value !== '' ? value : 'N/A';
  },
  
  roundWithdollar : value =>{
    if(value && typeof(value) === "number")
     return "$"+String(value.toFixed(2));
    return "$0.00";
  },
  roundForNotNull : value =>{
    if(value && typeof(value) === "number")
    return value.toFixed(2);
   return "-";                                                  
  },
  
  hyphenForNullsub: value => {
    return value && value !== '' ? value + '(REF)' : ' - ';
  },
  hyphenForNullref: value => {
    return value && value !== '' ? value + '(SUB)' : ' - ';
  },
  hyphenForZero: value => {
    return value && parseFloat(value) !== 0 ? value : ' - ';
  },
  hyphenForNullOrZero: value => {
    return value && (value !== 0 && value !== '' && value !== '$0.00') ? value : ' - ';
  },

  hyphenForZeroPercentage: value => {
    return value && parseFloat(value) !== 0 ? `${value}%`  : ' - ';
  },

  emptyForZeroPercentage: value => {
    return value && parseFloat(value) !== 0 ? `${value}%`  : '';
  },

  dollarForValue: value => {
    return value && parseFloat(value) !== 0 ? '$' + value : ''
  },

  floatDollarForValue: value => {
    return value && parseFloat(value) !== 0 ? '$' + Number(value).toFixed(2) : ''
  },
  
  trueForZero: value => {
    return value || value === 0 ? true : false
  },

  PercentageSavings : value => {
    return !utilities.isEmptyOrNullString(value) 
      ? (parseFloat(value).toFixed(2) + ' %') 
      : '-'
  },

  emptyForPercentageSavings : value => {
    return !utilities.isEmptyOrNullString(value) 
      ? (parseFloat(value).toFixed(2) + ' %') 
      : ''
  },

  getPricePrecision : value =>{
    return (!value || isNaN(value))?value:Number(value).toFixed(2);
  },
  rdcBasedonCentralship: (rdcType, CsRDC, storeRDC) => {
    return (rdcType === 'C' && CsRDC && CsRDC.toString().trim() !== '' && !_.isEmpty(CsRDC.toString())) ? CsRDC : storeRDC;
  },
  toIntlNumberFormat : value =>{
    if(utilities.isEmptyOrNullString(value) || isNaN(parseFloat(value))) return '';
    let intlValue = parseFloat(value).toFixed(2);
    const nfObject = new Intl.NumberFormat('en-US',{minimumFractionDigits:2}); 
    return nfObject.format(intlValue);
  },
  isEmptyOrNullString: stringOrObjectOrNull => {
    return (
      _(stringOrObjectOrNull)
        .toString()
        .trim() === '' || _.isEmpty(stringOrObjectOrNull.toString())
    );
  },

  emptyForNull: value => {
    return value && value !== '' && value !== '0' ? value : '';
  },
  zeroForNull: value => {
    return value && value !== '' ? value : 0;
  },
  excelTierFormat: value => {
    return value ? (value.toString().includes('T') ? value : `T${value}`) : '';
  },
  undefindForNull : value => {
    return value && value === undefined ? 0 : value;  
  },
  isUndefinedOrNull :value =>{
    return (value === undefined || !value);
  },
  emptyStringForZero: value => {
    return value && parseFloat(value) !== 0 ? value : '';
  },

  percentageFormat: value => {
    return value && parseFloat(value) !== 0 && Number(value) > 0 ? Number(value).toFixed(2) + '%' : '';
  },

  multiUpcFormat: (value) =>{
    const upcSplit = value ? value.split(' ') : [];
    if(upcSplit.length > 0){
      let upcFormat = upcSplit.map((upc) =>{
        return utilities.padWithZeros(upc, 14)
      });  
      upcFormat = upcFormat.join(' ');
      return upcFormat;
    } else {
      return value;
    }
  },

  padWithZeros: (value, reqLength) => {
    value = value.toString();
    const strLength = value.length;
    return reqLength <= strLength
      ? value
      : new Array(reqLength - strLength + 1).join('0') + value;
  },

  supportsTouch: () => {
    return 'ontouchstart' in window || navigator.msMaxTouchPoints;
  },

  getStatus: status => {
    const orderStatus = {
      O: 'Open',
      P: 'Pending',
      C: 'Billing',
      CLO: 'Closed',
      F: 'Cancelled',
      ARC: 'Being Processed'
    };
    return orderStatus[status] ? orderStatus[status] : '';
  },
  
  getResponse: (assortmentType, listType, response) => {
    assortmentType = assortmentType ? assortmentType : 'Default';
    assortmentType =
      listType && listType === 'CloseoutItems'
        ? 'CloseoutItems'
        : listType && listType === 'DirectShipItems' ? 'DirectShipItems' 
        : listType && listType === 'HotDeals' 
        ? 'HotDeals'
        : assortmentType;
    const apiResponse = {
      Planograms: response.data,
      RetailAssortments: response.data,
      ImpulseMerchandising: response.data,
      DOM: response.data,
      CloseoutItems: response.data,
      DirectShipItems: response.data,
      HotDeals : response.data,
      Default:
        response.data.source && response.data.source.products
          ? response.data.source.products.items
          : ''
    };
    return apiResponse[assortmentType] ? apiResponse[assortmentType] : '';
  },

  openInNewTab: url => {
    var win = window.open(url, '_blank');
    if (win) win.focus();
  },

  openInsameTab: url => {
    var win = window.open(url, '_self');
    if (win) win.focus();
  },

  scrollToElement: ele => {
    setTimeout(
      ele => {
        ele.scrollIntoView();
      },
      1000,
      ele
    );
  },

  showToast: (toastText, isError, addClass, time = 5000) => {
    let x = document.getElementById('snackbar');
    if (x) {
      const className = isError ? `red show ${addClass}` : `show ${addClass}`;
      x.innerHTML = utilities.replaceAll(toastText,'\n','<br/>');
      x.className = className;
      setTimeout(function() {
        x.className = x.className.replace(className, '');
      }, time);
    }
  },

  centreshowToast: (toastText, isError) => {
    return toastText;
  },

  checkAssortmentMdlNumLength :(modelNumber) => {
    return modelNumber.length < 17
      ? modelNumber
      : modelNumber.substr(0, 15) + ' ...' ;
  },

  notEligibleText : (text, orderModelNum) => {
    return (text + orderModelNum + '.');
  },

  excelHubAndSpokeIndictor: (product) => {
    const isStringHands = product && ( product.IsHubPlusSpokeQty === 'True' || product.IsHubPlusSpokeQty === true );
    const isQtyCombined = product.quantity && product.quantity.length > 0 
                            && product.quantity[0].IsHubPlusSpokeQty;
    const isHubAndSpoke = product && (product.primaryWarehouseType === 'S' || product.rdcType === 'S')
                            && ( isStringHands || isQtyCombined);
    return  isHubAndSpoke ? 'H&S' : ''          
  },

  excelHubIndictor: (product) => {
    const isHub = product && product.primaryWarehouseType === 'S' && product.rdctype === 'H'
    return  isHub ? 'HUB': ''        
  },

  checkForHubAndSpokeIndictor: (product, view) => {
    const isStringHands = product && ( product.IsHubPlusSpokeQty === 'True' || product.IsHubPlusSpokeQty === true );
    const isQtyCombined = product.quantity && product.quantity.length > 0 
                            && product.quantity[0].IsHubPlusSpokeQty;
    const isHubAndSpoke = product && (product.primaryWarehouseType === 'S' || product.rdcType === 'S')
                            && ( isStringHands || isQtyCombined);
    let Available = utilities.zeroForNull(product.available || product.Available);
    if(view === 'compact'){
      return(
        <>
        {Available}
        {isHubAndSpoke && <>
        {' '}
          <span className='hnsInd compact_indicator'>
            {'H&S'} 
          </span> </>}
      </>
      )
      }
    return  (
      <>
        {Available}
        {isHubAndSpoke && <>
        {' '}
          <span className='hnsInd'>
            {'H&S'} 
          </span> </>}
      </>
    );
  },

  checkFlashsaleorAssortment: (isFlashsales, isReunionAssortment, seleDept, isCtbAssortment) => {
    return isFlashsales 
            ? 'Flash Sales'
            : isReunionAssortment 
            ? 'Reunion Retail Assortments'+' - ' + seleDept.trim()
            : isCtbAssortment ? 'Customized True Blue Assortments':'Retail Assortments';
  },
  cookieClear: () => {
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      // expire date is set to past, so that cookie will get expired
      document.cookie = name + "=;expires=Thu,01 Jan 1970 00:00:00 GMT;path=/";
    });
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
    });
  },
  validateToken: function(secretKey){
    const access_token = localStorage.getItem('access_token');
    let publicKey = `-----BEGIN CERTIFICATE-----${secretKey}-----END CERTIFICATE-----`
    jwt.verify(access_token,publicKey,{ algorithm: 'RS256', ignoreNotBefore : true  },(err,decoded)=>{
      if(err){
        console.log(err);
        this.showToast("Session timed out");
        store.dispatch(logout());
        localStorage.clear();
        sessionStorage.clear();
        this.cookieClear();
        return false;
      }
      return true;
    });
  },
  setLoader : function(showLoader){
    store.dispatch(setLoader(showLoader));
  },
  throwExpiryError : () =>{
    throw new Error("Session Expired");
  },

  getplanogramPdf:assortmentNumb=>{
    let planogramPdf = assortmentPlanopdf+assortmentNumb.charAt(0)+'/'+assortmentNumb+'.pdf';
    return planogramPdf
   
  },

  getImpulsePdf:assortmentNumb=>{
    let impulsePdf=assortmentImpulepdf +assortmentNumb+'.pdf'
    return impulsePdf;
  },

  mergeBy: (existingArray = [], newArray = [], propBy) => {
    const mapper = {};
    const resultArray = [];
    if (propBy) {
      existingArray.forEach(item => {
        const key = item[propBy];
        if (key) {
          mapper[key] = { ...item };
        }
      });
      newArray.forEach(item => {
        const key = item[propBy];
        if (key) {
          if (mapper[key]) {
            resultArray.push({ ...mapper[key], ...item });
          } else {
            resultArray.push({ ...item });
          }
        }
      });
    }
    return resultArray;
  },
  hasParentWithMatchingSelector : (target, selector)=> {
    return [...document.querySelectorAll(selector)].some(el =>
      el !== target && el.contains(target)
    )
  },
  exportToCsv : (data,fileName) =>{
    downloadExcel(data, `${fileName}-${utilities.getCurrDate()}.xlsx`);
  },
  
  checkCsrUser: (storeDetails) => {
    return storeDetails?.csr === true;
  },
  
  checkDuplicate: (items) => {
    return items.filter((item) => items.indexOf(item) !== items.lastIndexOf(item))
  },

  isAliasSkuRequired: (multiStoreDetails) =>{
    return !utilities.isEmptyOrNullString(multiStoreDetails?.aliasSKURetailerGrpID)
  },

  getChildStoreUserId: (multiStoreDetails, storeId) => {
    return multiStoreDetails?.childStoreDetails[storeId] ? multiStoreDetails.childStoreDetails[storeId].userID : '';    
  },

  getChildStoreDetails: (multiStoreDetails, userId) => {
   let childStoreName = Object.keys(multiStoreDetails?.childStoreDetails).map(key => {
      if(multiStoreDetails?.childStoreDetails[key].userID === parseInt(userId)){
        return multiStoreDetails?.childStoreDetails[key].storeID + ' - ' + multiStoreDetails?.childStoreDetails[key].storeName;
      }
    })
    return childStoreName;
  },

  getStoreName: (multiStoreDetails, userId) => {
    let storeName = Object.keys(multiStoreDetails?.childStoreDetails).map(key => {
       if(multiStoreDetails?.childStoreDetails[key].userID === parseInt(userId)){
         return multiStoreDetails?.childStoreDetails[key].storeName;
       }
     })
    storeName = storeName?.filter((s)=> s!= undefined)
     return storeName[0];
   },

  getReunionOnlyStore: (childStoreDetails) => {
    let isReunion = true
    let stores = childStoreDetails && !_.isEmpty(Object.keys(childStoreDetails)) && Object.keys(childStoreDetails).map(key => {
      if(!childStoreDetails[key].IsReunionOnlyStore) {
        return parseInt(key)
      }
      isReunion = childStoreDetails[key].IsReunionOnlyStore !== undefined ? true : false
    })
    let result = childStoreDetails && !_.isEmpty(Object.keys(childStoreDetails)) && Object.keys(childStoreDetails).map(ele => parseInt(ele) )
    stores = stores && !_.isEmpty(stores) ? stores.filter(ele => ele !== undefined): []
    result = result && !_.isEmpty(result) ? result: []
    return isReunion ? stores : result
  },
  orderedChildStoreList: (childStoreList, store) => {
    if(childStoreList && !_.isEmpty(Object.keys(childStoreList))) {
      let listAsc = Object.keys(childStoreList).sort((a, b) => a - b)
      let keys = listAsc.filter(ele => ele !== store)
      keys.unshift(store)
      return keys
    }
    return []
  },
  getLabelsByType:(viewType,key,getprefer)=>{
    if(viewType === MEMBER_VIEW){
      return memberViewConstants[key];
    } else if(viewType !== MEMBER_VIEW && (key === 'SUGG_RETAIL' || key === 'VAR_RETAIL') && (getprefer==='c1'||getprefer==='c4')) {
      return memberViewConstants[key];
    }
    else return customerViewConstants[key];
  },
  checkEmptyStringAndArray:(ele)=> {
    if(Array.isArray(ele) && ele.length){
      return false
    }
    else if(ele == '') {
      return false
    }
    return true
  },
  isQuantityAvaliable:(quantities)=>{
    return !quantities.some(ele => ele !== '')
  },
  getWhsStockMeg: (code)=>{
    let stockStatus = '';
    switch(code){
      case whsStockCode.STATUS_DISCONTINUED:
        stockStatus =  'Discontinued';
        break;
      case whsStockCode.STATUS_MARKED_OUT:
        stockStatus =  'Out of Stock';
        break;
      case whsStockCode.STATUS_NEW_ITEM:
        stockStatus =  'New Item';
        break;
      case whsStockCode.STATUS_OUT_OF_SEASON:
        stockStatus =  'Out of Season';
        break;
      case whsStockCode.STATUS_TEMP_OUT:
        stockStatus =  'Temporarily Out';
        break;
      case whsStockCode.STATUS_OUT_FOR_SEASON:
        stockStatus = 'Item is Out of Stock for the Remainder of the Season';
        break;
      case whsStockCode.STATUS_EXTENDED_OUT_OF_STOCK:
        stockStatus =  'Extended out of Stock';
        break;
      case whsStockCode.STATUS_EMERGENCY_OUT_OF_STOCK:
        stockStatus =  'Emergency out of Stock';
        break;
      case whsStockCode.STATUS_END_OF_LIFE:
        stockStatus =  'Item is to be Discontinued - Awaiting further information';
        break;
      case whsStockCode.STATUS_PROMO_IN_OUT:
        stockStatus =  'Promo In Out';
        break;
      default:
        stockStatus =  '---'
    }
    return stockStatus;
    },
    checkRef: (substistuteorreferal)=>{
      if(substistuteorreferal.indexOf('REF') !== -1){
        return true;
      }
      return false;
    },
    compareDates: (startDate, endDate)=>{
      const formattedStartDate = startDate;
      const formattedEndDate = endDate;

      return formattedStartDate < formattedEndDate;
    },
    isDesktop : ()=> window.innerWidth >= 1025 ,
    isMobile : ()=> window.innerWidth <= 767,
    isMobileTab: ()=> window.innerWidth <= 1024,
    isTab : ()=> window.innerWidth >= 768 &&  window.innerWidth <= 1024,
    isMobileTabDevice : ()=> /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase()),
    getCurrentCstTime : () => moment(new Date()).tz('America/Chicago').format("MM/DD/YYYY HH:mm:ss"),
    addSuffixZero: (num) => {
      const result = num.toFixed(Math.max(((num+'').split(".")[1]||"").length, 2));
      return parseFloat(result).toFixed(2)
    },
    zeroForNaN: (value) => {
      return (_.isEmpty(value) || isNaN(parseInt(value))) ? 0 : parseInt(value) 
    },
    getDateByFilterMonthDate : (date) => {
      const value = date.split('-')
      return (
        value[1] +
        '/' +
        value[2] 
      );      
    },
    isBinLabel:(qty) => parseInt(qty) === 888,
    validateFileType:(file)=>{
      const fileType = ['jpg','png','pdf','docx','xlsx','xls','doc']

      return fileType.includes(file.toLowerCase())
   },
   getAfterFirstHyphen:(text) =>{
    if(_.isEmpty(text)) {
      return ''
    }
    const index = text.indexOf('-');
    return index !== -1 ? text.substring(index + 1) : ''; 
  }
};
export const getMaskPriceFromText = (assortment)=>{
  const assortmentSaveMaskPrice = assortment.save.split("Save $");
  return  assortmentSaveMaskPrice[1];
}
export const getPrefernceType = (props) =>{
return   props.getprefer && props.getprefer.customerView
  ? props.getprefer.customerView
  : 'c1';
}

export const shouldHideCostForPreference = (viewType , getprefer)=>{
  if(viewType !== MEMBER_VIEW && getprefer === "c3")
  {
    return false;
  }
  if(viewType !== MEMBER_VIEW && getprefer === "c4")
  {
    return false;
  }  
  return true;
}
export const shouldHidePriceTotalPreference = (tableType,viewType,getprefer) =>{
  if((tableType === "viewCart" || tableType == "ra-cart" || tableType == "event-cart") && viewType !== MEMBER_VIEW && getprefer === "c3")
  {
    return false;
  }
  if((tableType === "viewCart" || tableType == "ra-cart" || tableType == "event-cart") && viewType !== MEMBER_VIEW && getprefer === "c4")
  {
    return false;
  }  
  return true;
}
export const shouldHideSUGG_RETAILForPreference = (viewType , getprefer)=>{
  if(viewType !== MEMBER_VIEW && getprefer === "c3")
  {
    return false;
  }
  return true;
}
export const shouldHideOrigAndCurrCostForPreference = (viewType ,tableType,getprefer)=>{
  if(viewType !== MEMBER_VIEW && (getprefer === "c3"||getprefer ==="c4") && (tableType === DISCONTINUED || tableType === CLOSEOUTS || tableType === HOTDEALS))
  {
    return false;
  }
 return true;  
}

export const shouldHideCreditOnRAPage = (viewType , getprefer) =>{
  if(viewType!== MEMBER_VIEW && (getprefer === "c3"||getprefer ==="c4"))
  {
    return false;
  }
  return true;
}
export const shouldHideOrderPadCost = (viewType , getprefer) =>{
  if(viewType!== MEMBER_VIEW && (getprefer === "c3"||getprefer === 'c4'))
  {
    return false;
  }
  return true;
}

export const showSRPMaskPricePref =(viewType ,getPrefer) =>{
  if(viewType!== MEMBER_VIEW && (getPrefer === "c4" || getPrefer === "c1"))
  {
    return false;
  }
  return true;
}


export const showSRPMaskPrice =(getPrefer) =>{
  if(getPrefer === "c1" || getPrefer === "c2")
  {
    return true;
  }
  return false;
}

export const showSRPPrice =(getPrefer) =>{
  if(getPrefer === "c1" || getPrefer === "c2")
  {
    return true;
  }
  return false;
}

export const showSavingValue = (viewType , getprefer)=>{
  if(viewType !== MEMBER_VIEW && getprefer === "c3" || getprefer === "c4")
{
  return false;
}
return true;
}

export const checkViewType =(isCustomerView) =>{
  if(isCustomerView) {
    return CUSTOMER_VIEW;
  }
  return MEMBER_VIEW;
}

export const saleType = (value) =>{
  let saleLabel = 'Monthly Specials'
  if(value == 2){
    saleLabel ='Sizzle Sale';
  } else if(value == 3){
    saleLabel ='Monthly Specials/Sizzle Sale';
  } 
  return  saleLabel
}

export const getCloseoutRegularCost = (product)=> {
   let cost = '-';
   let tier = ''
    if(product.ME_Cost && product.ME_Cost > 0) {
      cost = product.ME_Cost
      tier = 'T1'
    }
    else {
       if(product.Member_Cost && product.Member_Cost > 0) {
        cost = product.Member_Cost
        tier = 'T2'
       }
       else if(product.memCostZoned && product.memCostZoned > 0) {
        cost = product.memCostZoned
        tier = 'T2'
       }
      //cost = product.Member_Cost/product.memCostZoned
      //tier = 'T2'
    }
    return [cost, tier]
}

export const getCloseoutCurrentCost = (product) => {
   if(product?.MemberCostPromo &&product?.MemberCostPromo > 0) {
     return [product?.MemberCostPromo, '']
   }
   return getCloseoutRegularCost(product)
}
export const dsRetailerPolicyMessage = () =>{
  return (
    <section className='infoMessageSection'>
      <h4>By utilizing True Value Company's Direct Ship Portal ("Portal"), You ("Retailer") acknowledge that:</h4>
      <ul>
        <li>The Portal is provided to Retailer as a convenience, True Value Company makes no representation or warranty regarding the product information contained on the Portal;</li>
        <li>Transactions facilitated on the Portal are between Retailer and the Vendor;</li>
        <li>Orders on the Portal must be confirmed by the Vendor;</li>
        <li>All negotiations regarding the products ("Vendor Products") on the Portal are between Retailer and the Vendor;</li>
        <li>Placing an order on the Portal does not guarantee fulfillment or shipment of Vendor Products to your jurisdiction;</li>
        <li>Vendor is responsible for all fulfillment and shipment of Vendor Products;</li>
        <li>Vendor Products displayed on the Portal may not comply with the laws and regulations of Retailer's jurisdiction;</li>
        <li>Retailer is responsible for compliance with all laws and regulations related to Retailer's purchase of Vendor Products on the Portal; and</li>
        <li>Retailer irrevocably and unconditionally waives, releases, and forever discharges True Value Company from any and all claims, complaints, demands, rights, causes of action, obligations, liabilities, losses, promises, agreements, controversies, penalties, expenses, damages or suits at law and equity of any kind, known or unknown, foreseen and unforeseen, ("Claims") related to Retailer's use of the Portal, including but not limited to any and all Claims related to Vendor Products. </li>
        <li>By placing an order, Retailer agrees that the order is non-cancelable, and the product(s) are non-returnable. Retailer is to contact the vendor directly for any questions about Retailer's order of vendor's product(s).</li>
      </ul>
    <h4>By clicking Place Order, Retailer agrees to be bound by True Value's terms for Portal usage.</h4>
    </section>
  )
}
export const dsNonStockedItem = (itemNbr) => {
   return (
       <span className='dsNonStockedItem'>
       {itemNbr}
       <Popup content="The item is not stocked, and it's not available in the Warehouse" 
              trigger ={<span
              className='tier_icon'
              size='mini'
            >NS</span>} />
       </span>
   )
}
export default utilities;
